import React, { useState, MouseEvent, useEffect } from 'react';
import { createStyles, withStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { green } from '@material-ui/core/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import VideoTab from '../VideoTab/VideoTab';
//import useRoomState from '../../hooks/useRoomState/useRoomState';
import { useAppState } from '../../state';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';

const refreshSound = require('../../utils/AudioFiles/refreshSound.wav');

function ViewRoomList(props: any) {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      container: {
        borderRight: '1px solid #606060',
        borderColor: theme.palette.text.primary,
        position: 'relative',
        gridRow: !mobileMode ? 1 : 2,
      },
      roomContainer: {
        height: '65px',
        width: '100%',
        display: 'grid',
        gridTemplateColumns: !mobileMode ? 'auto 122px 50px' : 'auto 80px 50px',
        transitionDuration: '150ms',
        borderBottom: '1px solid #606060',
        borderColor: theme.palette.text.primary,
        alignItems: 'center',
        padding: !mobileMode ? '0px 15px' : '0px 10px',
        '&:hover': {
          backgroundColor: theme.palette.background.default,
        },
      },
      headerContainer: {
        borderBottom: '1px solid #606060',
        borderColor: theme.palette.text.primary,
        position: 'relative',
        height: !mobileMode ? '70px' : '50px',
        display: 'grid',
        alignItems: 'center',
      },
      headerText: {
        margin: '0px',
        marginLeft: '10px',
        color: theme.palette.text.primary,
      },
      refreshContainer: {
        position: 'absolute',
        top: '50%',
        right: '20px',
        transform: 'translate(0%, -50%)',
        backgroundColor: '#606060',
        cursor: 'pointer',
        height: '35px',
        width: '40px',
        display: 'grid',
        alignItems: 'center',
        justifyItems: 'center',
        borderRadius: '8px',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.6)',
        transitionDuration: '150ms',
        '&:hover': {
          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.3)',
          backgroundColor: '#404040',
        },
      },
      refreshIcon: {
        fontSize: '20px',
        color: 'white',
      },
      button: {},
      subjectOptionsButton: {
        position: 'relative',
        right: '-10px',
      },
      kickButton: {
        margin: '1em',
        marginRight: '0px',
        gridColumn: 3,
      },
      nameText: {
        margin: '0px',
        fontSize: '16px',
        position: 'relative',
        top: '1px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        color: theme.palette.text.primary,
      },
      noRoomsText: {
        position: 'relative',
        left: '50%',
        transform: 'translate(-50%, 0%)',
        display: 'inline-block',
        fontSize: '18px',
        color: theme.palette.text.primary,
      },
      createRoomContainer: {
        position: 'absolute',
        bottom: '0',
        width: '100%',
      },
    })
  );

  const {
    getToken,
    loggedUsername,
    server,
    totalRooms,
    roomList,
    walklistjson,
    setSurveySubject,
    setMyCurrentRoom,
    myCurrentRoom,
    closeRoom,
    mobileMode,
  } = useAppState();
  const { connect, room } = useVideoContext();

  const classes = useStyles();

  const kickUserHandler = async (userToBeKicked: string, index: number) => {
    //const roomName = 'myRoom';

    const headers = new window.Headers();
    headers.set('Authorization', 'Bearer ' + sessionStorage.getItem('customg'));

    //await needs a try catch, wont catch with the .catch
    try {
      await fetch(
        // @ts-ignore
        `https://${server}/api/v2/twilio/kickVideoParticipant?room=${roomList[index].roomName}&name=${userToBeKicked}`,
        {
          method: 'POST',
          headers: headers,
        }
      )
        //catch needs to be after
        .then(res => {
          if (res.status === 200) {
            //console.log('res 200 ok');
            return res.text();
          } else {
            //console.log('not 200 ok');
            throw new Error('Failed to kick');
          }
        })
        .then(() => console.log(`User ${userToBeKicked} kicked from room`))
        .catch((err: string) => console.log(err));
    } catch (err) {
      console.log(err);
    }
  };

  const closeRoomHandler = async (room: string, sid: string) => {
    if (room && sid) {
      closeRoom(room, sid)
        .then(() => {
          alert('Successfully closed room.');
          console.log('Successfully closed video room');
        })
        .catch((err: string) => {
          console.log(err);
          alert('Unable to close video room.');
        });
    }
  };

  const checkCanvasserRoomStatus = () => {
    if (myCurrentRoom === '') return true;
    else {
      room?.disconnect?.();
      return true;
    }
  };

  //value consists of 0. roomName<string> 1. participantQuantity<number> 2. roomSID<string>
  const joinRoomHandler = (value: any) => {
    //Verify that canvasser is not already in a room
    if (checkCanvasserRoomStatus()) {
      if (value && value[0] && typeof value[0] === 'string') {
        const name = loggedUsername;
        setMyCurrentRoom(value[0]);
        getToken(loggedUsername, '', name, value[0]).then(token => connect(token));
      }
    }
  };

  const ColorButton = withStyles(theme => ({
    root: {
      color: '#ffffff',
      backgroundColor: green[500],
      '&:hover': {
        backgroundColor: green[700],
      },
    },
  }))(Button);

  //const roomState = useRoomState();
  //const [roomName, setRoomName] = useState<string>('');

  const ITEM_HEIGHT = 48;

  const options = ['Survey User', 'Close Room'];

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    //setAnchorEl establishes the UI element that the menu will anchor to and expand from
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (index: number, value: any) => {
    setAnchorEl(null);
    if (value && value[0] && typeof value[0] === 'string') {
      switch (index) {
        case 0:
          return setSurveySubjectHandler(value[0]);
        case 1:
          if (value[2] && typeof value[2] === 'string' && value[2].startsWith('RM')) {
            return closeRoomHandler(value[0], value[2]);
          } else {
            return console.log('Invalid Room Name');
          }
      }
    }
  };

  const setSurveySubjectHandler = (roomName: string) => {
    if (roomName.indexOf('_') === -1) {
      return console.log('Invalid Room Name');
    } else {
      let splitStringArray = roomName.split('_');
      if (splitStringArray.length > 2) {
        let subjectHid = Number(splitStringArray[2]);
        console.log('set survey subject');
        walklistjson?.Walklist?.forEach((value: any, index: number) => {
          if (value.Hid === subjectHid) return surveyUserHandler(value);
        });
      } else {
        return console.log('Invalid Room Name');
      }
    }
  };

  const surveyUserHandler = (value: any) => {
    console.log(`Surveying user `, value);
    setSurveySubject(value);
    props.changePage(false);
  };

  const getRoomListName = (roomName: string) => {
    let splitStringArray = roomName.split('_');
    let subjectHid = Number(splitStringArray[2]);
    let name = `${splitStringArray[4]} - Not on today's walklist`;
    walklistjson.Walklist.forEach((value: any, index: number) => {
      if (value.Hid === subjectHid) return (name = `${splitStringArray[4]} ${value.LastName}`);
    });
    return name;
  };

  const refreshButtonHandler = () => {
    props.playSound(refreshSound, 3);
    props.getAvailableRooms();
  };

  //controls for manual video
  //<div className={classes.createRoomContainer}>
  //  {roomState === 'disconnected' ? <VideoTab></VideoTab> : <h3>{roomName}</h3>}
  //</div>

  return (
    <div className={classes.container}>
      <div className={classes.headerContainer}>
        <h2 className={classes.headerText}>Current Rooms ({totalRooms})</h2>
        <div className={classes.refreshContainer} onClick={refreshButtonHandler}>
          <FontAwesomeIcon icon={faSyncAlt} className={classes.refreshIcon} />
        </div>
      </div>
      {!roomList || roomList.length === 0 ? (
        <p className={classes.noRoomsText}>No rooms currently active</p>
      ) : (
        // @ts-ignore
        roomList.map((value, index) => (
          <div className={classes.roomContainer} key={index}>
            <p className={classes.nameText}>
              {index + 1}) {getRoomListName(value[0])} ({value[1]}/2)
            </p>
            {value[1] < 2 && (
              <ColorButton
                className={classes.button}
                type="submit"
                color="primary"
                variant="contained"
                onClick={() => joinRoomHandler(value)}
              >
                Join
              </ColorButton>
            )}
            <div style={{ gridColumn: 3 }}>
              <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                className={classes.subjectOptionsButton}
                onClick={handleClick}
              >
                <MoreVertIcon />
              </IconButton>
              <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                PaperProps={{
                  style: {
                    maxHeight: ITEM_HEIGHT * 4.5,
                  },
                }}
              >
                {options.map((option, index) => (
                  <MenuItem key={option} selected={option === 'Pyxis'} onClick={() => handleClose(index, value)}>
                    {option}
                  </MenuItem>
                ))}
              </Menu>
            </div>
          </div>
        ))
      )}
    </div>
  );
}

export default ViewRoomList;
