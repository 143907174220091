import React, { createContext, useContext, useReducer, useState } from 'react';
import { TwilioError } from 'twilio-video';
import { settingsReducer, initialSettings, Settings, SettingsAction } from './settings/settingsReducer';
//import useFirebaseAuth from './useFirebaseAuth/useFirebaseAuth';
//import usePasscodeAuth from './usePasscodeAuth/usePasscodeAuth';
//import { User } from 'firebase';
//import useLoginState from '../hooks/useLoginState/useLoginState';
import * as Survey from 'survey-react';
import { isNumber } from 'util';

export interface StateContextType {
  error: TwilioError | null;
  setError(error: TwilioError | null): void;
  getToken(user: string, pass: string, name: string, room: string): Promise<string>;
  getLogin(name: string, passcode: string, server: string): Promise<string>;
  getPetition(name: string, passcode: string, server: string): Promise<string>;
  getWalklist(name: string, passcode: string, server: string): Promise<string>;
  sendAnswers(name: string, passcode: string, body: string): Promise<string>;
  closeRoom(room: string, sid: string): Promise<string>;
  //user?: User | null | { displayName: undefined; photoURL: undefined; passcode?: string };
  signIn?(passcode?: string): Promise<void>;
  signOut?(): Promise<void>;
  isAuthReady?: boolean;
  isFetching: boolean;
  isFetchingWalklist: boolean;
  isFetchingPetition: boolean;
  isFetchingLogin: boolean;
  loginState: string;
  petition: string;
  petitionjson: any;
  walklist: string;
  walklistjson: any;
  walklistjsonLocations: any;
  //mobileSurveyId: string;
  setWalklist(newWalklist: object): void;
  setSurveySubject(subject: any): void;
  setSurveySubjectPublic(subject: any, petitionjson: any, server: string, userName: string, surveyId: string): void;
  surveyPublic: boolean;
  setLanguage(language: any): void;
  loggedUsername: string;
  loggedImei: string;
  setLoggedImei(imei: string): void;
  //loggedPass: string;
  surveySent: boolean;
  surveySubject: any;
  language: any;
  server: string;
  surveyModel: any;
  surveyStartString: string;
  surveyMobileID: string;
  surveyExecuteSale: boolean;
  setSurveyExecuteSale(executeSale: boolean): void;
  surveyExecuteSaleValid: boolean;
  setSurveyExecuteSaleValid(executeSaleValid: boolean): void;
  activeSinkId: string;
  setActiveSinkId(sinkId: string): void;
  settings: Settings;
  dispatchSetting: React.Dispatch<SettingsAction>;
  newTextMessages: number;
  setNewTextMessages(messages: number): void;
  newTextMessageList: any;
  setNewTextMessageList(newMessageList: any): void;
  roomsOpen: number;
  setRoomsOpen(newOpenRoomTotal: number): void;
  totalRooms: number;
  setTotalRooms(newTotalRooms: number): void;
  roomList: any;
  setRoomList(newRoomList: any): void;
  myCurrentRoom: string;
  setMyCurrentRoom(newRoomName: string): void;
  screenShareTrack: any;
  setScreenShareTrack(newTrack: any): void;
  screenSharePublication: any;
  setScreenSharePublication(newPublication: any): void;
  darkMode: boolean;
  setDarkMode(newMode: boolean): void;
  textToPush: any;
  setTextToPush(newText: any): void;
  mobileMode: boolean;
  setMobileMode(newMobileState: boolean): void;
}

export const StateContext = createContext<StateContextType>(null!);

//@ts-ignore
var LocalSubject = null;

/*
  The 'react-hooks/rules-of-hooks' linting rules prevent React Hooks fron being called
  inside of if() statements. This is because hooks must always be called in the same order
  every time a component is rendered. The 'react-hooks/rules-of-hooks' rule is disabled below
  because the "if (process.env.REACT_APP_SET_AUTH === 'firebase')" statements are evaluated
  at build time (not runtime). If the statement evaluates to false, then the code is not
  included in the bundle that is produced (due to tree-shaking). Thus, in this instance, it
  is ok to call hooks inside if() statements.
*/

export function uuidv4() {
  // @ts-ignore
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
    (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
  );
}

export default function AppStateProvider(props: React.PropsWithChildren<{}>) {
  const [error, setError] = useState<TwilioError | null>(null);
  const [isFetching, setIsFetching] = useState(false);

  const [isFetchingWalklist, setIsFetchingWalklist] = useState(false);
  const [isFetchingPetition, setIsFetchingPetition] = useState(false);
  const [isFetchingLogin, setIsFetchingLogin] = useState(false);

  const [loggedUsername, setLoggedUsername] = useState('');
  const [loggedImei, setLoggedImei] = useState('');
  //const [loggedPass, setLoggedPass] = useState('');
  const [surveySent, setSurveySent] = useState(false);
  const [surveyPublic, setSurveyPublic] = useState(false);
  const [server, setServer] = useState('');

  const [surveyModel, setSurveyModelState] = useState(new Survey.Model());
  const [surveyStartString, setSurveyStartString] = useState('');
  const [surveyMobileID, setSurveyMobileID] = useState('');

  //payment variables
  const [surveyExecuteSale, setSurveyExecuteSale] = useState(false);
  const [surveyExecuteSaleValid, setSurveyExecuteSaleValid] = useState(false);

  const [activeSinkId, setActiveSinkId] = useState('default');
  const [settings, dispatchSetting] = useReducer(settingsReducer, initialSettings);

  const [loginState, setLoginState] = useState('logout');

  const [petition, setPetition] = useState('');
  const [petitionjson, setPetitionjson] = useState(null);
  const [walklist, setWalklist] = useState('');
  const [walklistjson, setWalklistjson] = useState(null);
  const [walklistjsonLocations, setWalklistjsonLocations] = useState<object>([]);
  const [surveySubject, setSurveySubjectState] = useState(null);

  const [language, setLanguageState] = useState('default');

  const [newTextMessages, setNewTextMessages] = useState<number>(0);
  const [newTextMessageList, setNewTextMessageList] = useState<Array<Array<any>>>([]);
  const [roomsOpen, setRoomsOpen] = useState<number>(0);
  const [totalRooms, setTotalRooms] = useState<number>(0);
  const [roomList, setRoomList] = useState<Array<any>>([]);
  const [myCurrentRoom, setMyCurrentRoom] = useState<string>('');
  const [screenShareTrack, setScreenShareTrack] = useState<any>();
  const [screenSharePublication, setScreenSharePublication] = useState<any>();
  const [darkMode, setDarkMode] = useState<boolean>(true);
  const [textToPush, setTextToPush] = useState<any>(null);
  const [mobileMode, setMobileMode] = useState(window.innerWidth <= 800 ? true : false);

  //const [mobileSurveyId, setMobileSurveyId] = useState(uuidv4());
  //const LoginState = useState();

  function getFormattedDate(date: Date) {
    //var d = new Date();

    ////"2020-07-07T10:41:01.9-0400"

    //const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
    //const mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d);
    //const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
    //const hr = new Intl.DateTimeFormat('en-US', { hour: 'numeric' }).format(d);
    //const mn = new Intl.DateTimeFormat('en', { minute: '2-digit' }).format(d);
    //const sc = new Intl.DateTimeFormat('en', { second: '2-digit' }).format(d);
    ////const ms = new Intl.DateTimeFormat('en', { s: '2-digit' }).format(d);
    //const ms = d.getMilliseconds();

    const dateTimeFormat = new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false,
    });
    var [
      { value: month },
      ,
      { value: day },
      ,
      { value: year },
      ,
      { value: hour },
      ,
      { value: minute },
      ,
      { value: second },
    ] = dateTimeFormat.formatToParts(date);

    var ms = ('000' + date.getMilliseconds()).slice(-3);

    //console.log(date.toISOString());
    //var tz = date.getTimezoneOffset();
    if (hour === '24') {
      hour = '00';
    }

    var surveystartstring = `${year}-${month}-${day}T${hour}:${minute}:${second}.${ms}`;

    console.log('survey Start: ' + surveystartstring);

    return surveystartstring;
    //console.log(surveystartstring);
  }

  function isContactInfoQuestion(variable: string) {
    if (
      variable === '$FIRST_NAME' ||
      variable === '$LAST_NAME' ||
      variable === '$ADDRESS' ||
      variable === '$APARTMENT' ||
      variable === '$CITY' ||
      variable === '$STATE' ||
      variable === '$ZIP' ||
      variable === '$HOME' ||
      variable === '$CELL' ||
      variable === '$EMAIL'
    ) {
      return true;
    }

    return false;
  }

  function isContactInfoSameLine(variable: string) {
    if (variable === '$LAST_NAME' || variable === '$STATE' || variable === '$ZIP' || variable === '$CELL') {
      return true;
    }

    return false;
  }

  function getContactInfoInputType(variable: string) {
    var inputtype = '';

    switch (variable) {
      case '$ZIP':
        inputtype = 'number';
        break;

      case '$HOME':
        inputtype = 'number';
        break;

      case '$CELL':
        inputtype = 'number';
        break;
    }

    return inputtype;
  }

  function getDefaultSurveyValue(variable: string, object: any) {
    var inputtype = '';

    switch (variable) {
      case '$FIRST_NAME':
        inputtype = object.FirstName;
        break;

      case '$LAST_NAME':
        inputtype = object.LastName;
        break;

      case '$ADDRESS':
        inputtype = object.Address;
        break;

      case '$APARTMENT':
        inputtype = object.Apartment;
        break;

      case '$CITY':
        inputtype = object.City;
        break;

      case '$STATE':
        inputtype = object.State;
        break;

      case '$ZIP':
        inputtype = object.Zip;
        break;

      case '$HOME':
        inputtype = object.Phone;
        break;

      case '$CELL':
        inputtype = object.Cell;
        break;

      case '$EMAIL':
        inputtype = object.Email;
        break;
    }

    return inputtype;
  }

  function generateSurvey(petitionJsonIn: any, publicSurvey = false) {
    const gruquestions = [].slice.call(petitionJsonIn.Questions).sort(function(a: any, b: any) {
      return a.Order - b.Order;
    });

    const gruquestionanswers = petitionJsonIn.Answers;
    const gruquestionproperties = petitionJsonIn.QuestionProperties;
    const gruanswerproperties = petitionJsonIn.AnswerProperties;

    var foundcontactinfo = false;

    //console.log(gruquestionanswers);

    Object.keys(gruquestions).forEach(function(key) {
      if (gruquestions[key].Type === 'ContactInfo') {
        foundcontactinfo = true;
      }
    });

    var questionsarr: any = [];
    var questionscontactinfoarr: any = [];

    let surveyLinkIgnore = petitionJsonIn.ScriptProperties.find((x: any) => x.Name === 'SurveyLinkIgnore');
    let valuesToIgnore: any = [];

    if (surveyLinkIgnore) {
      valuesToIgnore = surveyLinkIgnore.Value.split(',');
    }

    //loop through sorted questions
    Object.keys(gruquestions).forEach(function(key) {
      var element = {
        type: '',
        name: '',
        tempTitle: '',
        isRequired: false,
        startWithNewLine: true,
        title: { default: '' },
        choices: [],
        visibleIf: 'true',
      };

      if (window.location.pathname.includes('survey')) {
        if (valuesToIgnore.includes(gruquestions[key].Variable)) {
          return;
        }
      }

      //default name
      element.name = gruquestions[key].Id.toString();

      //default title
      var titleelement;

      if (gruquestions[key].Title === gruquestions[key].Text || gruquestions[key].Text === '') {
        element.tempTitle = '';
        titleelement = {
          default: gruquestions[key].Title,
        };
      } else {
        element.tempTitle = gruquestions[key].Title;
        titleelement = {
          default: gruquestions[key].Text,
        };
      }
      element.title = titleelement;

      if (gruquestions[key].Magic === true) element.visibleIf = 'false';

      // element.description = gruquestions[key].Text;

      //check for languages for title and description
      Object.keys(gruquestionproperties).forEach(function(key) {
        //found matching question
        if (gruquestionproperties[key].QuestionId.toString() === element.name) {
          if (gruquestionproperties[key].Value) {
            var jsonparse = null;
            try {
              jsonparse = JSON.parse(gruquestionproperties[key].Value);
            } catch (err) {}
            if (jsonparse) {
              for (var i = 0; i < jsonparse.length; i++) {
                const obj = jsonparse[i];
                //console.log(obj);
                const jsonparseLang = obj.Language;
                //console.log(jsonparseLang);
                const jsonparseFormatting = obj.Formatting;
                //console.log(jsonparseFormatting);
                if (jsonparseLang && jsonparseFormatting && jsonparseFormatting.Description) {
                  //@ts-ignore
                  titleelement[jsonparseLang] = jsonparseFormatting.Description;
                } else if (jsonparseLang && jsonparseFormatting && jsonparseFormatting.Title) {
                  //@ts-ignore
                  titleelement[jsonparseLang] = jsonparseFormatting.Title;
                }
              }
            }
          }
        }
      });

      //question restrictions
      //console.log("before json parse restrictions");
      var jsonparse = null;

      try {
        jsonparse = JSON.parse(gruquestions[key].Restrictions);
      } catch {}
      //console.log("after json parse restrictions");
      if (jsonparse && jsonparse.length > 0) {
        for (var i = 0; i < 1; i++) {
          //TODO: support for mutiple restrictions
          //for (var i = 0; i < jsonparse.length; i++) {
          const obj = jsonparse[i];
          console.log(obj);
          //found restriction type
          if (obj.type === 'Question') {
            if (obj.criteria) {
              var res = obj.criteria.split('|');
              console.log(res);
              //found a somewhat valid restriction so far
              if (
                res &&
                res.length > 2 &&
                !isNaN(res[0]) &&
                (res[1].toString() === '=' || res[1].toString() === '!=')
              ) {
                console.log('valid restriction');
                //found matching questionid
                Object.keys(gruquestions).forEach(function(keyquestions) {
                  if (gruquestions[keyquestions].Id.toString() === res[0].toString()) {
                    //singleline restriction
                    if (
                      gruquestions[keyquestions].Type === 'FreeFormSingleLine' ||
                      gruquestions[keyquestions].Type === 'FreeFormMultiLine'
                    ) {
                      //singleline equals and not equals
                      if (res[1].toString() === '=') {
                        //@ts-ignore
                        element.visibleIf = '{' + res[0].toString() + "}='" + res[2].toString() + "'";
                        //@ts-ignore
                        console.log('added FreeFormSingleLine = restriction: ' + element.visibleIf);
                      } else if (res[1].toString() === '!=') {
                        //@ts-ignore
                        element.visibleIf = '{' + res[0].toString() + "}!='" + res[2].toString() + "'";
                        //@ts-ignore
                        console.log('added FreeFormSingleLine != restriction: ' + element.visibleIf);
                      }

                      //standard restriction
                    } else if (
                      gruquestions[keyquestions].Type === 'Standard' ||
                      gruquestions[keyquestions].Type === 'NewYesNo' ||
                      gruquestions[keyquestions].Type === 'Checkbox'
                    ) {
                      //found matching answer if standard
                      Object.keys(gruquestionanswers).forEach(function(keyanswers) {
                        //answer matches id in restriction
                        //console.log("gruquestionanswers[keyanswers].Question.toString(): " + gruquestionanswers[keyanswers].Id.toString());
                        //console.log("res[2].toString(): " + res[2].toString());

                        if (gruquestionanswers[keyanswers].Id.toString() === res[2].toString()) {
                          //standard equals and not equals
                          if (res[1].toString() === '=') {
                            //@ts-ignore
                            element.visibleIf =
                              '{' + res[0].toString() + "}='" + gruquestionanswers[keyanswers].Value + "'";
                            //@ts-ignore
                            console.log('added Standard = restriction: ' + element.visibleIf);
                          } else if (res[1].toString() === '!=') {
                            //@ts-ignore
                            element.visibleIf =
                              '{' + res[0].toString() + "}!='" + gruquestionanswers[keyanswers].Value + "'";
                            //@ts-ignore
                            console.log('added Standard != restriction: ' + element.visibleIf);
                          }
                        }
                      });
                    }
                  }
                });
              }
            }
          }
        }
      }

      //required question
      if (gruquestions[key].Required) {
        element.isRequired = true;
      }

      //question types
      if (gruquestions[key].Type === 'ContactInfo') {
        foundcontactinfo = true;
      } else if (gruquestions[key].Type === 'FreeFormSingleLine') {
        element.type = 'text';
      } else if (gruquestions[key].Type === 'Email') {
        element.type = 'text';
      } else if (gruquestions[key].Type === 'Donation') {
        const gruproducts = petitionJsonIn.Products;
        const PaymentProcessorID = petitionJsonIn.PaymentProcessorId;

        if (petitionJsonIn.Products && PaymentProcessorID) {
          var questionsarrproducts: any = [];

          var productsonetime: any = [];
          var productsonetimeother = false;
          var productsweekly: any = [];
          var productsweeklyother = false;
          var productsmonthly: any = [];
          var productsmonthlyother = false;
          var productsquarterly: any = [];
          var productsquarterlyother = false;
          var productssemiannual: any = [];
          var productssemiannualother = false;
          var productsannual: any = [];
          var productsannualother = false;
          var productsrecurring: any = [];

          //get products from json
          Object.keys(gruproducts).forEach(function(key) {
            if (gruproducts[key].Recurring && gruproducts[key].Recurring.toString() === 'ONE-TIME') {
              if (gruproducts[key].Cost && gruproducts[key].Cost >= 0) {
                productsonetime.push(gruproducts[key].Cost);
              } else {
                productsonetimeother = true;
              }
            } else if (gruproducts[key].Recurring && gruproducts[key].Recurring.toString() === 'WEEKLY') {
              if (gruproducts[key].Cost && gruproducts[key].Cost >= 0) {
                productsweekly.push(gruproducts[key].Cost);
              } else {
                productsweeklyother = true;
              }
            } else if (gruproducts[key].Recurring && gruproducts[key].Recurring.toString() === 'MONTHLY') {
              if (gruproducts[key].Cost && gruproducts[key].Cost >= 0) {
                productsmonthly.push(gruproducts[key].Cost);
              } else {
                productsmonthlyother = true;
              }
            } else if (gruproducts[key].Recurring && gruproducts[key].Recurring.toString() === 'QUARTERLY') {
              if (gruproducts[key].Cost && gruproducts[key].Cost >= 0) {
                productsquarterly.push(gruproducts[key].Cost);
              } else {
                productsquarterlyother = true;
              }
            } else if (gruproducts[key].Recurring && gruproducts[key].Recurring.toString() === 'SEMIANNUALLY') {
              if (gruproducts[key].Cost && gruproducts[key].Cost >= 0) {
                productssemiannual.push(gruproducts[key].Cost);
              } else {
                productssemiannualother = true;
              }
            } else if (gruproducts[key].Recurring && gruproducts[key].Recurring.toString() === 'ANNUAL') {
              if (gruproducts[key].Cost && gruproducts[key].Cost >= 0) {
                productsannual.push(gruproducts[key].Cost);
              } else {
                productsannualother = true;
              }
            }
          });

          var productavailable = false;

          if (productsonetime.length > 0) {
            productavailable = true;

            productsrecurring.push('ONE-TIME');
          }
          if (productsweekly.length > 0) {
            productavailable = true;

            productsrecurring.push('WEEKLY');
          }
          if (productsmonthly.length > 0) {
            productavailable = true;

            productsrecurring.push('MONTHLY');
          }
          if (productsquarterly.length > 0) {
            productavailable = true;

            productsrecurring.push('QUARTERLY');
          }
          if (productssemiannual.length > 0) {
            productavailable = true;

            productsrecurring.push('SEMIANNUALLY');
          }
          if (productsannual.length > 0) {
            productavailable = true;

            productsrecurring.push('ANNUAL');
          }

          if (productavailable) {
            //setup recurring question
            var elementproductsrecurring = {
              type: 'radiogroup',
              name: 'Recurring Products',
              isRequired: true,
              startWithNewLine: true,
              title: { default: 'Select Recurring Type' },
              //choices: [],
              choices: productsrecurring, //["One Time", "Monthly", "Annual"]
            };

            questionsarrproducts.push(elementproductsrecurring);

            if (productsonetime.length > 0) {
              //setup question ONE-TIME
              var elementproductsonetime = {
                type: 'radiogroup',
                name: 'One Time Products',
                isRequired: true,
                startWithNewLine: true,
                visibleIf: "{Recurring Products}='ONE-TIME'",
                title: { default: 'Select One Time Amount' },
                //choices: [],
                choices: productsonetime, //["One Time $10", "One Time $15"]
                hasOther: productsonetimeother,
                otherText: 'Other Amount',
                validators: [
                  {
                    text: 'Please enter a numeric value',
                    type: 'numeric',
                    //maxValue: 999999999,
                  },
                ],
              };

              questionsarrproducts.push(elementproductsonetime);
            }

            if (productsweekly.length > 0) {
              //setup question WEEKLY
              var elementproductsweekly = {
                type: 'radiogroup',
                name: 'Weekly Products',
                isRequired: true,
                startWithNewLine: true,
                visibleIf: "{Recurring Products}='WEEKLY'",
                title: { default: 'Select Weekly Amount' },
                //choices: [],
                choices: productsweekly, //["One Time $10", "One Time $15"]
                hasOther: productsweeklyother,
                otherText: 'Other Amount',
                validators: [
                  {
                    text: 'Please enter a numeric value',
                    type: 'numeric',
                    //maxValue: 999999999,
                  },
                ],
              };

              questionsarrproducts.push(elementproductsweekly);
            }

            if (productsmonthly.length > 0) {
              //setup question MONTHLY
              var elementproductsmonthly = {
                type: 'radiogroup',
                name: 'Monthly Products',
                isRequired: true,
                startWithNewLine: true,
                visibleIf: "{Recurring Products}='MONTHLY'",
                title: { default: 'Select Monthly Amount' },
                //choices: [],
                choices: productsmonthly, //["One Time $10", "One Time $15"]
                hasOther: productsmonthlyother,
                otherText: 'Other Amount',
                validators: [
                  {
                    text: 'Please enter a numeric value',
                    type: 'numeric',
                    //maxValue: 999999999,
                  },
                ],
              };

              questionsarrproducts.push(elementproductsmonthly);
            }

            if (productsquarterly.length > 0) {
              //setup question QUARTERLY
              var elementproductsquarterly = {
                type: 'radiogroup',
                name: 'Quarterly Products',
                isRequired: true,
                startWithNewLine: true,
                visibleIf: "{Recurring Products}='QUARTERLY'",
                title: { default: 'Select Quarterly Amount' },
                //choices: [],
                choices: productsquarterly, //["One Time $10", "One Time $15"]
                hasOther: productsquarterlyother,
                otherText: 'Other Amount',
                validators: [
                  {
                    text: 'Please enter a numeric value',
                    type: 'numeric',
                    //maxValue: 999999999,
                  },
                ],
              };

              questionsarrproducts.push(elementproductsquarterly);
            }

            if (productssemiannual.length > 0) {
              //setup question SEMIANNUALLY
              var elementproductssemiannual = {
                type: 'radiogroup',
                name: 'Semiannual Products',
                isRequired: true,
                startWithNewLine: true,
                visibleIf: "{Recurring Products}='SEMIANNUALLY'",
                title: { default: 'Select Annual Amount' },
                //choices: [],
                choices: productssemiannual, //["One Time $10", "One Time $15"]
                hasOther: productssemiannualother,
                otherText: 'Other Amount',
                validators: [
                  {
                    text: 'Please enter a numeric value',
                    type: 'numeric',
                    //maxValue: 999999999,
                  },
                ],
              };

              questionsarrproducts.push(elementproductssemiannual);
            }

            if (productsannual.length > 0) {
              //setup question ANNUAL
              var elementproductannual = {
                type: 'radiogroup',
                name: 'Annual Products',
                isRequired: true,
                startWithNewLine: true,
                visibleIf: "{Recurring Products}='ANNUAL'",
                title: { default: 'Select Annual Amount' },
                //choices: [],
                choices: productsannual, //["One Time $10", "One Time $15"]
                hasOther: productsannualother,
                otherText: 'Other Amount',
                validators: [
                  {
                    text: 'Please enter a numeric value',
                    type: 'numeric',
                    //maxValue: 999999999,
                  },
                ],
              };

              questionsarrproducts.push(elementproductannual);
            }

            //var backup = false;
            var backupid = 0;
            const Backups = petitionJsonIn.PaymentProcessors;
            if (Backups) {
              Object.keys(Backups).forEach(function(key) {
                if (Backups[key].ProcessorTypeId && Backups[key].ProcessorTypeId > 0) {
                  backupid = Backups[key].ProcessorTypeId;
                }
              });
            }

            var PaymentOptions: any = [];

            //only allow cc for spreedly main
            if (PaymentProcessorID === 5) {
              PaymentOptions.push('Credit Card');

              //allow ach for litle main
            } else if (PaymentProcessorID === 2) {
              PaymentOptions.push('Check');
              //add cc if backup enabled
              if (backupid === 5) {
                PaymentOptions.push('Credit Card');
              }
              //allow ach for iats main
            } else if (PaymentProcessorID === 7) {
              PaymentOptions.push('Check');
              //add cc if backup enabled
              if (backupid === 7) {
                PaymentOptions.push('Credit Card');
              }
              //allow cc for ingenico main with spreedly backup
            } else if (PaymentProcessorID === 8) {
              //PaymentOptions.push('Check');
              //add cc if backup enabled
              if (backupid === 5) {
                PaymentOptions.push('Credit Card');
              }
            }

            //setup question Payment Type
            var elementpaymenttype = {
              type: 'radiogroup',
              name: 'Product Payment Type',
              isRequired: true,
              startWithNewLine: true,
              //visibleIf: '{One Time Products} notempty or {Weekly Products} notempty or {Monthly Products} notempty or {Annual Products} notempty',
              title: { default: 'Select Payment Type' },
              //choices: [],
              choices: PaymentOptions,
            };

            //push payment type question to array
            questionsarrproducts.push(elementpaymenttype);

            //push full array of product questions
            questionsarr.push(questionsarrproducts);

            //setup ach question
            var questionsarrcheck: any = [];

            //setup question account Type
            var elementpaymentaccountnumber = {
              type: 'text',
              name: 'Checking Account Number',
              isRequired: true,
              startWithNewLine: true,
              visibleIf: '{Product Payment Type}="Check"',
              title: { default: 'Enter Account Number' },
              validators: [
                {
                  type: 'numeric',
                  minValue: 1,
                  maxValue: 9999999999999999999,
                },
              ],
              //choices: [],
              //choices: ['Credit Card', 'Check'],
            };

            // add account number question to array
            questionsarrcheck.push(elementpaymentaccountnumber);

            //setup question routing number
            var elementpaymentroutingnumber = {
              type: 'text',
              name: 'Checking Routing Number',
              isRequired: true,
              startWithNewLine: true,
              visibleIf: '{Product Payment Type}="Check"',
              title: { default: 'Enter Routing Number' },
              validators: [
                {
                  text: 'Please enter a numeric value',
                  type: 'numeric',
                  //maxValue: 999999999,
                },
                {
                  text: 'Please enter only 9 numeric characters',
                  type: 'text',
                  minLength: 9,
                  maxLength: 9,
                },
              ],
              //choices: [],
              //choices: ['Credit Card', 'Check'],
            };

            // add routing number question to array
            questionsarrcheck.push(elementpaymentroutingnumber);

            //push checking account question
            questionsarr.push(questionsarrcheck);

            //setup donation iframe
            element.type = 'html';
            //@ts-ignore
            element.visibleIf = '{Product Payment Type}="Credit Card"';
            var htmlbase = {
              default: '<iframe id="payframe" src="" height="500" width="480" title="Iframe Example"></iframe>',
            };

            // @ts-ignore */
            element.html = htmlbase;
          } else {
            //setup empty products question
            var elementproducts = {
              type: 'radiogroup',
              name: 'VerifyProductsQuestion',
              isRequired: true,
              startWithNewLine: true,
              title: { default: 'Please verify the products available for this survey.' },
              //choices: [],
              choices: ['I verified the products on this survey'],
            };

            questionsarrproducts.push(elementproducts);

            //push empty product question
            questionsarr.push(questionsarrproducts);
          }
        } else {
          //setup empty products question
          var elementproducts = {
            type: 'radiogroup',
            name: 'VerifyProductsQuestion',
            isRequired: true,
            startWithNewLine: true,
            title: { default: 'Please verify the products available for this survey.' },
            //choices: [],
            choices: ['I verified the products on this survey'],
          };

          questionsarrproducts.push(elementproducts);

          //push empty product question
          questionsarr.push(questionsarrproducts);
        }
      } else if (gruquestions[key].Type === 'QueueCard') {
        element.type = 'html';

        var htmlbase = { default: '' };

        //examples QueueCard questions
        //<LIVE_LINK>https://www.grassrootsunwired.com/</LIVE_LINK>
        //<iframe src='https://www.grassrootsunwired.com/' height='200' width='300' title='Iframe Example'></iframe>
        //Hello this is ___, would you be interested in...

        Object.keys(gruquestionanswers).forEach(function(key) {
          if (gruquestionanswers[key].Question.toString() === element.name) {
            if (gruquestionanswers[key].Value) {
              var tempdoc = document.createElement('div');
              tempdoc.innerHTML = gruquestionanswers[key].Value;

              if (
                (tempdoc.innerHTML === gruquestionanswers[key].Value &&
                  gruquestionanswers[key].Value.toLowerCase().includes('live_link')) ||
                gruquestionanswers[key].Value.toLowerCase().includes('live_link')
              ) {
                var localhtml = gruquestionanswers[key].Value.trim();
                localhtml = localhtml.replaceAll('<LIVE_LINK>', '');
                localhtml = localhtml.replaceAll('<live_link>', '');
                localhtml = localhtml.replaceAll('</LIVE_LINK>', '');
                localhtml = localhtml.replaceAll('</live_link>', '');
                localhtml = localhtml.trim();
                localhtml = "<iframe src='" + localhtml + "' height='520' width='800' title='Iframe Example'></iframe>";
                htmlbase.default = localhtml;
                console.log('includes live link');
              } else if (tempdoc.innerHTML === gruquestionanswers[key].Value) {
                //supposed to be a good check for valid html
                htmlbase.default = gruquestionanswers[key].Value;
                console.log('does not include live link');
              } else {
                //htmlbase.default = "<html><body>Invalid html</body></html>";
                //just let this through because it happens to work sometimes even with invalid html
                htmlbase.default = gruquestionanswers[key].Value;
                console.log('possibly invalid html');
              }

              const answerId = gruquestionanswers[key].Id;

              //check for languages in answerproperties
              Object.keys(gruanswerproperties).forEach(function(key) {
                //found matching question
                if (gruanswerproperties[key].AnswerId.toString() === answerId.toString()) {
                  if (gruanswerproperties[key].Value) {
                    var jsonparse = null;
                    try {
                      jsonparse = JSON.parse(gruanswerproperties[key].Value);
                    } catch (err) {}

                    if (jsonparse) {
                      for (var i = 0; i < jsonparse.length; i++) {
                        const obj = jsonparse[i];
                        //console.log(obj);
                        const jsonparseLang = obj.Language;
                        //console.log(jsonparseLang);
                        const jsonparseFormatting = obj.Formatting;
                        //console.log(jsonparseFormatting);
                        if (jsonparseLang && jsonparseFormatting && jsonparseFormatting.Value) {
                          //@ts-ignore
                          htmlbase[jsonparseLang] = jsonparseFormatting.Value;
                        }
                      }
                    }
                  }
                }
              });
            }
          }
        });

        // @ts-ignore */
        element.html = htmlbase;
      } else if (gruquestions[key].Type === 'FreeFormMultiLine') {
        element.type = 'text';
      } else if (
        gruquestions[key].Type === 'Standard' ||
        gruquestions[key].Type === 'NewYesNo' ||
        gruquestions[key].Type === 'Checkbox'
      ) {
        element.type = 'radiogroup';

        var choiceslocal: any = [];

        Object.keys(gruquestionanswers).forEach(function(key) {
          if (gruquestionanswers[key].Question.toString() === element.name) {
            var choicebase = {
              text: {
                default: gruquestionanswers[key].Value,
              },
            };

            const answerId = gruquestionanswers[key].Id;

            //check for languages in answerproperties
            Object.keys(gruanswerproperties).forEach(function(key) {
              //found matching question
              if (gruanswerproperties[key].AnswerId.toString() === answerId.toString()) {
                if (gruanswerproperties[key].Value) {
                  const jsonparse = JSON.parse(gruanswerproperties[key].Value);
                  if (jsonparse) {
                    for (var i = 0; i < jsonparse.length; i++) {
                      const obj = jsonparse[i];
                      //console.log(obj);
                      const jsonparseLang = obj.Language;
                      //console.log(jsonparseLang);
                      const jsonparseFormatting = obj.Formatting;
                      //console.log(jsonparseFormatting);
                      if (jsonparseLang && jsonparseFormatting && jsonparseFormatting.Value) {
                        //@ts-ignore
                        choicebase.text[jsonparseLang] = jsonparseFormatting.Value;
                      }
                    }
                  }
                }
              }
            });

            //new method to support language
            choiceslocal.push(choicebase);

            //old method
            //choicebase.text.es = 'holaspanishtestanswer';
            //choiceslocal.push(gruquestionanswers[key].Value);
          }
        });

        //console.log(choiceslocal);
        element.choices = choiceslocal;
      }
      //TODO checkbox type without allowing you to check multiple items
      //else if (gruquestions[key].Type === 'Checkbox') {
      //  //element.type = "checkbox";
      //  //radiogroup for now
      //  element.type = 'radiogroup';

      //  var choiceslocal: any = [];

      // Object.keys(gruquestionanswers).forEach(function (key) {
      //   if (gruquestionanswers[key].Question.toString() === element.name) {
      //     choiceslocal.push(gruquestionanswers[key].Value);
      //   }
      // });

      //  element.choices = choiceslocal;
      //}

      // @ts-ignore
      if (LocalSubject !== null) {
        // @ts-ignore
        element.defaultValue = getDefaultSurveyValue(gruquestions[key].Variable, LocalSubject);
      }

      if (foundcontactinfo && isContactInfoQuestion(gruquestions[key].Variable)) {
        if (isContactInfoSameLine(gruquestions[key].Variable)) {
          element.startWithNewLine = false;
        }

        if (getContactInfoInputType(gruquestions[key].Variable) !== '') {
          // @ts-ignore
          element.inputType = getContactInfoInputType(gruquestions[key].Variable);

          if (gruquestions[key].Variable === '$ZIP') {
            // @ts-ignore
            element.inputType = 'text';

            var validatortemp = [{ type: 'numeric' }];
            // @ts-ignore
            element.validators = validatortemp;
          }
        }

        questionscontactinfoarr.push(element);
      } else {
        questionsarr.push(element);
      }
    });

    // console.log(questionscontactinfoarr);

    var pages: any = [];

    if (foundcontactinfo) {
      pages.push({
        name: 'Contact Info',
        title: 'Contact Info',
        elements: questionscontactinfoarr,
      });
    }

    var newPage = false;

    for (const [index, value] of questionsarr.entries()) {
      var localarray: any = [];

      var pagetemp = {
        name: '',
        title: '',
        elements: [],
      };

      if (value.type !== '') {
        if (!value.name) {
          if (!newPage) {
            pagetemp.name = 'ProductTest';
            pagetemp.title = value.tempTitle;
            localarray.push(value);
            pagetemp.elements = value;
            pages.push(pagetemp);
            newPage = true;
          } else {
            pagetemp.name = 'CheckingTest';
            pagetemp.title = value.tempTitle;
            localarray.push(value);
            pagetemp.elements = value;
            pages.push(pagetemp);
            newPage = true;
          }
        } else {
          pagetemp.name = value.name;
          pagetemp.title = value.tempTitle;
          localarray.push(value);
          pagetemp.elements = localarray;
          pages.push(pagetemp);
        }
      }
    }

    //all on one page
    //var jsontest2 = {
    //    "title": "New Grassroots Survey",
    //    "pages": [
    //        {
    //            "name": "Contact Info",
    //            "title": "Contact Info",
    //            "elements": questionsarr
    //        }
    //    ]
    //};

    var surveyTitle = 'New Grassroots Survey';

    if (language === 'es') {
      surveyTitle = 'Nueva encuesta de Grassroots';
    }

    // @ts-ignore
    if (LocalSubject !== null) {
      // @ts-ignore
      surveyTitle = 'Survey for ' + LocalSubject.FirstName + ' ' + LocalSubject.LastName;
    }

    //all on separate pages
    var jsonSurveyModel = {
      //cookieName: mobileSurveyId,
      title: surveyTitle,
      pages: pages,
    };

    //console.log(jsonSurveyModel);

    Survey.StylesManager.applyTheme('modern');

    //log full survey
    //console.log(jsonSurveyModel);

    var surveylocal = new Survey.Model(jsonSurveyModel);

    if (language === 'es') {
      surveylocal.previewText = 'Vista Previa';
    }

    //one method to branch by dynamically adding or removing pages
    //surveylocal.removePage(surveylocal.getPageByName("Contact Info"));

    //onNextPage is default mode
    surveylocal.checkErrorsMode = 'onValueChanged';

    surveylocal.showPageNumbers = false;

    //noPreview is default, showAllQuestions is another option
    surveylocal.showPreviewBeforeComplete = 'showAnsweredQuestions';

    surveylocal.showProgressBar = 'top';
    //surveylocal.showTimerPanel = "top";
    surveylocal.showQuestionNumbers = 'off';

    //options are none, onComplete, onHidden.  OnHidden will prevent answers being sent that shouldnt be sent, more tedious if canvasser
    //messes up but safer in the end.
    surveylocal.clearInvisibleValues = 'onHidden';

    //set default language or current selected language
    surveylocal.locale = language;

    //reset start time
    setSurveyStartString(getFormattedDate(new Date()));

    //reset mobile survey id
    if (!publicSurvey) {
      setSurveyMobileID(uuidv4());
    }

    //set survey model state
    setSurveyModelState(surveylocal);
  }

  let contextValue = {
    error,
    setError,
    isFetching,
    isFetchingWalklist,
    isFetchingPetition,
    isFetchingLogin,
    loginState,
    petition,
    petitionjson,
    walklist,
    walklistjson,
    walklistjsonLocations,
    surveySubject,
    language,
    activeSinkId,
    setActiveSinkId,
    settings,
    //mobileSurveyId,
    loggedUsername,
    loggedImei,
    setLoggedImei,
    //loggedPass,
    surveySent,
    surveyPublic,
    server,
    surveyModel,
    surveyStartString,
    surveyMobileID,
    surveyExecuteSale,
    setSurveyExecuteSale,
    surveyExecuteSaleValid,
    setSurveyExecuteSaleValid,
    dispatchSetting,
    newTextMessages,
    setNewTextMessages,
    newTextMessageList,
    setNewTextMessageList,
    roomsOpen,
    setRoomsOpen,
    totalRooms,
    setTotalRooms,
    roomList,
    setRoomList,
    myCurrentRoom,
    setMyCurrentRoom,
    screenShareTrack,
    setScreenShareTrack,
    screenSharePublication,
    setScreenSharePublication,
    darkMode,
    setDarkMode,
    textToPush,
    setTextToPush,
    mobileMode,
    setMobileMode,
  } as StateContextType;

  //if (process.env.REACT_APP_SET_AUTH === 'firebase') {
  //  contextValue = {
  //    ...contextValue,
  //    ...useFirebaseAuth(), // eslint-disable-line react-hooks/rules-of-hooks
  //  };
  //} else if (process.env.REACT_APP_SET_AUTH === 'passcode') {
  //  contextValue = {
  //    ...contextValue,
  //    ...usePasscodeAuth(), // eslint-disable-line react-hooks/rules-of-hooks
  //  };
  //} else {
  contextValue = {
    ...contextValue,
    getToken: async (username, password, identity, roomName) => {
      const headers = new window.Headers();

      headers.set('Authorization', 'Bearer ' + sessionStorage.getItem('customg'));
      let petitionid = '0';
      //const endpoint = process.env.REACT_APP_TOKEN_ENDPOINT || '/accessTokenVideo';
      const params = new window.URLSearchParams({ identity, roomName, petitionid });

      //return fetch(`${endpoint}?${params}`, { headers }).then(res => res.text());
      return fetch(`https://${server}/api/v2/twilio/accessTokenVideo?${params}`, {
        headers: headers,
        method: 'GET',
      }).then(res => res.text());
    },
    getLogin: async (username, password, server) => {
      const headers = new window.Headers();

      headers.set('Content-Type', 'application/x-www-form-urlencoded');

      require('dotenv').config();
      //return fetch(`http://localhost:52144/api/token`, {
      return fetch(`https://${server}/api/token`, {
        //return fetch(`http://localhost:52902/api/token`, {
        headers: headers,
        method: 'POST',
        body: new URLSearchParams({
          Grant_type: `${process.env.REACT_APP_GRANT}`,
          Username: `${username}`,
          Password: `${password}`,
          Client_id: `${process.env.REACT_APP_CLIENT_ID}`,
          Client_secret: `${process.env.REACT_APP_CLIENT_SECRET}`,
        }),
      }).then(res => {
        if (res.ok) {
          return res.text();
        } else {
          throw new Error('Failed to login');
        }
      });
    },
    getPetition: async (username, password, server) => {
      const headers = new window.Headers();

      headers.set('Authorization', 'Bearer ' + sessionStorage.getItem('customg'));
      //return fetch(`http://localhost:52902/api/v2/petition/get`, {
      return fetch(`https://${server}/api/v2/petition/get`, {
        //return fetch(`https://${server}.grassrootsunwired.com/api/petition/get`, {
        headers: headers,
        method: 'GET',
      }).then(res => {
        if (res.ok) {
          return res.text();
        } else {
          throw new Error('Failed to get petition');
        }
      });
    },
    getWalklist: async (username, password, server) => {
      const headers = new window.Headers();
      headers.set('Authorization', 'Bearer ' + sessionStorage.getItem('customg'));
      //console.log(`https://${server}.grassrootsunwired.com/api/petition/get`);
      //return fetch(`http://localhost:52902/api/v2/walklist/get`, {
      return fetch(`https://${server}/api/v2/walklist/get`, {
        headers: headers,
        method: 'GET',
      }).then(res => {
        if (res.ok) {
          return res.text();
        } else {
          throw new Error('Failed to get walklist');
        }
      });
    },
    sendAnswers: async (username, password, body) => {
      const headers = new window.Headers();

      headers.set('Authorization', 'Bearer ' + sessionStorage.getItem('customg'));

      headers.set('imei', loggedImei); //'a5c2ab7e5305232');
      headers.set('Content-Type', 'application/json');
      return fetch(`https://${server}/api/v2/petition/answers`, {
        headers: headers,
        method: 'PUT',
        body: JSON.stringify(body),
      }).then(res => {
        if (res.ok || res.status === 412) {
          return res.text();
        } else {
          throw new Error('Failed to send answers');
        }
      });
    },
    closeRoom: async (room, sid) => {
      const headers = new window.Headers();

      headers.set('Authorization', 'Bearer ' + sessionStorage.getItem('customg'));

      headers.set('imei', loggedImei); //'a5c2ab7e5305232');
      headers.set('Content-Type', 'application/json');
      return fetch(`https://${server}/api/v2/twilio/closeVideoRoom?room=${room}&sid=${sid}`, {
        headers: headers,
        method: 'POST',
      }).then(res => {
        if (res.status === 200) {
          return res.text();
        } else {
          throw new Error('Failed to close room');
        }
      });
    },
    setWalklist: (newWalklist: any) => {
      setWalklistjson(newWalklist);
    },
    setSurveySubject: (surveySubjectIn: any) => {
      setSurveyPublic(false);
      setSurveyExecuteSale(false);
      setSurveyExecuteSaleValid(false);
      setSurveySubjectState(surveySubjectIn);
      LocalSubject = surveySubjectIn;
      generateSurvey(petitionjson);
    },
    setSurveySubjectPublic: (
      surveySubjectIn: any,
      petitionJsonIn,
      server: string,
      userName: string,
      surveyId: string
    ) => {
      setSurveyPublic(true);
      setSurveyExecuteSale(false);
      setSurveyExecuteSaleValid(false);
      setLoggedUsername(userName);
      setSurveyMobileID(surveyId);
      setServer(server);
      setPetitionjson(petitionJsonIn);
      setSurveySubjectState(surveySubjectIn);
      LocalSubject = surveySubjectIn;
      generateSurvey(petitionJsonIn, true);
    },
    setLanguage: (LanguageIn: any) => {
      setLanguageState(LanguageIn);
      surveyModel.locale = LanguageIn;

      //reset survey title
      var surveyTitle = 'New Grassroots Survey';

      if (LanguageIn === 'es') {
        surveyTitle = 'Nueva encuesta de Grassroots';
      }

      //reset preview text
      var previewText = 'Preview';

      if (LanguageIn === 'es') {
        previewText = 'Vista Previa';
      }

      // @ts-ignore
      if (LocalSubject !== null) {
        if (LanguageIn === 'es') {
          // @ts-ignore
          surveyTitle = 'Encuesta para ' + LocalSubject.FirstName + ' ' + LocalSubject.LastName;
        } else {
          // @ts-ignore
          surveyTitle = 'Survey for ' + LocalSubject.FirstName + ' ' + LocalSubject.LastName;
        }
      }
      surveyModel.title = surveyTitle;
      surveyModel.previewText = previewText;

      surveyModel.render();
      //LocalLanguage = LanguageIn;
    },
  };
  //}

  const getToken: StateContextType['getToken'] = (user, pass, name, room) => {
    setIsFetching(true);
    return contextValue
      .getToken(user, pass, name, room)
      .then(res => {
        setIsFetching(false);
        return res;
      })
      .catch(err => {
        setError(err);
        setIsFetching(false);
        return Promise.reject(err);
      });
  };

  const getLogin: StateContextType['getLogin'] = (name, pass, server) => {
    setIsFetching(true);
    setIsFetchingLogin(true);
    sessionStorage.clear();
    //setLoggedImei('react00' + name);
    return contextValue
      .getLogin(name, pass, server)
      .then(res => {
        const jsonparse = JSON.parse(res);
        if (jsonparse.access_token) {
          sessionStorage.setItem('customg', jsonparse.access_token);
        } else {
          setIsFetching(false);
          setIsFetchingLogin(false);
          throw new Error('Failed to login');
        }
        setIsFetching(false);
        setIsFetchingLogin(false);
        return 'OK';
      })
      .catch(err => {
        //setError(err);
        setIsFetching(false);
        setIsFetchingLogin(false);
        return Promise.reject(err);
      });
  };

  const getPetition: StateContextType['getPetition'] = (name, pass, server) => {
    setIsFetching(true);
    setIsFetchingPetition(true);
    return contextValue
      .getPetition(name, pass, server)
      .then(res => {
        //console.log(res);
        //var myObject = JSON.parse(res);

        setPetition(res);
        const jsonparse = JSON.parse(res);
        setPetitionjson(jsonparse);
        generateSurvey(jsonparse);
        setIsFetching(false);
        setIsFetchingPetition(false);
        //setLoginState('petition');
        //LoginState = 'petition';
        return res;
      })
      .catch(err => {
        //setError(err);
        setIsFetching(false);
        setIsFetchingPetition(false);
        return Promise.reject(err);
      });
  };

  const getWalklist: StateContextType['getWalklist'] = (name, pass, server) => {
    setIsFetching(true);
    setIsFetchingWalklist(true);
    return contextValue
      .getWalklist(name, pass, server)
      .then(res => {
        //console.log("res:" + res);

        setWalklist(res);
        //if (!res && res.length > 0) {
        if (res) {
          const jsonparse = JSON.parse(res);
          setWalklistjson(jsonparse);

          let tmpLocation: object[] = [];

          const checkLocation = (location: string, tmplocations: object[]) => {
            var status: Boolean = false;
            tmplocations.map((w: any, i: any) => {
              if (w.location == location) status = true;
            });
            return status;
          };

          jsonparse?.Walklist?.map((w: any, i: any) => {
            let location: string = w.Address;
            let Cell: string = w.Cell;
            if (!checkLocation(location, tmpLocation)) {
              tmpLocation.push({ location, Cell });
            }
          });

          setWalklistjsonLocations(tmpLocation);
        } else {
          //@ts-ignore
          setWalklistjson(res);
        }
        setLoggedUsername(name);
        //setLoggedImei('react00' + name);
        setServer(server);
        //jsonparse.sort((a, b) => a.Address - b.Address);

        setIsFetching(false);
        setIsFetchingWalklist(false);
        setLoginState('walklist');
        return res;
      })
      .catch(err => {
        //console.log("error in walklist catch: " + err);
        //setError(err);
        setIsFetching(false);
        setIsFetchingWalklist(false);
        return Promise.reject(err);
      });
  };

  const sendAnswers: StateContextType['sendAnswers'] = (name, pass, body) => {
    //setIsFetching(true);
    return contextValue
      .sendAnswers(name, pass, body)
      .then(res => {
        //console.log(res);
        setSurveySent(true);
        //setWalklist(res);
        //var jsonparse = JSON.parse(res);
        //setLoggedusername(name);
        //setWalklistjson(jsonparse);
        //setIsFetching(false);
        //setIsFetchingWalklist(false);
        //setLoginState('walklist');
        return res;
      })
      .catch(err => {
        //setError(err);
        //setIsFetching(false);
        //setIsFetchingWalklist(false);
        return Promise.reject(err);
      });
  };

  const closeRoom: StateContextType['closeRoom'] = (room, sid) => {
    //setIsFetching(true);
    return contextValue
      .closeRoom(room, sid)
      .then(res => {
        //console.log(res);
        //setSurveySent(true);
        //setWalklist(res);
        //var jsonparse = JSON.parse(res);
        //setLoggedusername(name);
        //setWalklistjson(jsonparse);
        //setIsFetching(false);
        //setIsFetchingWalklist(false);
        //setLoginState('walklist');
        return res;
      })
      .catch(err => {
        //setError(err);
        //setIsFetching(false);
        //setIsFetchingWalklist(false);
        return Promise.reject(err);
      });
  };

  return (
    <StateContext.Provider
      value={{ ...contextValue, getToken, getLogin, getPetition, getWalklist, sendAnswers, closeRoom }}
    >
      {props.children}
    </StateContext.Provider>
  );
}

export function useAppState() {
  const context = useContext(StateContext);
  if (!context) {
    throw new Error('useAppState must be used within the AppStateProvider');
  }
  return context;
}
