import React, { useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Button from '@material-ui/core/Button';
import { green } from '@material-ui/core/colors';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      height: '50px',
      width: '50%',
      marginLeft: '50%',
      color: 'white',
      backgroundColor: green[500],
      '&:hover': {
        backgroundColor: green[700],
      },
    },
    checkbox: {
      position: 'absolute',
      right: '5px',
    },
    title: {
      fontSize: '24px',
      fontWeight: 'bold',
      margin: '0px',
      padding: '18px 0px 10px 15px',
    },
    optionText: {
      fontSize: '16px',
    },
  })
);

export default function FilterDialogue(props: any) {
  const classes = useStyles();
  const { open, onClose, subjectInfo } = props;
  const [status, setStatus] = useState(-1);

  const handleChange = (event: any) => {
    setStatus(event.target.value);
  };

  const closeDialogueHandler = (status: number, updateStatus: boolean) => {
    setStatus(-1);
    onClose(status, status === -1 ? false : updateStatus);
  };

  return (
    <Dialog
      onClose={() => closeDialogueHandler(0, false)}
      aria-labelledby="simple-dialog-title"
      open={open}
      fullWidth={true}
      maxWidth={'xs'}
    >
      <p className={classes.title}>
        Knock Status - {subjectInfo?.FirstName} {subjectInfo?.LastName}
      </p>
      <List>
        <ListItem autoFocus>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={status === -1 ? Number(subjectInfo?.Status) : status}
            onChange={handleChange}
          >
            {props.knockStatuses.map((value: any, index: number) => (
              <MenuItem value={value.LocalId} key={index}>
                {value.Name}
              </MenuItem>
            ))}
          </Select>
        </ListItem>
        <ListItem autoFocus>
          <Button
            className={classes.button}
            type="submit"
            color="primary"
            variant="contained"
            onClick={() => closeDialogueHandler(status, true)}
          >
            Confirm
          </Button>
        </ListItem>
      </List>
    </Dialog>
  );
}
