import React, { useState, useRef, useCallback } from 'react';
import AboutDialog from '../AboutDialog/AboutDialog';
import IconButton from '@material-ui/core/IconButton';
import MenuContainer from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreIcon from '@material-ui/icons/MoreVert';
import SettingsDialog from '../SettingsDialog/SettingsDialog';
import UserAvatar from '../UserAvatar/UserAvatar';
import { getFormattedDate } from '../../Petition/Survey';

import { useAppState } from '../../../state';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';

//import { useHistory } from 'react-router-dom';

export default function Menu() {
  const { loggedUsername, signOut, server, loggedImei } = useAppState();
  const { room, localTracks } = useVideoContext();

  const [aboutOpen, setAboutOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [settingsOpen, setSettingsOpen] = useState(false);

  const anchorRef = useRef<HTMLDivElement>(null);

  //const history = useHistory();

  const logoutPutHandler = async () => {
    const headers = new window.Headers();
    headers.set('Authorization', 'Bearer ' + sessionStorage.getItem('customg'));
    headers.set('imei', loggedImei); //'a5c2ab7e5305232');
    headers.set('Content-Type', 'application/json');
    headers.set('Accept', 'application/json, text/plain, */*');

    let body = {
      Latitude: 40.1,
      Longitude: -74.8,
      Type: 'GPS',
      Uncertainty: 50,
      Time: getFormattedDate(new Date()),
      Batterylevel: 0,
      signalStrength: -120,
      RunningAppscount: 30,
      Brightness: 200,
      Bluetooth: true,
      Bondeddevices: 2,
      Wifi: false,
      Imei: loggedImei, //'a5c2ab7e5305232',
      Phonemodel: 'None',
      Networktype: 'LTE',
    };

    await fetch(`https://` + server + `/api/v2/events/logout`, {
      headers: headers,
      method: 'PUT',
      body: JSON.stringify(body),
      keepalive: true,
    }).then(res => {
      if (res.status === 201 || res.status === 200) {
        console.log('User logged out successfully');
      }
    });
  };

  window.onbeforeunload = () => {
    logoutPutHandler();
  };

  const handleSignOut = useCallback(() => {
    room.disconnect?.();
    localTracks.forEach(track => track.stop());
    signOut?.();
    logoutPutHandler()
      .then(() => {
        window.location.reload(true);
      })
      .catch(err => {
        window.location.reload(true);
      });
    //this method is quicker but does not reset variables
    //history.push("/");
    //this method resets all the variables as well
  }, [room.disconnect, localTracks, signOut]);

  return (
    <div ref={anchorRef}>
      <IconButton color="inherit" onClick={() => setMenuOpen(state => !state)}>
        {loggedUsername ? <UserAvatar user={loggedUsername} /> : <MoreIcon />}
      </IconButton>
      <MenuContainer open={menuOpen} onClose={() => setMenuOpen(state => !state)} anchorEl={anchorRef.current}>
        {loggedUsername && <MenuItem disabled>{loggedUsername}</MenuItem>}
        <MenuItem onClick={() => setAboutOpen(true)}>About</MenuItem>
        <MenuItem onClick={() => setSettingsOpen(true)}>Settings</MenuItem>
        {loggedUsername && <MenuItem onClick={handleSignOut}>Logout</MenuItem>}
      </MenuContainer>
      <AboutDialog
        open={aboutOpen}
        onClose={() => {
          setAboutOpen(false);
          setMenuOpen(false);
        }}
      />
      <SettingsDialog
        open={settingsOpen}
        onClose={() => {
          setSettingsOpen(false);
          setMenuOpen(false);
        }}
      />
    </div>
  );
}
