import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useAppState } from '../../../state';

import CallEnd from '@material-ui/icons/CallEnd';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';

import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fab: {
      margin: theme.spacing(1),
      backgroundColor: '#F22F46',
      color: 'white',
      '&:hover': {
        backgroundColor: '#d11b31',
      },
    },
  })
);

export default function EndCallButton() {
  const classes = useStyles();
  const { room } = useVideoContext();
  const { setMyCurrentRoom, closeRoom, loggedUsername } = useAppState();

  const disconnectHandler = () => {
    const roomname = room?.name;
    const roomsid = room?.sid;
    room?.disconnect?.();
    if (roomname && roomsid && loggedUsername) {
      closeRoom(roomname, roomsid)
        .then(() => {
          alert('Successfully closed video room.');
          console.log('Successfully closed video room.');
        })
        .catch((err: string) => {
          console.log(err);
          alert('Unable to close video room for all participants.');
        });
    }
    setMyCurrentRoom('');
  };

  return (
    <Tooltip title={'End Call'} onClick={disconnectHandler} placement="top" PopperProps={{ disablePortal: true }}>
      <Fab className={classes.fab}>
        <CallEnd />
      </Fab>
    </Tooltip>
  );
}
