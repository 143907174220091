import React, { useState } from 'react';
import Participant from '../Participant/Participant';
import { styled, createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import useParticipants from '../../hooks/useParticipants/useParticipants';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import useSelectedParticipant from '../VideoProvider/useSelectedParticipant/useSelectedParticipant';
import './JoinPageParticipantStrip.css';
import MainParticipant from '../MainParticipant/MainParticipant';
import useMainSpeaker from '../../hooks/useMainSpeaker/useMainSpeaker';
import useScreenShareParticipant from '../../hooks/useScreenShareParticipant/useScreenShareParticipant';

const Container = styled('aside')(({ theme }) => ({
  overflowY: 'auto',
  height: '100%',
}));

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    videoContainer: {
      border: '3px solid #303030',
    },
  })
);

export default function ParticipantStrip() {
  const {
    room: { localParticipant },
  } = useVideoContext();
  const participants = useParticipants();
  const [selectedParticipant, setSelectedParticipant] = useSelectedParticipant();
  const classes = useStyles();
  const primaryParticipant = useMainSpeaker();
  const screenShareParticipant = useScreenShareParticipant();
  const [isSharingScreen, setIsSharingScreen] = useState(false);

  React.useEffect(() => {
    if (primaryParticipant.tracks.size === 0) {
      setIsSharingScreen(false);
    } else {
      setIsSharingScreen(true);
    }
  }, [primaryParticipant.tracks.size]);

  return (
    <Container>
      <div className={'joinPageParticipantStripScrollContainer'}>
        <div className={classes.videoContainer}>
          {screenShareParticipant === undefined ? (
            <Participant
              participant={localParticipant}
              isSelected={selectedParticipant === localParticipant}
              onClick={() => null}
              customStyles={null}
            />
          ) : (
            <MainParticipant />
          )}
        </div>
        <div className={classes.videoContainer}>
          {participants.map(participant => (
            <Participant
              key={participant.sid}
              participant={participant}
              isSelected={selectedParticipant === participant}
              onClick={() => null}
              customStyles={null}
            />
          ))}
        </div>
      </div>
    </Container>
  );
}
