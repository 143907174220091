import React from 'react';
import ReactDOM from 'react-dom';

import { CssBaseline, ThemeProvider } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';

import App from './App';
import AppStateProvider, { useAppState } from './state';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import ErrorDialog from './components/ErrorDialog/ErrorDialog';
import generateConnectionOptions from './utils/generateConnectionOptions/generateConnectionOptions';
import Login from './components/Login/Login';
import JoinApp from './components/JoinRoom/JoinApp';
import PublicPetition from './components/PublicPetition/PublicPetition';
//import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import { lightTheme, darkTheme } from './theme';
import './types';
import { VideoProvider } from './components/VideoProvider';
import { useLocation, useHistory } from 'react-router-dom';

let localDarkMode = false;

const GrassApp = () => {
  const { error, setError, settings, loggedUsername, darkMode } = useAppState();
  const history = useHistory();

  if (!sessionStorage.getItem('customg') || !loggedUsername) {
    history.replace('/');
    return null;
  }

  const connectionOptions = generateConnectionOptions(settings);

  return (
    <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
      <CssBaseline />
      <VideoProvider options={connectionOptions} onError={setError}>
        <ErrorDialog dismissError={() => setError(null)} error={error} />
        <App />
      </VideoProvider>
    </ThemeProvider>
  );
};

//redirect /join/.... to JoinApp
const JoinRoomApp = () => {
  const { error, setError, settings } = useAppState();

  const connectionOptions = generateConnectionOptions(settings);

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <VideoProvider options={connectionOptions} onError={setError}>
        <ErrorDialog dismissError={() => setError(null)} error={error} />
        <JoinApp />
      </VideoProvider>
    </ThemeProvider>
  );
};

//redirect /survey/.... to PublicSurvey app
const PublicSurveyApp = () => {
  const { error, setError, settings } = useAppState();

  //const connectionOptions = generateConnectionOptions(settings);

  //<VideoProvider options={connectionOptions} onError={setError}>
  //  <ErrorDialog dismissError={() => setError(null)} error={error} />
  //  <JoinApp />
  //</VideoProvider>

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <PublicPetition />
    </ThemeProvider>
  );
};

ReactDOM.render(
  <Router>
    <AppStateProvider>
      <Switch>
        {/*
        <PrivateRoute path="/room/:URLRoomName">
          <GrassApp />
        </PrivateRoute>
        */}
        <Route exact path="/">
          <Login />
        </Route>

        <Route path="/start">
          <GrassApp />
        </Route>

        <Route path="/join">
          <JoinRoomApp />
        </Route>

        <Route path="/survey">
          <PublicSurveyApp />
        </Route>

        <Redirect to="/" />
      </Switch>
    </AppStateProvider>
  </Router>,
  document.getElementById('root')
);
