import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { green } from '@material-ui/core/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { useAppState } from '../../state';
import { motion } from 'framer-motion';

const refreshSound = require('../../utils/AudioFiles/refreshSound.wav');

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      height: '45px',
      width: '100%',
      minWidth: '320px',
      backgroundColor: theme.tertiaryColors.banner,
      display: 'grid',
      alignItems: 'center',
      borderBottom: '1px solid #606060',
      position: 'relative',
    },
    button: {
      height: '70%',
      width: '150px',
      marginLeft: '12px',
      backgroundColor: green[500],
      '&:hover': {
        backgroundColor: green[700],
      },
      color: 'white',
    },
    filter: {
      height: '70%',
      width: '125px',
      marginLeft: '12px',
      position: 'absolute',
      top: '50%',
      right: '62px',
      transform: 'translate(0%, -50%)',
      backgroundColor: '#606060',
      '&:hover': {
        backgroundColor: '#404040',
      },
      color: 'white',
    },
    filterIcon: {
      position: 'relative',
      left: '15px',
      fontSize: '1rem',
    },
    refreshContainer: {
      position: 'absolute',
      top: '50%',
      right: '10px',
      transform: 'translate(0%, -50%)',
      backgroundColor: '#606060',
      '&:hover': {
        backgroundColor: '#404040',
      },
      cursor: 'pointer',
      height: '70%',
      width: '40px',
      display: 'grid',
      alignItems: 'center',
      justifyItems: 'center',
      borderRadius: '8px',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.6)',
      transitionDuration: '150ms',
    },
    refreshIcon: {
      fontSize: '20px',
      color: 'white',
    },
  })
);

export default function WalklistHeader(props: any) {
  const classes = useStyles();

  const { getWalklist, loggedUsername, server, mobileMode } = useAppState();

  const updateWalklistHandler = () => {
    props.playSound(refreshSound, 3);
    getWalklist(loggedUsername, '', server);
  };

  const drawerTransition = mobileMode
    ? {
        in: {
          x: '0%',
          opacity: 1,
        },
        out: {
          x: '-20%',
          opacity: 0,
        },
        initial: {
          x: '-20%',
          opacity: 0,
        },
      }
    : {
        in: {},
        out: {},
        initial: {},
      };

  return (
    <motion.div
      key="asdokjgvlfn0wefu0"
      className={classes.container}
      initial={drawerTransition.initial}
      animate={drawerTransition.in}
      exit={drawerTransition.out}
      transition={{ duration: 0.3 }}
    >
      <Button className={classes.button} type="submit" color="primary" variant="contained" onClick={props.newSurvey}>
        {' '}
        New Survey
      </Button>
      <Button className={classes.filter} type="submit" color="primary" variant="contained" onClick={props.onClick}>
        Filter <FontAwesomeIcon icon={faFilter} className={classes.filterIcon} />
      </Button>
      <div className={classes.refreshContainer} onClick={() => updateWalklistHandler()}>
        <FontAwesomeIcon icon={faSyncAlt} className={classes.refreshIcon} />
      </div>
    </motion.div>
  );
}
