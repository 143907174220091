import { createMuiTheme } from '@material-ui/core';
import { green, purple } from '@material-ui/core/colors';

declare module '@material-ui/core/styles/createMuiTheme' {
  interface Theme {
    sidebarWidth: number;
    sidebarMobileHeight: number;
    tertiaryColors: {
      default: string;
      paper: string;
      banner: string;
    };
  }

  // allow configuration using `createMuiTheme`
  interface ThemeOptions {
    sidebarWidth?: number;
    sidebarMobileHeight?: number;
    tertiaryColors?: {
      default: string;
      paper: string;
      banner: string;
    };
  }
}

// #F22F46 --- Red color

export const lightTheme = createMuiTheme({
  palette: {
    type: 'light', //light for light theme
    primary: {
      main: '#000',

      //main: green[500],
    },
    secondary: {
      main: green[500],
    },
    background: {
      paper: '#e9e9e9',
      // default: light ? '#e6e6e6' : '#303030'
    },
  },
  tertiaryColors: {
    default: '#dadada',
    paper: '',
    banner: '#dbdbdb',
  },
  sidebarWidth: 260,
  sidebarMobileHeight: 90,
});

export const darkTheme = createMuiTheme({
  palette: {
    type: 'dark', //light for light theme
    primary: {
      main: '#fff',

      //main: green[500],
    },
    secondary: {
      main: green[500],
    },
    background: {
      paper: '#424242',
      // default: light ? '#e6e6e6' : '#303030'
    },
  },
  tertiaryColors: {
    default: '#505050',
    paper: '',
    banner: '#202020',
  },
  sidebarWidth: 260,
  sidebarMobileHeight: 90,
});
