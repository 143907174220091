import React, { useState } from 'react';
import { makeStyles, styled } from '@material-ui/core/styles';

import Controls from './components/Controls/Controls';
import MenuBarStart from './components/MenuBarStart/MenuBarStart';
import ReconnectingNotification from './components/ReconnectingNotification/ReconnectingNotification';
import List from './components/Petition/List';
import Survey from './components/Petition/Survey';

import PropTypes from 'prop-types';

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import getFilteredPhoneNumber from './utils/getFilteredPhoneNumber/getFilteredPhoneNumber';

import useHeight from './hooks/useHeight/useHeight';
import { useAppState } from './state';
import VideoRoomList from './components/VideoRoomList/viewRoomList';
import VideoViewer from './components/MenuBarStart/VideoViewer/VideoViewer';
import './App.css';
import useRoomState from './hooks/useRoomState/useRoomState';
import BottomVideoParticipantStrip from './components/BottomVideoParticipantStrip/BottomVideoParticipantStrip';
import * as $ from 'jquery';
import MainParticipant from './components/MainParticipant/MainParticipant';
import useVideoContext from './hooks/useVideoContext/useVideoContext';
import useMainSpeaker from './hooks/useMainSpeaker/useMainSpeaker';
import useScreenShareParticipant from './hooks/useScreenShareParticipant/useScreenShareParticipant';
import playSound from './utils/PlaySound/PlaySound';
import MobileMenuBar from './components/MobileMenuBar/MobileMenuBar';
import MobileNavigationDrawer from './components/MobileNavigationDrawer/MobileNavigationDrawer';
import { AnimatePresence } from 'framer-motion';
import { AnyARecord } from 'dns';

//ES5 Import used here because typescript didn't like these imported with ES6 syntax
const newRoomCreatedSound = require('./utils/AudioFiles/newRoomCreatedSound.wav');
const newTextMessageSound = require('./utils/AudioFiles/newTextMessageSound.wav');

declare global {
  interface Window {
    jQuery: any;
  }
}

var LocalTotalRooms = 0;
var LocalOpenRooms = 0;
var LocalNewMessages = 0;
var LocalMessageList: Array<Array<any>> = [];
var LocalRoomList: Array<any> = [];

var LocalCurrentRoom = '';
let currentRoomUpdates = 0;

let LocalAutomaticUpdates = 0;
let LocalPhoneNumber = '';
let participantUpdateQueue: Array<Array<any>> = [];

window.jQuery = $;
var test2 = require('signalr');

const Container = styled('div')({
  display: 'grid',
  gridTemplateRows: 'auto auto 1fr',
  //overflow: 'hidden',
});

const Main = styled('main')({
  //overflow: 'hidden',
});

function TabPanel(props: {
  [x: string]: any;
  children: any;
  value: any;
  index: any;
  className: any;
  hiddenContainer: any;
}) {
  const { children, value, index, className, hiddenContainer, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={false}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      className={value === index ? className : hiddenContainer}
      {...other}
    >
      {children}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export default function App() {
  const useStyles = makeStyles(theme => ({
    root: {
      //flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
    },
    surveyContainer: {
      height: !mobileMode
        ? `${roomState !== 'disconnected' ? 'calc(100vh - 280px)' : 'calc(100vh - 112px)'}`
        : `calc(100vh - 50px)`,
      overflowY: 'scroll',
      overflowX: 'hidden',
      backgroundColor: theme.palette.background.paper,
    },
    videoTabContainer: {
      display: 'grid',
      height: !mobileMode ? `100%` : `calc(100vh - 50px)`,
      gridTemplateColumns: !mobileMode ? 'minmax(450px, auto) minmax(800px, 50%)' : '1fr',
      gridTemplateRows: !mobileMode ? '1fr' : '25vh auto 72px',
      // gridTemplateRows: '700px auto',
      position: 'relative',
      overflow: 'hidden',
    },
    videoControlsContainer: {
      position: 'absolute',
      gridRow: !mobileMode ? 1 : 3,
      gridColumn: !mobileMode ? 2 : 1,
      right: '50%',
      transform: 'translate(50%, 0%)',
      bottom: '50px',
      height: '72px',
      zIndex: 10,
    },
    hiddenContainer: {
      display: 'none',
      pointerEvents: 'none',
    },
    bottomVideoDisplayContainer: {
      backgroundColor: theme.palette.background.paper,
      display: 'grid',
      gridTemplateColumns: '550px 285px auto',
      overflow: 'hidden',
      maxHeight: '168px',
      borderTop: '2px solid',
      borderColor: theme.tertiaryColors.banner,
    },
    videoContainer: {
      width: '100%',
      height: '100%',
      display: 'inline-block',
    },
    controlsContainer: {
      width: '100%',
      height: '65%',
      alignSelf: 'end',
      display: 'inline-block',
      position: 'relative',
    },
  }));

  const {
    loginState,
    petition,
    walklist,
    walklistjson,
    setSurveySubject,
    petitionjson,
    setNewTextMessages,
    setNewTextMessageList,
    setRoomsOpen,
    setTotalRooms,
    setRoomList,
    loggedUsername,
    server,
    myCurrentRoom,
    setMyCurrentRoom,
    textToPush,
    setTextToPush,
    mobileMode,
    setMobileMode,
  } = useAppState();
  const roomState = useRoomState();

  const [currentlyViewedPhoneNumber, setCurrentlyViewedPhoneNumber] = useState('');

  const getLogoFromPetition = () => {
    let logoUrl = '';
    petitionjson.ScriptProperties.forEach((value: any, index: number) => {
      if (value.Value.slice(-14) === 'logo_large.png') return (logoUrl = value.Value);
    });
    return logoUrl;
  };

  React.useEffect(() => {
    let timeout: any = null;
    const resizeListener = () => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        if (window.innerWidth < 800) {
          setMobileMode(true);
        } else {
          setMobileMode(false);
        }
      }, 500);
    };

    window.addEventListener('resize', resizeListener);

    return () => {
      window.removeEventListener('resize', resizeListener);
    };
  }, []);

  const Logo = getLogoFromPetition();

  // console.log(petitionjson.ScriptProperties[1].Value) <-- Value for canvasser phone number

  // Here we would like the height of the main container to be the height of the viewport.
  // On some mobile browsers, 'height: 100vh' sets the height equal to that of the screen,
  // not the viewport. This looks bad when the mobile browsers location bar is open.
  // We will dynamically set the height with 'window.innerHeight', which means that this
  // will look good on mobile browsers even after the location bar opens or closes.
  const height = useHeight();

  const handleChange = (event: any, newValue: any, resetSubject: boolean = false) => {
    if (resetSubject === true) {
      setSurveySubject(null);
    }
    setValue(newValue);
  };

  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [automaticUpdates, setAutomaticUpdates] = useState(0);

  const getPetitionFromNumber = () => {
    for (let i = 0; i < petitionjson.ScriptProperties.length; ++i) {
      if (petitionjson.ScriptProperties[i].Name === 'TEXT_FROM_NUMBER') {
        return petitionjson.ScriptProperties[i].Value;
      }
    }
    return '+12672027058';
  };

  const petitionFromNumber = getPetitionFromNumber();

  const incrementNewTextByNumber = (
    fromNumber: string,
    toNumber: string,
    enableSound: boolean,
    message: string = ''
  ) => {
    let subjectPhoneNumberFound: boolean = false;
    let toPhoneNumberFound: boolean = false;

    let subjectPhoneNumber: string = getFilteredPhoneNumber(fromNumber);
    let canvasserPhoneNumber: string = getFilteredPhoneNumber(toNumber);

    subjectPhoneNumberFound = walklistjson?.Walklist?.some(
      (value: any) =>
        getFilteredPhoneNumber(value.Cell) === subjectPhoneNumber ||
        getFilteredPhoneNumber(value.Other.CellPhone) === subjectPhoneNumber
    );
    toPhoneNumberFound = getFilteredPhoneNumber(petitionFromNumber) === canvasserPhoneNumber;

    //console.log("ScriptProperties" +petitionjson.ScriptProperties[1].Value.slice(-10));
    //console.log("toPhoneNumberFound:" + toPhoneNumberFound);
    //console.log("subjectPhoneNumberFound:" + subjectPhoneNumberFound);

    //Verify that the subject is in the walklist and that the message is received by the appropriate canvasser
    if (subjectPhoneNumberFound && toPhoneNumberFound) {
      if (subjectPhoneNumber === LocalPhoneNumber) {
        LocalAutomaticUpdates++;
        setAutomaticUpdates(LocalAutomaticUpdates);
        if (enableSound) {
          playSound(newTextMessageSound, 2);
        }
        setTextToPush({ message, fromNumber, toNumber });
      } else {
        LocalNewMessages++;
        setNewTextMessages(LocalNewMessages);
        if (enableSound) {
          playSound(newTextMessageSound, 2);
        }

        //if no new texts exist, initialize array with phone number and 1 text message
        //we keep track of text messages for each phone number in order to remove the appropriate amount once the conversation is viewed
        if (LocalMessageList && LocalMessageList.length === 0) {
          LocalMessageList = [[subjectPhoneNumber, 1]];
          setNewTextMessageList(LocalMessageList);
          //console.log(LocalMessageList);
        } else if (LocalMessageList) {
          var pushNumber = false;
          for (let i = 0; i < LocalMessageList.length; ++i) {
            //If phone number is found, increment that phone number's unread texts by 1
            if (subjectPhoneNumber === LocalMessageList[i][0]) {
              LocalMessageList[i][1]++;
              setNewTextMessageList(LocalMessageList);
              //console.log(LocalMessageList);
              return;
            }
            //If array length is reached and phone number is not found, add phone number to list of newTextMessages and initialize number to 1 new text
            //dont want to push inside loop...
            if (i === LocalMessageList.length - 1) {
              pushNumber = true;
            }
            //setNewTextMessageList(LocalMessageList);
          }
          if (pushNumber) {
            LocalMessageList.push([subjectPhoneNumber, 1]);
            setNewTextMessageList(LocalMessageList);
          }
        }
      }
    }
  };

  const updateParticipantsFromQueueHandler = (roomName: string) => {
    for (let i = 0; i < participantUpdateQueue.length; ++i) {
      if (participantUpdateQueue[i][0] === roomName) {
        updateParticipants(participantUpdateQueue[i][0], participantUpdateQueue[i][1]);
        participantUpdateQueue = participantUpdateQueue
          .slice(0, i)
          .concat(participantUpdateQueue.slice(i + 1, participantUpdateQueue.length));
        return;
      }
    }
  };

  const addRoomToRoomList = (roomUpdate: any) => {
    LocalRoomList.push([roomUpdate.roomName, 0, roomUpdate.roomSID]);
    setRoomList(LocalRoomList);
    LocalTotalRooms++;
    setTotalRooms(LocalTotalRooms);
    playSound(newRoomCreatedSound, 4);
    updateParticipantsFromQueueHandler(roomUpdate.roomName);
  };

  const removeRoomFromRoomList = (roomUpdate: any) => {
    let roomIndex = -1;
    //here we are checking the roomName against the list of rooms to find the index of the room that is to be removed
    for (let i = 0; i < LocalRoomList.length; ++i) {
      if (LocalRoomList[i][0] === roomUpdate.roomName) {
        roomIndex = i;
      }
    }

    //Remove the room at a given index and concatenate the array values after that index with the array values prior to that
    let newRoomList = LocalRoomList.slice(0, roomIndex).concat(
      LocalRoomList.slice(roomIndex + 1, LocalRoomList.length)
    );
    setRoomList(newRoomList);
    LocalRoomList = newRoomList;
    if (LocalTotalRooms > 0) {
      LocalTotalRooms--;
    }
    //setTotalRooms(totalRooms - 1);
    setTotalRooms(LocalTotalRooms);
    if (LocalOpenRooms > 0) {
      LocalOpenRooms--;
    }
    setRoomsOpen(LocalOpenRooms);
  };

  const { room } = useVideoContext();
  const updateParticipants = (roomUpdate: any, updateAmount: number) => {
    let roomIndex = -1;

    if (roomUpdate.roomName === LocalCurrentRoom && updateAmount === -1) {
      setMyCurrentRoom('');
    }
    //here we are checking the roomName against the list of rooms to find which room to add or remove a participant from
    //doing this helps us keep track of which rooms are open and which rooms are full
    for (let i = 0; i < LocalRoomList.length; ++i) {
      if (LocalRoomList[i][0] === roomUpdate.roomName) {
        roomIndex = i;
      }
    }
    if (roomIndex !== -1) {
      //If the room hasn't already been removed from the roomlist array
      LocalRoomList[roomIndex][1] += updateAmount;
      if (LocalRoomList[roomIndex][1] === 2) {
        if (LocalOpenRooms > 0) {
          LocalOpenRooms--;
        }
        setRoomsOpen(LocalOpenRooms);
      }
      //If room has maximum participants (2) decrease the total open rooms by 1
      else if (LocalRoomList[roomIndex][1] === 1) {
        LocalOpenRooms++;
        setRoomsOpen(LocalOpenRooms); //Else If the room has only 1 participant, increase open rooms by 1 indicating the room is available to be entered by cavasser
      }
      setRoomList(LocalRoomList);
    }
  };

  React.useEffect(() => {
    LocalCurrentRoom = myCurrentRoom;
    if (myCurrentRoom === '' && currentRoomUpdates !== 0) {
      room?.disconnect?.();
    }
    currentRoomUpdates++;
  }, [myCurrentRoom]);

  const queueParticipantUpdate = (update: any, updateAmount: number) => {
    participantUpdateQueue = [...participantUpdateQueue, [update.roomName, updateAmount]];
  };

  const checkForRoomExistence = (roomName: string) => {
    for (let i = 0; i < LocalRoomList.length; ++i) {
      if (LocalRoomList[i][0] === roomName) {
        return true;
      }
    }
    return false;
  };

  const updateRoomHandler = (update: any) => {
    switch (update.roomEvent) {
      case 'room-created':
        return addRoomToRoomList(update);
      case 'room-ended':
        return removeRoomFromRoomList(update);
      case 'participant-connected':
        if (checkForRoomExistence(update.roomName)) {
          return updateParticipants(update, 1);
        } else {
          return queueParticipantUpdate(update, 1);
        }
      case 'participant-disconnected':
        if (checkForRoomExistence(update.roomName)) {
          return updateParticipants(update, -1);
        } else {
          //No need to update any values in this case as room has already been removed
          return;
        }
      default:
        return;
    }
  };

  const removeNewMessagesHandler = (phoneNumber: string) => {
    let filteredPhoneNumber = getFilteredPhoneNumber(phoneNumber);

    setCurrentlyViewedPhoneNumber(filteredPhoneNumber);
    LocalPhoneNumber = filteredPhoneNumber;
    if (LocalMessageList.length !== 0) {
      let phoneNumberIndex = -1;
      for (let i = 0; i < LocalMessageList.length; ++i) {
        if (LocalMessageList[i][0] === filteredPhoneNumber) {
          phoneNumberIndex = i;
        }
      }
      if (phoneNumberIndex !== -1) {
        LocalNewMessages -= LocalMessageList[phoneNumberIndex][1];
        setNewTextMessages(LocalNewMessages);

        let newMessageList = LocalMessageList.slice(0, phoneNumberIndex).concat(
          LocalMessageList.slice(phoneNumberIndex + 1, LocalMessageList.length)
        );
        LocalMessageList = newMessageList;
        setNewTextMessageList(newMessageList);
      }
    }
  };

  const updateCurrentPhoneNumber = () => {
    setCurrentlyViewedPhoneNumber('');
    LocalPhoneNumber = '';
  };

  //Updates notification bar variables when the roomlist is refreshed
  const updateLocalVariablesOnRefresh = (totalRooms: number, openRooms: number) => {
    LocalTotalRooms = totalRooms;
    setTotalRooms(totalRooms);

    LocalOpenRooms = openRooms;
    setRoomsOpen(openRooms);
  };

  const validateRoomOwnershipHandler = (roomName: string) => {
    let canvasserId = petitionjson.Id;
    let roomInfoArray = roomName.split('_');
    if (canvasserId === Number(roomInfoArray[1])) return true;
    else return false;
  };

  React.useEffect(() => {
    var connection = $.hubConnection('https://' + server + '/api/signalr');
    //var connection = $.hubConnection('http://localhost:52144/api/signalr');
    connection.qs = { customg: sessionStorage.getItem('customg') };
    var contosoChatHubProxy = connection.createHubProxy('TextChatHub');
    contosoChatHubProxy.on('addNewTextMessageToPage', function(name, message) {
      let textDetails = JSON.parse(message);
      //console.log(message, textDetails);
      if (textDetails && textDetails.fromnumber) {
        incrementNewTextByNumber(textDetails.fromnumber, textDetails.tonumber, true, textDetails.message);
      } else if (textDetails && textDetails.roomEvent && validateRoomOwnershipHandler(textDetails.roomName)) {
        updateRoomHandler(textDetails);
      }
    });

    connection.error(function(error) {
      console.log('signal error: ' + error.message);
    });

    connection.disconnected(function() {
      console.log('signal disconnected');

      setTimeout(function() {
        // attempt connection, and handle errors
        connection
          .start({ jsonp: true })
          .done(function() {
            console.log('Now connected, connection ID=' + connection.id);
          })
          .fail(function() {
            console.log('Could not connect');
          });
      }, 120000); // Restart connection after 10 seconds.
    });

    //connection.reconnected(function () {
    //  //console.log('signal reconnected');
    //});

    //connection.reconnecting(function () {
    //  //console.log('signal reconnecting');
    //});

    //connection.connectionSlow(function () {
    //  //console.log('signal connectionSlow');
    //});

    // attempt connection, and handle errors
    connection
      .start({ jsonp: true })
      .done(function() {
        console.log('Now connected, connection ID=' + connection.id);
      })
      .fail(function() {
        console.log('Could not connect');
      });
  }, []);

  const updateNotificationBarHandler = (videoRoomList: Array<any>) => {
    let totalRooms = videoRoomList.length;
    let availableRooms: any = [];
    let currentVideoRooms: any = [];

    videoRoomList.forEach((value, index) => {
      currentVideoRooms.push([value.roomName, 1]);
      if (value[1] === 1) availableRooms.push(value);
    });

    let openRooms = availableRooms.length;

    updateLocalVariablesOnRefresh(totalRooms, openRooms);
  };

  const getAvailableRooms = async () => {
    const headers = new window.Headers();
    headers.set('Authorization', 'Bearer ' + sessionStorage.getItem('customg'));

    let newRoomList: any = [];
    let newLocalRoomList: any = [];

    await fetch('https://' + server + '/api/v2/twilio/getVideoRoomList', {
      method: 'GET',
      headers: headers,
    })
      .then(res => res.text())
      .then(res => (newRoomList = JSON.parse(res)))
      .catch(err => {
        alert('Unable to retrieve video room list');
        newRoomList = null;
      });

    newRoomList?.VideoRoomList?.forEach((value: any, index: number) => {
      //Check to make sure incoming data is valid before pushing it to roomlist array. If data is not valid, do not include it in the roomlist
      if (value.roomName && value.roomParticipants && value.roomSID) {
        //This if statement is to allow test rooms to be created via the inputs on the video page and can be removed once video testing is no longer needed
        if (value.roomName.indexOf('_') !== -1) {
          let splitStringArray = value.roomName.split('_');
          //Checks the roomname by petitionId to ensure a given room is available to this canvasser and that the room name is valid
          if (
            petitionjson.Id === Number(splitStringArray[1]) &&
            splitStringArray.length === 5 &&
            !isNaN(Number(splitStringArray[1]))
          ) {
            newLocalRoomList.push([value.roomName, value.roomParticipants.length, value.roomSID]);
          }
        }
      }
    });
    if (newRoomList) {
      setRoomList(newLocalRoomList);
      LocalRoomList = newLocalRoomList;
      return newLocalRoomList;
    } else {
      return null;
    }
  };

  const refreshButtonHandler = async () => {
    let roomListByPetitionId: any = await getAvailableRooms();
    if (roomListByPetitionId) {
      updateNotificationBarHandler(roomListByPetitionId);
    }
  };

  const [gotInitialRooms, setGotInitialRooms] = useState(false);

  React.useEffect(() => {
    if (!gotInitialRooms) {
      refreshButtonHandler();
      setGotInitialRooms(true);
    }
  }, [gotInitialRooms]);

  const getAvailableTexts = async () => {
    const headers = new window.Headers();
    headers.set('Authorization', 'Bearer ' + sessionStorage.getItem('customg'));
    let newTextList: any = [];
    let petitionid = petitionjson.Id;
    let from = petitionFromNumber;

    const params = new window.URLSearchParams({ from, petitionid });
    //temporary
    await fetch('https://' + server + `/api/v2/twilio/getUnreadTexts?${params}`, {
      method: 'GET',
      headers: headers,
    })
      .then(res => res.text())
      .then(res => (newTextList = JSON.parse(res)))
      .catch(err => {
        console.log('Unable to retrieve unread texts');
      });

    let newLocalTextList: any = [];
    newTextList?.UnreadTextMessages?.forEach((value: any, index: number) => {
      //console.log(value);
      //Check to make sure incoming data is valid before pushing it to roomlist array. If data is not valid, do not include it in the textlist
      if (value.From && value.To && value.Id) {
        incrementNewTextByNumber(value.From, value.To, false);
      }
    });

    return newLocalTextList;
  };

  const handlerUnreadTexts = async () => {
    await getAvailableTexts();
  };

  const [gotInitialTexts, setGotInitialTexts] = useState(false);

  React.useEffect(() => {
    if (!gotInitialTexts) {
      handlerUnreadTexts();
      setGotInitialTexts(true);
    }
  }, [gotInitialTexts]);

  const screenShareParticipant = useScreenShareParticipant();

  const [navigationDrawerDisplay, setNavigationDrawerDisplay] = useState(false);

  const openNavigationDrawerHandler = () => {
    setNavigationDrawerDisplay(true);
  };

  const closeNavigationDrawerHandler = () => {
    setNavigationDrawerDisplay(false);
  };

  return (
    <Container
      style={{
        display: 'grid',
        gridTemplateRows: !mobileMode
          ? `${
              roomState !== 'disconnected' && value !== 2
                ? 'auto auto calc(100vh - 280px) 168px'
                : 'auto auto calc(100vh - 112px)'
            }`
          : 'auto',
      }}
    >
      {!mobileMode && <MenuBarStart Logo={Logo} />}

      {!mobileMode && (
        <div style={{ zIndex: 1 }}>
          <AppBar className={classes.root} position="static" elevation={2}>
            <Tabs variant="fullWidth" textColor="primary" value={value} onChange={handleChange} aria-label="tabs">
              <Tab id="simple-tab-0" aria-controls="simple-tabpanel-0" label="Walklist" />
              <Tab id="simple-tab-1" aria-controls="simple-tabpanel-1" label="Survey" />
              <Tab id="simple-tab-2" aria-controls="simple-tabpanel-2" label="Video" />
            </Tabs>
          </AppBar>
        </div>
      )}

      {mobileMode && <MobileMenuBar openNavigationDrawerHandler={openNavigationDrawerHandler} currentPage={value} />}

      <AnimatePresence exitBeforeEnter>
        {mobileMode && navigationDrawerDisplay && (
          <MobileNavigationDrawer
            closeNavigationDrawerHandler={closeNavigationDrawerHandler}
            Logo={Logo}
            currentPage={value}
            changePage={handleChange}
          />
        )}
      </AnimatePresence>

      <Main>
        <TabPanel
          value={value}
          index={0}
          className={null}
          hiddenContainer={classes.hiddenContainer}
          style={{ height: '100%' }}
        >
          <List
            changePage={(resetSubject: boolean = false) => handleChange(0, 1, resetSubject)}
            removeNewMessagesHandler={removeNewMessagesHandler}
            playSound={playSound}
            automaticUpdates={automaticUpdates}
            updateCurrentPhoneNumber={updateCurrentPhoneNumber}
          ></List>
        </TabPanel>

        <TabPanel value={value} index={1} className={null} hiddenContainer={classes.hiddenContainer}>
          <div className={classes.surveyContainer}>
            <Survey></Survey>
          </div>
        </TabPanel>

        <TabPanel
          value={value}
          index={2}
          className={classes.videoTabContainer}
          hiddenContainer={classes.hiddenContainer}
        >
          {(!mobileMode || myCurrentRoom === '') && (
            <VideoRoomList
              getAvailableRooms={refreshButtonHandler}
              changePage={(resetSubject: boolean = false) => handleChange(0, 1, resetSubject)}
              playSound={playSound}
            />
          )}
          <VideoViewer />
          <div className={classes.videoControlsContainer}>
            <Controls />
          </div>
        </TabPanel>
      </Main>

      {roomState !== 'disconnected' && value !== 2 && !mobileMode && (
        <div className={classes.bottomVideoDisplayContainer}>
          <div className={classes.videoContainer}>
            <BottomVideoParticipantStrip />
          </div>
          <div>{screenShareParticipant !== undefined && <MainParticipant bottomVideo />}</div>
          <div className={classes.controlsContainer}>
            <Controls />
          </div>
        </div>
      )}

      <ReconnectingNotification />
    </Container>
  );
}
