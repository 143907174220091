import React from 'react';
import JoinPageParticipantStrip from '../JoinPageParticipantStrip/JoinPageParticipantStrip';
import { styled } from '@material-ui/core/styles';

const Container = styled('div')(({ theme }) => ({
  position: 'relative',
  height: '100%',
}));

export default function Room() {
  return (
    <Container>
      <JoinPageParticipantStrip />
    </Container>
  );
}
