import React from 'react';
import ParticipantStrip from '../ParticipantStrip/ParticipantStrip';
import { styled } from '@material-ui/core/styles';
import MainParticipant from '../MainParticipant/MainParticipant';
import useMainSpeaker from '../../hooks/useMainSpeaker/useMainSpeaker';
import useScreenShareParticipant from '../../hooks/useScreenShareParticipant/useScreenShareParticipant';
import { useAppState } from '../../state';

export default function Room() {
  const { mobileMode } = useAppState();

  const Container = styled('div')(({ theme }) => ({
    position: 'relative',
    height: '100%',
    display: 'grid',
    gridTemplateRows: !mobileMode ? '300px auto' : '60vh auto',
  }));
  const mainSpeaker = useMainSpeaker();
  const screenShareParticipant = useScreenShareParticipant();

  return (
    <Container>
      <ParticipantStrip />
      {screenShareParticipant !== undefined && <MainParticipant />}
    </Container>
  );
}
