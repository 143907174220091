import React, { useState, useRef, useEffect } from 'react';
import { useAppState } from '../../state';
import { ThemeProvider, withStyles, createMuiTheme, makeStyles, styled } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import TextField from '@material-ui/core/TextField';
import Fab from '@material-ui/core/Fab';
import SendIcon from '@material-ui/icons/Send';
import RefreshIcon from '@material-ui/icons/Refresh';
import WalklistCandidate from './WalklistCandidate';
import WalklistHeader from './WalklistHeader';
import FilterDialogue from './FilterDialogue';
import KnockStatusDialogue from './KnockStatusDialogue';
import { getFormattedDate } from './Survey';
import useRoomState from '../../hooks/useRoomState/useRoomState';
import getFilteredPhoneNumber from '../../utils/getFilteredPhoneNumber/getFilteredPhoneNumber';

/* For table */
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Collapse from '@material-ui/core/Collapse';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
/* For table */

//import axios
//import axios from 'axios';

// For accordion
import clsx from 'clsx';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionActions from '@material-ui/core/AccordionActions';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Chip from '@material-ui/core/Chip';
import Divider from '@material-ui/core/Divider';

import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import PhoneIcon from '@material-ui/icons/Phone';
import TextIcon from '@material-ui/icons/Message';
import VideoIcon from '@material-ui/icons/Videocam';
import InfiniteScroll from 'react-infinite-scroll-component';
import Paper from '@material-ui/core/Paper';

import BounceLoader from 'react-spinners/BounceLoader';

// @ts-ignore
import LoadingOverlay from 'react-loading-overlay';

//for tab panel
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PropTypes from 'prop-types';

import { green, purple, blue, lightGreen } from '@material-ui/core/colors';
import { any } from 'prop-types';
import ChatPortal from './ChatPortal';

import './List.css';
import { AppBar, withTheme } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { AnimatePresence, motion } from 'framer-motion';

const refreshSound = require('../../utils/AudioFiles/refreshSound.wav');

const Main = styled('main')({
  //overflow: 'hidden',
});

function TabPanel(props: { [x: string]: any; children: any; value: any; index: any }) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={2}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    //flexGrow: 1,

    backgroundColor: theme.palette.background.paper,
  },
}));

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const theme = createMuiTheme({
  overrides: {
    MuiTableCell: {
      root: {
        padding: '4px 12px',
      },
      body: {
        color: 'white', //dark theme edit
      },
    },
  },
  tertiaryColors: {
    default: '',
    paper: '',
    banner: '',
  },
});

export default function InteractiveList(props: any) {
  const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1,
      maxWidth: 500,
    },
    demo: {
      backgroundColor: theme.palette.background.paper,
    },
    title: {
      margin: theme.spacing(4, 0, 2),
    },

    table: {
      minWidth: 650,
    },
    headBG: {
      backgroundColor: '#e0e0e0',
    },
    borderRight500: {
      borderRight: '1px solid #e0e0e0',
    },

    heading: {
      fontSize: theme.typography.pxToRem(15),
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
    },
    icon: {
      verticalAlign: 'bottom',
      height: 20,
      width: 20,
    },
    details: {
      alignItems: 'center',
      overflow: 'hidden',
    },
    column: {
      flexBasis: '33.33%',
    },
    helper: {
      borderLeft: `2px solid ${theme.palette.divider}`,
      padding: theme.spacing(1, 2),
    },
    link: {
      color: theme.palette.primary.main,
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    loading: {
      height: '100%',
      width: '100%',
      display: 'flex',
      position: 'absolute',
    },
    columnHeaderContainer: {
      height: '45px',
      width: '100%',
      backgroundColor: theme.tertiaryColors.banner,
      display: 'grid',
      alignItems: 'center',
      justifyItems: 'center',
      borderBottom: '1px solid #606060',
      position: 'relative',
    },
    columnBackButtonContainer: {
      position: 'absolute',
      top: '50%',
      left: '10px',
      transform: 'translate(0%, -50%)',
      backgroundColor: '#606060',
      '&:hover': {
        backgroundColor: '#404040',
      },
      cursor: 'pointer',
      height: '70%',
      width: '40px',
      display: 'grid',
      alignItems: 'center',
      justifyItems: 'center',
      borderRadius: '8px',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.6)',
      transitionDuration: '150ms',
    },
    columnBackButton: {
      fontSize: '20px',
      color: 'white',
    },
    columnTwoHeaderText: {
      margin: '0px',
      fontSize: '20px',
      color: theme.palette.text.primary,
    },
    accordianText: {
      color: theme.palette.text.primary,
    },
    knockStatusButton: {
      backgroundColor: '#F22F46',
      color: 'white',
      '&:hover': {
        backgroundColor: '#d11b31',
      },
    },
    subjectOptionsButton: {
      position: 'relative',
      right: '-10px',
      transform: 'scale(1.3)',
    },
    additionalInfoButton: {
      marginTop: '16px',
      position: 'relative',
      left: '100%',
      transform: 'translate(-100%, 0)',
    },
    chatPanel: {
      '& .MuiBox-root': {
        padding: '8px',
      },
    },
    newTextMessageSubjectIndicator: {
      position: 'absolute',
      bottom: '10px',
      right: '5px',
      height: '15px',
      width: '15px',
      borderRadius: '15px',
      backgroundColor: '#4caf50',
      border: `2px solid ${theme.palette.background.default}`,
    },
    newTextMessageSubjectIndicatorMobile: {
      position: 'absolute',
      bottom: '10px',
      right: '11px',
      height: '12px',
      width: '12px',
      borderRadius: '12px',
      backgroundColor: '#4caf50',
      border: `2px solid ${theme.palette.background.default}`,
    },
  }));

  const LocationPerPage: number = 1099;
  const classes = useStyles();
  const {
    walklist,
    walklistjson,
    walklistjsonLocations,
    setWalklist,
    petition,
    petitionjson,
    server,
    loggedImei,
    setSurveySubject,
    newTextMessageList,
    darkMode,
    mobileMode,
  } = useAppState();
  const [dense, setDense] = useState(false);

  const [isShowChat, setIsShowChat] = useState(false);

  /* for sms */
  const [curPage, setCurPage] = useState(1);

  const [msgText, setMsgText] = useState<any[]>([]);
  const [walkName, setWalkName] = useState('');
  const [chatSubject, setChatSubject] = useState('');
  const [phone, setPhone] = useState('');

  /* for refresh */
  const [isRefresh, setIsRefresh] = useState(-1);

  /* for tab panel */
  const [phonelist, setphonelist] = useState<any[]>([]);
  const [phoneCount, setPhoneCount] = useState(0);
  const [value, setValue] = useState(0);
  const [loadingActive, setLoadingActive] = useState(false);
  const [secondary, setSecondary] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const [filteredWalklist, setFilteredWalklist] = useState<any[]>([]);
  const [filteredMsgList, setFilteredMsgList] = useState<any[]>([]);

  const loaderTextInitial = () => {
    if (walklistjson && walklistjson.Walklist && walklistjson.Walklist.length > 0) {
      return 'End of Walklist';
    }
    return 'Empty Walklist';
  };

  const [loaderText, setLoaderText] = useState<string>(loaderTextInitial());

  const [historyPushed, setHistoryPushed] = useState<boolean>(false);

  useEffect(() => {
    if (!historyPushed) {
      window.history.pushState(null, document.title, window.location.href);
      window.addEventListener('popstate', event => {
        window.history.pushState(null, document.title, window.location.href);
      });
      setHistoryPushed(true);
    }
  });

  //const checkLocation = (location: string, tmplocations: object[]) => {
  //  var status: Boolean = false;
  //  tmplocations.map((w: any, i: any) => {
  //    if (w.location == location) status = true;
  //  });
  //  return status;
  //};

  //const insertLocation = () => {
  //  let tmpLocation: object[] = [];

  //  walklistjson?.Walklist?.map((w: any, i: any) => {
  //    let location: string = w.Address;
  //    let Cell: string = w.Cell;
  //    if (!checkLocation(location, tmpLocation)) {
  //      tmpLocation.push({ location, Cell });
  //    }
  //  });
  //  return tmpLocation;
  //};

  //set showable
  const setShow = () => {
    setIsShowChat(true);
  };
  //handle click refresh button
  const handleRefreshClick = (event: any) => {
    event.preventDefault();
    var refreshTmp = isRefresh;
    refreshTmp++;
    props.playSound(refreshSound, 3);
    setIsRefresh(refreshTmp);
  };
  //init the full locations
  //const [locations, setLocations] = useState(insertLocation());
  //const [locations, setLocations] = useState(walklistjsonLocations);

  // set the filtered location list
  const [filteredLocations, setFilteredLocations] = useState<any>(
    walklistjsonLocations.slice(0, curPage * LocationPerPage)
  );

  //handler to click the locations
  const handleLocationClick = (event: any, location: string, index: any) => {
    setWalklistPageDisplayed(1);
    setSelectedUserIndex(0);
    setSubjectSelected(-1);
    props.updateCurrentPhoneNumber();
    let locationIndex: any = getIndexFromLocation(location);
    setSelectedIndex(locationIndex);
    if (isShowChat) {
      resetChatHandler();
    }
    let tmpWalklist: any[] = [];
    let tempExpandedAccordion: boolean[] = [];
    walklistjson?.Walklist.map((w: any, i: any) => {
      if (w.Address == location) {
        tmpWalklist.push(w);
        tempExpandedAccordion.push(false);
      }
    });
    setExpandedAccordion(tempExpandedAccordion);
    setFilteredWalklist([...tmpWalklist]);
  };

  const handleSubjectSurveyClick = (event: any, w: any) => {
    event.stopPropagation();
    setSurveySubject(w);
    props.changePage(false);
  };

  const handleNewSurveyClick = (event: any, w: any) => {
    props.changePage(true);
  };

  const resetChatHandler = () => {
    setIsShowChat(false);
    setPhoneCount(0);
    setPhone('');
  };

  //handle tabpanel change
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    if (value == newValue) return;
    setLoadingActive(true);
    if (newValue == 0) setPhone(phonelist[0]);
    else setPhone(phonelist[1]);
    setValue(newValue);
  };

  //handler to select new walk
  const handleNewMsg = (event: any, data: any) => {
    event.preventDefault();
    if (
      (data.Cell == '' || data.Cell == null || data.Cell.length < 10) &&
      (data.Other == null ||
        data.Other.CellPhone == null ||
        data.Other.CellPhone == '' ||
        data.Other.CellPhone.length < 10)
    ) {
      alert('There is no Phone Number!');
      return;
    }
    setWalklistPageDisplayed(2);
    setChatSubject(data);
    //setWalkName(data.FirstName + ' ' + data.LastName);
    var phoneTmp = [];
    var i = 0;
    setPhoneCount(0);
    if (data.Cell != null && data.Cell != '' && data.Cell.length > 9) {
      phoneTmp.push(data.Cell);
      i++;
      setPhoneCount(i);
    }
    if (
      data.Other != null &&
      data.Other.CellPhone != null &&
      data.Other.CellPhone != '' &&
      data.Other.CellPhone.length > 9 &&
      !(i == 1 && data.Cell == data.Other.CellPhone)
    ) {
      phoneTmp.push(data.Other.CellPhone);
      i++;
      setPhoneCount(i);
    }
    setphonelist(phoneTmp);
    if (i > 1) {
      if (phoneTmp[0] != phone) {
        setLoadingActive(true);
        setPhone(phoneTmp[0]);
        setValue(0);
      }
    } else {
      if (phoneTmp[0] != phone) {
        setLoadingActive(true);
        setPhone(phoneTmp[0]);
      }
    }
  };

  //handler to show more locations
  const fetchMoreData = () => {
    // a fake async api call like which sends
    // 20 more records in 1.5 secs
    if (curPage * LocationPerPage >= walklistjsonLocations.length) {
      setHasMore(false);
      return;
    }

    setCurPage(curPage + 1);
    setFilteredLocations(walklistjsonLocations.slice(0, (curPage + 1) * LocationPerPage));
  };

  const [selectedIndex, setSelectedIndex] = useState(-1);

  //ColorButton
  const ColorButton = withStyles(theme => ({
    root: {
      color: 'white',
      backgroundColor: green[500],
      '&:hover': {
        backgroundColor: green[700],
      },
    },
  }))(Button);

  function getCanvassStatus(status: any) {
    let statusName = '';

    petitionjson.KnockStatuses.forEach((value: any) => {
      if (value.LocalId === Number(status)) {
        return (statusName = value.Name);
      }
    });

    return statusName;
  }

  function getStatusArray(location: any) {
    var i = 0;

    var canvassed: Array<string> = [];

    Object.keys(walklistjson?.Walklist).forEach(function(key) {
      if (walklistjson?.Walklist[key].Address === location) {
        i++;
        canvassed.push(walklistjson?.Walklist[key].Status);
      }
      //arr.push(json[key]);
    });

    return canvassed;
  }
  //console.log(petitionjson);

  const updateFilteredList = (filters: Array<number>, newTextMessagesFilter: boolean, updateFilters: boolean) => {
    if (!updateFilters) return;
    if (filters.length === 0 && !newTextMessagesFilter && updateFilters) {
      //If no filters selected, revert filteredLocations back to initial state
      setFilteredLocations(walklistjsonLocations.slice(0, curPage * LocationPerPage));
      //setLoaderText('Loading...');
      return;
    }
    let updatedList = walklistjsonLocations.filter((value: any, index: number) => {
      let status = getStatusArray(value.location);

      if (newTextMessagesFilter) {
        let tmpNumbers: any[] = [];
        var found = false;

        if (value.location && walklistjson && walklistjson.Walklist && newTextMessageList) {
          //looking at both options for cell
          walklistjson.Walklist.map((w: any, i: any) => {
            if (w.Address === value.location) {
              if (w.Cell) {
                tmpNumbers.push(getFilteredPhoneNumber(w.Cell));
              }
              if (w.Other && w.Other.CellPhone) {
                tmpNumbers.push(getFilteredPhoneNumber(w.Other.CellPhone));
              }
            }
          });

          if (tmpNumbers && tmpNumbers.length > 0) {
            found = newTextMessageList?.some((newTextMessageListValue: any, index: number) =>
              tmpNumbers.some((tmpNumbersValue: any, index: number) => newTextMessageListValue[0] === tmpNumbersValue)
            );

            if (found) {
              return true;
            }
          }
        }

        //old method for reference
        //let foundNumber = false;
        //newTextMessageList.forEach((messageListValue: any, messageListIndex: number) => {
        //  if (
        //    messageListValue[0] === getFilteredPhoneNumber(walklistjson.Walklist[index].Cell) ||
        //    messageListValue[0] === getFilteredPhoneNumber(walklistjson.Walklist[index].Other.CellPhone)
        //  ) {
        //    foundNumber = true;
        //  }
        //});
        //if (foundNumber) return true;
      }
      for (let i = 0; i < filters.length; ++i) {
        //Checks each filter in the filter array
        for (let j = 0; j < status.length; ++j) {
          //Checks each person at a given location
          if (Number(status[j]) === filters[i]) return true; //If person's status matches a filter, add them to the new array
        }
      }
      return false;
    });
    //if (updatedList.length === 0) setLoaderText('No Records Found');
    //else setLoaderText('Loading...');
    setFilteredLocations(updatedList);
  };

  const [filtersOpen, setFiltersOpen] = useState(false);
  const [knockStatusOpen, setKnockStatusOpen] = useState(false);
  const [currentSubject, setCurrentSubject] = useState({});
  const roomState = useRoomState();

  const handleFilterDisplay = () => {
    setFiltersOpen(true);
  };

  const handleCloseFilters = (filters: Array<number>, newTextMessagesFilter: boolean, updateFilters: boolean) => {
    setFiltersOpen(false);
    updateFilteredList(filters, newTextMessagesFilter, updateFilters);
  };

  const handleKnockStatusDisplay = (event: any, userData: any) => {
    event.stopPropagation();
    setKnockStatusOpen(true);
    setCurrentSubject(userData);
  };

  const { loggedUsername } = useAppState();

  const updateUserStatusHandler = async (currentSubject: any, status: number) => {
    let headers = new window.Headers();
    headers.set('Authorization', 'Bearer ' + sessionStorage.getItem('customg'));
    headers.set('imei', loggedImei); //'a5c2ab7e5305232');
    headers.set('Content-Type', 'application/json');

    //let newData = {
    //  Knocks: [
    //    {
    //      Answers: [
    //        {
    //          Answer: 5556,
    //          Question: 94747,
    //        },
    //      ],
    //      BatteryLevel: 0,
    //      BlueTooth: false,
    //      Bondeddevices: 0,
    //      Brightness: 0.0,
    //      Other: [
    //        {
    //          Index: selectedIndex,
    //          Name: currentSubject.FirstName,
    //          Value: 5556,
    //        },
    //      ],
    //      Runningappscount: 0,
    //      Signalstrength: 0,
    //      Wifi: false,
    //      Hid: currentSubject.Hid,
    //      KnockStatus: status,
    //      Latitude: 40.1,
    //      Longitude: -74.8,
    //      Time: getFormattedDate(new Date()),
    //      Type: 'GPS',
    //      Uncertainty: 15,
    //    },
    //  ],
    //};

    let newData = {
      Knocks: [
        {
          BatteryLevel: 0,
          BlueTooth: false,
          Bondeddevices: 0,
          Brightness: 0.0,
          Runningappscount: 0,
          Signalstrength: 0,
          Wifi: false,
          Hid: currentSubject.Hid,
          KnockStatus: status,
          Latitude: 40.1,
          Longitude: -74.8,
          Time: getFormattedDate(new Date()),
          Type: 'GPS',
          Uncertainty: 15,
        },
      ],
    };
    //await fetch(`http://localhost:63446/api/events/knock`, {
    await fetch(`https://${server}/api/v2/events/knock`, {
      method: 'PUT',
      headers: headers,
      body: JSON.stringify(newData),
    })
      //please put the catch at the end.  The subject's status was set even after failed api requests.
      .then(res => {
        if (res.status === 201 || res.status === 200) {
          updateLocalSubjectStatusFilter(currentSubject.Hid, status);
        } else {
          console.log(`Failed to update subject status. Received status ${res.status}`);
          alert(`Failed to update subject status`);
        }
      })
      .catch((err: string) => {
        console.log(`Failed to update subject status. Error: ${err}`);
        alert(`Failed to update subject status`);
      });
  };

  const updateLocalSubjectStatus = (status: number) => {
    let currentSubjectList = walklistjson;
    currentSubjectList.Walklist[selectedIndex].Status = `${status}`;
    setWalklist(currentSubjectList);
    setTimeout(() => handleLocationClick(null, currentSubjectList.Walklist[selectedIndex].Address, selectedIndex), 1);
  };

  //was previously updating wrong subject, we need some error checking here since this part could fail, but the api could succeed.
  const updateLocalSubjectStatusFilter = (id: any, status: number) => {
    let currentSubjectList = walklistjson;
    currentSubjectList.Walklist.filter(function(data: any) {
      return data.Hid == id;
    })[0].Status = `${status}`;
    setWalklist(currentSubjectList);
    setTimeout(() => handleLocationClick(null, filteredLocations[selectedIndex].location, selectedIndex), 1);
  };

  const handleCloseKnockStatus = (status: number, updateStatus: boolean) => {
    setKnockStatusOpen(false);
    if (updateStatus) updateUserStatusHandler(currentSubject, status);
  };

  const getIndexFromLocation = (location: string) => {
    for (let i = 0; i < filteredLocations.length; ++i) {
      if (filteredLocations[i].location === location) return i;
    }
  };

  const [knockStatuses, setKnockStatus] = useState(petitionjson.KnockStatuses);

  //can add these in the future below
  //<ListItemAvatar>
  //  <Avatar>{/*<FolderIcon />*/}</Avatar>
  //</ListItemAvatar>
  //<IconButton edge="end" aria-label="delete" onClick={event => event.stopPropagation()}>
  //  <VideoIcon />
  //</IconButton>

  const getDoneStatusArray = () => {
    let statusArray: any = [];
    petitionjson.KnockStatuses.forEach(
      (value: any) => value.CategoryName === 'Done' && statusArray.push(value.LocalId)
    );
    return statusArray.length !== 0 ? statusArray : [14];
  };

  const doneStatusArray = getDoneStatusArray();

  const [expandedAccordion, setExpandedAccordion] = useState([false]);
  const [subjectSelected, setSubjectSelected] = useState(-1);

  const expandAccordianHandler = (index: number) => {
    let tempExpandedAccordion = expandedAccordion;
    tempExpandedAccordion[index] = !tempExpandedAccordion[index];
    setExpandedAccordion([...tempExpandedAccordion]);
  };

  const getOtherOptionsHandler = (subjectData: any) => {
    let data = [];
    if (subjectData?.Other) {
      for (const [key, value] of Object.entries(subjectData.Other)) {
        data.push({ cellName: key, cellData: value });
      }
    }
    return data;
  };

  const getKnockStatuses = () => {
    return knockStatuses.filter((value: any) => value.CategoryName !== 'Done');
  };

  const checkForMessagesHandler = (subjectValue: any) => {
    let hasTextMessages = newTextMessageList.some((value: any) => {
      if (subjectValue.Cell) {
        if (value[0] === getFilteredPhoneNumber(subjectValue.Cell)) return true;
      } else if (subjectValue.Phone) {
        if (value[0] === getFilteredPhoneNumber(subjectValue.Phone)) return true;
      } else if (subjectValue.Other.CellPhone) {
        if (value[0] === getFilteredPhoneNumber(subjectValue.Other.CellPhone)) return true;
      } else if (subjectValue.Other.Phone) {
        if (value[0] === getFilteredPhoneNumber(subjectValue.Other.Phone)) return true;
      } else {
        return false;
      }
    });
    if (hasTextMessages) {
      return true;
    } else {
      return false;
    }
  };

  const [walklistPageDisplayed, setWalklistPageDisplayed] = useState(0);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [selectedUserIndex, setSelectedUserIndex] = useState(0);
  const [walklistScrollPosition, setWalklistScrollPosition] = useState(0);

  const handleClick = (event: React.MouseEvent<HTMLElement>, userIndex: number) => {
    //setAnchorEl establishes the UI element that the menu will anchor to and expand from
    event.stopPropagation();
    setSelectedUserIndex(userIndex);
    setAnchorEl(event.currentTarget);
  };

  const ITEM_HEIGHT = 48;

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleWalklistScroll = () => {
    if (mobileMode) {
      let walklistContainer = document.getElementById('scrollableDiv');
      if (walklistContainer) {
        setWalklistScrollPosition(walklistContainer.scrollTop);
      }
    }
  };

  useEffect(() => {
    let walklistContainer = document.getElementById('scrollableDiv');
    if (walklistPageDisplayed === 0 && mobileMode) {
      if (walklistContainer) {
        walklistContainer.scrollTop = walklistScrollPosition;
      }
    }
  }, [walklistPageDisplayed]);

  // "{"firstgift":"6/23/2016 12:00:00 AM","lastgiftdt":"6/23/2016 12:00:00 AM","lastamount":"50","lastoffice":"CO-DENV-FC","larggiftdt":"6/23/2016 12:00:00 AM","largeamt":"50","totalgifts":"1"}"

  const drawerTransition = mobileMode
    ? {
        in: {
          x: '0%',
          opacity: 1,
        },
        out: {
          x: '-20%',
          opacity: 0,
        },
        initial: {
          x: '-20%',
          opacity: 0,
        },
      }
    : {
        in: {},
        out: {},
        initial: {},
      };

  return (
    <Box style={{ height: '100%' }}>
      <FilterDialogue open={filtersOpen} onClose={handleCloseFilters} knockStatuses={knockStatuses} />
      <KnockStatusDialogue
        open={knockStatusOpen}
        onClose={handleCloseKnockStatus}
        subjectInfo={currentSubject}
        knockStatuses={getKnockStatuses()}
      />
      <Grid
        container
        direction="row"
        style={{
          display: 'grid',
          gridTemplateColumns: !mobileMode ? 'minMax(360px, auto) minMax(550px, 33%) 33%' : '1fr',
          height: '100%',
        }}
      >
        <AnimatePresence exitBeforeEnter>
          {(!mobileMode || walklistPageDisplayed === 0) && (
            <Grid item xs={12} md={12}>
              <WalklistHeader
                key="joaisdgf0dvwe980f"
                onClick={handleFilterDisplay}
                newSurvey={handleNewSurveyClick}
                playSound={props.playSound}
              />
              <motion.div
                key="sdfh908wje0rfj"
                initial={drawerTransition.initial}
                animate={drawerTransition.in}
                exit={drawerTransition.out}
                transition={{ duration: 0.3 }}
                className="scrollbar"
                id="scrollableDiv"
                style={
                  !mobileMode
                    ? roomState === 'disconnected'
                      ? { height: 'calc(100vh - 157px)' }
                      : { height: 'calc(100vh - 325px)' }
                    : { height: 'calc(100vh - 96px)' }
                }
              >
                {' '}
                {/* 168px bottom video height */}
                <div className="force-overflow">
                  <InfiniteScroll
                    dataLength={filteredLocations.length}
                    next={fetchMoreData}
                    hasMore={hasMore}
                    loader={<h4>{loaderText}</h4>}
                    scrollableTarget="scrollableDiv"
                    endMessage={<h4>End of Walklist</h4>}
                    onScroll={handleWalklistScroll}
                  >
                    <List dense={dense} disablePadding={true}>
                      {filteredLocations ? (
                        filteredLocations.map((value: any, i: any) => (
                          <ListItem
                            button
                            selected={selectedIndex === i}
                            onClick={event => handleLocationClick(event, value.location, i)}
                            key={value.location}
                            className={'walklistCandidateContainer'}
                          >
                            {/*// @ts-ignore */}
                            <WalklistCandidate
                              address={value.location}
                              subject={getStatusArray(value.location)}
                              index={i}
                              phoneNumber={value.Cell}
                              knockStatuses={knockStatuses}
                            />
                          </ListItem>
                        ))
                      ) : (
                        <div></div>
                      )}
                    </List>
                  </InfiniteScroll>
                </div>
              </motion.div>
            </Grid>
          )}
        </AnimatePresence>

        <AnimatePresence exitBeforeEnter>
          {(!mobileMode || walklistPageDisplayed === 1) && (
            <Grid item xs={12} md={12}>
              <motion.div
                key="osdhnjf9080q32rj"
                className={classes.columnHeaderContainer}
                initial={drawerTransition.initial}
                animate={drawerTransition.in}
                exit={drawerTransition.out}
                transition={{ duration: 0.3 }}
              >
                {mobileMode && (
                  <div
                    className={classes.columnBackButtonContainer}
                    onClick={() => setWalklistPageDisplayed(walklistPageDisplayed - 1)}
                  >
                    <FontAwesomeIcon icon={faArrowLeft} className={classes.columnBackButton} />
                  </div>
                )}
                <p className={classes.columnTwoHeaderText}>{filteredWalklist[0]?.Address}</p>
              </motion.div>
              <motion.div
                key="sijdf0ij123rf"
                initial={drawerTransition.initial}
                animate={drawerTransition.in}
                exit={drawerTransition.out}
                transition={{ duration: 0.3 }}
                className="scrollbar"
                id="scrollableDiv"
                style={
                  !mobileMode
                    ? roomState === 'disconnected'
                      ? { height: 'calc(100vh - 157px)' }
                      : { height: 'calc(100vh - 325px)' }
                    : { height: 'calc(100vh - 96px)' }
                }
              >
                <div className="force-overflow">
                  {filteredWalklist.map((w: any, i: any) => (
                    <Accordion
                      key={w.Hid}
                      style={{
                        margin: '5px',
                        backgroundColor: darkMode
                          ? subjectSelected === i
                            ? '#505050' //dark mode is enabled and the subject is selected
                            : '#424242' //dark mode is enabled but the subject is not selected
                          : subjectSelected === i
                          ? '#dedede' //dark mode is disabled and the subject is selected
                          : '#e9e9e9', //dark mode is disabled and the subject is not selected
                      }}
                    >
                      <AccordionSummary
                        // expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1c-content"
                        id="panel1c-header"
                      >
                        <ListItemText primary={w.FirstName + ' ' + w.LastName} secondary={getCanvassStatus(w.Status)} />
                        {!mobileMode ? ( //Display expanded buttons if user is on desktop
                          <ListItemSecondaryAction>
                            <Button
                              type="submit"
                              variant="contained"
                              className={classes.knockStatusButton}
                              style={{ marginRight: '5px' }}
                              disabled={
                                doneStatusArray.every((value: number) => w.Status !== `${value}`) ? false : true
                              }
                              onClick={event => handleKnockStatusDisplay(event, w)}
                            >
                              Knock Status
                            </Button>

                            <ColorButton
                              type="submit"
                              color="primary"
                              variant="contained"
                              disabled={
                                doneStatusArray.every((value: number) => w.Status !== `${value}`) ? false : true
                              }
                              onClick={event => handleSubjectSurveyClick(event, w)}
                            >
                              Survey
                            </ColorButton>

                            <IconButton edge="end" aria-label="delete" onClick={event => event.stopPropagation()}>
                              <PhoneIcon />
                            </IconButton>

                            <IconButton
                              edge="end"
                              aria-label="delete"
                              onClick={event => {
                                event.stopPropagation();
                                setSubjectSelected(i);
                                handleNewMsg(event, w);
                              }}
                            >
                              {checkForMessagesHandler(w) && <div className={classes.newTextMessageSubjectIndicator} />}
                              <TextIcon
                                style={{
                                  color: subjectSelected === i ? '#4caf50' : darkMode ? '#fff' : 'rgba(0, 0, 0, 0.54)',
                                }}
                              />
                            </IconButton>
                          </ListItemSecondaryAction>
                        ) : (
                          //Display collapsable menu if the user is on mobile
                          <div style={{ display: 'grid', alignItems: 'center', justifyItems: 'center' }}>
                            <IconButton
                              aria-label="more"
                              aria-controls="long-menu"
                              aria-haspopup="true"
                              className={classes.subjectOptionsButton}
                              onClick={event => handleClick(event, i)}
                            >
                              {checkForMessagesHandler(w) && (
                                <div className={classes.newTextMessageSubjectIndicatorMobile} />
                              )}
                              <MoreVertIcon />
                            </IconButton>
                            <Menu
                              id="long-menu"
                              anchorEl={anchorEl}
                              keepMounted
                              open={open}
                              onClose={handleClose}
                              PaperProps={{
                                style: {
                                  maxHeight: ITEM_HEIGHT * 4.5,
                                },
                              }}
                            >
                              {doneStatusArray.every(
                                (value: number) => filteredWalklist[selectedUserIndex].Status !== `${value}`
                              ) && (
                                <MenuItem
                                  onClick={event => {
                                    handleKnockStatusDisplay(event, filteredWalklist[selectedUserIndex]);
                                    handleClose();
                                  }}
                                >
                                  Knock Status
                                </MenuItem>
                              )}
                              {doneStatusArray.every(
                                (value: number) => filteredWalklist[selectedUserIndex].Status !== `${value}`
                              ) && (
                                <MenuItem
                                  onClick={event => {
                                    handleSubjectSurveyClick(event, filteredWalklist[selectedUserIndex]);
                                    handleClose();
                                  }}
                                >
                                  Survey
                                </MenuItem>
                              )}
                              <MenuItem
                                onClick={event => {
                                  event.stopPropagation();
                                  handleClose();
                                }}
                              >
                                Call
                              </MenuItem>
                              <MenuItem
                                onClick={event => {
                                  handleClose();
                                  event.stopPropagation();
                                  handleNewMsg(event, filteredWalklist[selectedUserIndex]);
                                }}
                              >
                                Messages
                              </MenuItem>
                            </Menu>
                          </div>
                        )}
                      </AccordionSummary>
                      <AccordionDetails className={classes.details}>
                        <ThemeProvider theme={theme}>
                          <Table aria-label="simple table">
                            <TableBody>
                              <TableRow>
                                <TableCell className={classes.accordianText}>Full Name</TableCell>
                                <TableCell className={classes.accordianText}>
                                  {w.FirstName + ' ' + w.LastName}
                                </TableCell>
                              </TableRow>

                              <TableRow>
                                <TableCell className={classes.accordianText}>Address</TableCell>
                                <TableCell className={classes.accordianText}>{w.Address}</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell className={classes.accordianText}>Apartment</TableCell>
                                <TableCell className={classes.accordianText}>{w.Apartment}</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell className={classes.accordianText}>City</TableCell>
                                <TableCell className={classes.accordianText}>{w.City}</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell className={classes.accordianText}>State</TableCell>
                                <TableCell className={classes.accordianText}>{w.State}</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell className={classes.accordianText}>Zip</TableCell>
                                <TableCell className={classes.accordianText}>{w.Zip}</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell className={classes.accordianText}>Email</TableCell>
                                <TableCell className={classes.accordianText}>{w.Email}</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell className={classes.accordianText}>Phone</TableCell>
                                <TableCell className={classes.accordianText}>{w.Phone}</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell className={classes.accordianText}>CellPhone</TableCell>
                                <TableCell className={classes.accordianText}>{w.Cell}</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell className={classes.accordianText}>Other Phone</TableCell>
                                <TableCell className={classes.accordianText}>{w.Other.Phone}</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell className={classes.accordianText}>Other CellPhone</TableCell>
                                <TableCell className={classes.accordianText}>{w.Other.CellPhone}</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell colSpan={2}>
                                  <p
                                    className={classes.accordianText}
                                    style={{ display: 'inline-block', margin: '0px' }}
                                  >
                                    Other
                                  </p>
                                  <IconButton
                                    aria-label="expand row"
                                    size="small"
                                    onClick={() => expandAccordianHandler(i)}
                                  >
                                    {expandedAccordion[i] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell colSpan={2} style={{ padding: '0px', borderBottom: 'none' }}>
                                  <Collapse in={expandedAccordion[i]} timeout="auto" unmountOnExit>
                                    <Table>
                                      <TableBody>
                                        {getOtherOptionsHandler(w).map((value: any, index: number) => (
                                          <TableRow key={index}>
                                            <TableCell className={classes.accordianText}>{value.cellName}</TableCell>
                                            <TableCell style={{ padding: '4px 0px' }} className={classes.accordianText}>
                                              {value.cellData}
                                            </TableCell>
                                          </TableRow>
                                        ))}
                                      </TableBody>
                                    </Table>
                                  </Collapse>
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </ThemeProvider>
                      </AccordionDetails>
                    </Accordion>
                  ))}
                </div>
              </motion.div>
            </Grid>
          )}
        </AnimatePresence>
        <AnimatePresence exitBeforeEnter>
          {(!mobileMode || walklistPageDisplayed === 2) && (
            <Grid item xs={12} md={12} style={{ position: 'relative' }}>
              <motion.div
                key="cxvbjnw0eftji423"
                className={classes.columnHeaderContainer}
                initial={drawerTransition.initial}
                animate={drawerTransition.in}
                exit={drawerTransition.out}
                transition={{ duration: 0.3 }}
              >
                {mobileMode && (
                  <div
                    className={classes.columnBackButtonContainer}
                    onClick={() => setWalklistPageDisplayed(walklistPageDisplayed - 1)}
                  >
                    <FontAwesomeIcon icon={faArrowLeft} className={classes.columnBackButton} />
                  </div>
                )}
              </motion.div>
              {loadingActive ? (
                <LoadingOverlay
                  active={loadingActive}
                  spinner={<BounceLoader />}
                  text="Loading"
                  className={classes.loading}
                ></LoadingOverlay>
              ) : null}
              {phoneCount == 2 ? (
                <motion.div
                  key="sjkdfn90i23j0rsdf"
                  style={{ display: 'grid', gridTemplateRows: 'auto 1fr' }}
                  initial={drawerTransition.initial}
                  animate={drawerTransition.in}
                  exit={drawerTransition.out}
                  transition={{ duration: 0.3 }}
                >
                  <Grid container>
                    <Grid item xs={10}>
                      <Tabs
                        value={value}
                        onChange={handleChange}
                        variant="fullWidth"
                        indicatorColor="primary"
                        textColor="secondary"
                      >
                        <Tab label={phonelist[0]} />
                        <Tab label={phonelist[1]} />
                      </Tabs>
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      style={{ textAlign: 'center', display: 'grid', alignItems: 'center', justifyItems: 'center' }}
                    >
                      <Fab
                        size="small"
                        onClick={event => handleRefreshClick(event)}
                        style={{ position: 'relative', top: !mobileMode ? '7px' : '4px' }}
                      >
                        <RefreshIcon />
                      </Fab>
                    </Grid>
                  </Grid>
                  <TabPanel value={value} index={0} className={classes.chatPanel}>
                    <ChatPortal
                      //walkName={walkName}
                      phone={phone}
                      isRefresh={isRefresh}
                      isShowChat={isShowChat}
                      setIsShow={setIsShowChat}
                      msgText={msgText}
                      setMsgText={setMsgText}
                      setLoadingActive={setLoadingActive}
                      playSound={props.playSound}
                      subject={chatSubject}
                      automaticUpdates={props.automaticUpdates}
                      removeNewMessagesHandler={props.removeNewMessagesHandler}
                      //Hid={walklistjson.Walklist[selectedIndex].Hid}
                      //firstName={walklistjson.Walklist[selectedIndex].FirstName}
                    ></ChatPortal>
                  </TabPanel>
                  <TabPanel value={value} index={1} className={classes.chatPanel}>
                    <ChatPortal
                      //walkName={walkName}
                      phone={phone}
                      isShowChat={isShowChat}
                      setIsShow={setIsShowChat}
                      isRefresh={isRefresh}
                      msgText={msgText}
                      setMsgText={setMsgText}
                      setLoadingActive={setLoadingActive}
                      playSound={props.playSound}
                      automaticUpdates={props.automaticUpdates}
                      removeNewMessagesHandler={props.removeNewMessagesHandler}
                      //Hid={walklistjson.Walklist[selectedIndex].Hid}
                      //firstName={walklistjson.Walklist[selectedIndex].FirstName}
                      subject={chatSubject}
                    ></ChatPortal>
                  </TabPanel>
                </motion.div>
              ) : phoneCount == 1 ? (
                <motion.div
                  key="fsdnovsjn0q33r92y87"
                  style={{ display: 'grid', gridTemplateRows: 'auto 1fr' }}
                  initial={drawerTransition.initial}
                  animate={drawerTransition.in}
                  exit={drawerTransition.out}
                  transition={{ duration: 0.3 }}
                >
                  <Grid container>
                    <Grid item xs={10}>
                      <Tabs
                        value={value}
                        onChange={handleChange}
                        variant="fullWidth"
                        indicatorColor="primary"
                        textColor="secondary"
                      >
                        <Tab label={phonelist[0]} />
                      </Tabs>
                    </Grid>
                    <Grid item xs={2} style={{ textAlign: 'center' }}>
                      <Fab
                        onClick={event => handleRefreshClick(event)}
                        size="small"
                        style={{ position: 'relative', top: !mobileMode ? '7px' : '4px' }}
                      >
                        <RefreshIcon />
                      </Fab>
                    </Grid>
                  </Grid>
                  <TabPanel value={value} index={0} className={classes.chatPanel}>
                    <ChatPortal
                      //walkName={walkName}
                      phone={phone}
                      isRefresh={isRefresh}
                      isShowChat={isShowChat}
                      setIsShow={setIsShowChat}
                      msgText={msgText}
                      setMsgText={setMsgText}
                      setLoadingActive={setLoadingActive}
                      playSound={props.playSound}
                      automaticUpdates={props.automaticUpdates}
                      removeNewMessagesHandler={props.removeNewMessagesHandler}
                      //Hid={walklistjson.Walklist[selectedIndex].Hid}
                      //firstName={walklistjson.Walklist[selectedIndex].FirstName}
                      subject={chatSubject}
                    ></ChatPortal>
                  </TabPanel>
                </motion.div>
              ) : null}
            </Grid>
          )}
        </AnimatePresence>
      </Grid>
    </Box>
  );
}
