import React from 'react';
import './NewChatIndicator.css';

export default function NewChatIndicator() {
  return (
    <div className={'newChatIndicatorContainer'}>
      <div className={'newChatIndicatorCenterCircle'} />
      <div className={'newChatIndicatorOuterCircle'} />
    </div>
  );
}
