import React, { ChangeEvent, FormEvent, useState, useEffect } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import { useAppState } from '../../state';
import Button from '@material-ui/core/Button';
//import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
//import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import { styled } from '@material-ui/core/styles';

import Survey from '../Petition/Survey';

//import { useParams } from 'react-router-dom';
//import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import logo from './Publiclogo.png';

const Container = styled('div')({
  display: 'grid',
  gridTemplateRows: 'auto 1fr',
  flex: 1,
  justifyContent: 'center',
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      //background: 'theme.palette.background.paper',
      background: '#212121',
      width: 'auto',
      //textAlign: 'center',
      height: 'auto',
      //justifyContent: 'center',
      //display: 'flex',
      border: 'solid 2px #77AF4F',
      //flexDirection: 'column',
    },
    joinButton: {
      margin: '1em',
      background: '#77AF4F',
    },
    loadingSpinner: {
      marginLeft: '1em',
    },
    header: {
      width: '100%',
      //textAlign: 'center',
      fontSize: '24px',
      color: 'white',
    },
    logo: {
      justifySelf: 'center',
      height: '65px',
      width: 'auto',
    },
    RoomInfo: {
      marginLeft: 'auto',
      marginRight: 'auto',
      borderRadius: '10px',
      padding: '20px',
      flexDirection: 'column',
      fontSize: '20px',
      display: 'flex',
      // height: '360px',
      justifyContent: 'bottom',
    },
    surveyContainer: {
      //height: 'calc(100vh - 280px)',
      height: 'auto',
      //justifyContent: 'none',
      //overflowY: 'scroll',
      //overflowX: 'scroll',
      backgroundColor: theme.palette.background.paper,
    },
  })
);

export default function PublicPetitionStart() {
  //surveyContainer: {
  //  height: !mobileMode
  //    ? `${roomState !== 'disconnected' ? 'calc(100vh - 280px)' : 'calc(100vh - 112px)'}`
  //    : `calc(100vh - 50px)`,
  //    overflowY: 'scroll',
  //      overflowX: 'hidden',
  //        backgroundColor: theme.palette.background.paper,
  //  },
  /*
  const useStyles = makeStyles(theme => ({
    root: {
      //flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
    },
    surveyContainer: {
      height: 'calc(100vh - 280px)',
      overflowY: 'scroll',
      overflowX: 'hidden',
      backgroundColor: theme.palette.background.paper,
    },
    hiddenContainer: {
      display: 'none',
      pointerEvents: 'none',
    },
    bottomVideoDisplayContainer: {
      backgroundColor: theme.palette.background.paper,
      display: 'grid',
      gridTemplateColumns: '550px 285px auto',
      overflow: 'hidden',
      maxHeight: '168px',
      borderTop: '2px solid',
      borderColor: theme.tertiaryColors.banner,
    },
    videoContainer: {
      width: '100%',
      height: '100%',
      display: 'inline-block',
    },
    controlsContainer: {
      width: '100%',
      height: '65%',
      alignSelf: 'end',
      display: 'inline-block',
      position: 'relative',
    },
  }));
  */

  const classes = useStyles();

  //const { URLRoomName } = useParams();
  const {
    //setSurveySubject,
    setSurveySubjectPublic,
    surveyModel,
    petitionjson,
    surveySubject,
    loggedUsername,
  } = useAppState();
  //const { isConnecting, connect, isAcquiringLocalTracks } = useVideoContext();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [loaded, setLoaded] = useState<boolean>(false);
  //const [name, setName] = useState<string>(user ?.displayName || 'asdf');
  //const [roomName, setRoomName] = useState<string>('');
  // const [username, setUsername] = useState<string>('x1');
  //const [password, setPassword] = useState<string>('a');
  //const [identity, setIdentity] = useState<string>('');
  const [server, setServer] = useState<string>('');
  const [surveyId, setSurveyId] = useState<string>('');
  //const [petitionId, setPetitionId] = useState<string>('');
  //const [firstName, setFirstName] = useState<string>('');
  const [buttonName, setButtonName] = useState<string>('invalid survey link');

  // useEffect(() => {
  //   if (URLRoomName) {
  //     setRoomName(URLRoomName);
  //   }
  // }, [URLRoomName]);

  //get Info from url temporary
  useEffect(() => {
    let info = window.location.pathname.split('/survey/')[1];
    if (info != undefined && info != '') {
      let info_detail = info.split('-');
      //console.log(info_detail.length);
      if (
        info_detail.length === 6 &&
        info_detail[0].length === 1 &&
        info_detail[1].length === 8 &&
        info_detail[2].length === 4 &&
        info_detail[3].length === 4 &&
        info_detail[4].length === 4 &&
        info_detail[5].length === 12
      ) {
        if (info_detail[0].toLowerCase() === 'c') {
          setServer('canvass.grassrootsunwired.com');
          setSurveyId(
            info_detail[1] + '-' + info_detail[2] + '-' + info_detail[3] + '-' + info_detail[4] + '-' + info_detail[5]
          );
          setButtonName('Start Survey');
        } else if (info_detail[0].toLowerCase() === 'f') {
          setServer('fund.grassrootsunwired.com');
          setSurveyId(
            info_detail[1] + '-' + info_detail[2] + '-' + info_detail[3] + '-' + info_detail[4] + '-' + info_detail[5]
          );
          setButtonName('Start Survey');
        } else if (info_detail[0].toLowerCase() === 's') {
          setServer('staging.grassrootsunwired.com');
          setSurveyId(
            info_detail[1] + '-' + info_detail[2] + '-' + info_detail[3] + '-' + info_detail[4] + '-' + info_detail[5]
          );
          setButtonName('Start Survey');
        } else {
          setButtonName('invalid survey link');
        }
      } else {
        setButtonName('invalid survey link');
      }
    }
  }, []);

  //handle click start survey button
  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    if (buttonName == 'invalid survey link') return;

    setIsLoading(true);

    event.preventDefault();

    const headers = new window.Headers();
    headers.set('Content-Type', 'application/json');
    headers.set('Accept', 'application/json, text/plain, */*');

    //const endpoint = process.env.REACT_APP_TOKEN_ENDPOINT || '/accessTokenVideo';
    //const params = new window.URLSearchParams({ identity, roomName, petitionId });

    //console.log(surveyId);

    const body = JSON.stringify({
      surveyId: surveyId,
      //userId: '123',
      //subject: JSON.stringify(props.subject),
      //tonumber: props.phone,
      //message: inputValue,
    });

    //return fetch(`${endpoint}?${params}`, { headers }).then(res => res.text());
    //fetch(`http://localhost:52902/api/v2/petition/get-survey`, {
    fetch(`https://${server}/api/v2/petition/get-survey`, {
      headers: headers,
      method: 'POST',
      body: body,
    })
      .then(async res => {
        if (res.status === 200) {
          console.log('res 200 ok');
          return res.text();
        } else if (res.status === 409 || res.status === 412) {
          console.log('409 or 412 status');
          return JSON.stringify({ status: res.status, text: await res.text() });
        } else {
          console.log('not 200 ok');
          throw new Error(`Failed to retrieve survey. Status code ${res.status}`);
        }
      })
      //.then(token => connect(token))
      //.then(data => alert(data))
      .then(data => {
        if (data) {
          const parsed = JSON.parse(data);
          console.log(parsed);
          if (parsed.status === 409 || parsed.status === 412) {
            let parsedErrorMessage = JSON.parse(parsed.text);
            if (parsedErrorMessage?.Errors[0]?.Message) {
              throw new Error(parsedErrorMessage.Errors[0].Message);
            } else {
              throw new Error('Survey or payment already completed');
            }
          } else if (parsed.petition) {
            //console.log(parsed.subjectInfo);
            setSurveySubjectPublic(JSON.parse(parsed.subjectInfo), parsed.petition, server, parsed.userName, surveyId);
            setIsLoading(false);
            setLoaded(true);
          } else {
            throw new Error('Failed to retrieve survey data');
          }
        }
      })
      .catch(err => {
        //alert('Unable to join video');
        alert(err);
        setIsLoading(false);
      });
  };

  //<p className={classes.header}>Welcome {firstName}</p>

  //<div className={classes.surveyContainer}>
  //  <Survey></Survey>
  //</div>

  //<div className={classes.RoomInfo}>

  return (
    <div className={classes.root}>
      {!loaded && (
        <div>
          <img src={logo} alt="Logo" className={classes.logo} />

          <p className={classes.header}>Welcome</p>
        </div>
      )}

      <div>
        {/* <p>
              Room Number:
              <br />
              <b> {roomName} </b>
            </p>
            <TextField value={petitionId} label="Petition ID" disabled variant="outlined" />
            <br />
            <TextField value={identity} disabled label="Identifier" variant="outlined" /> */}
        {surveyModel && petitionjson && surveySubject && loggedUsername && (
          <div className={classes.surveyContainer}>
            <Survey></Survey>
          </div>
        )}
        {!loaded && (
          <form onSubmit={handleSubmit}>
            <Button
              className={classes.joinButton}
              type="submit"
              color="primary"
              variant="contained"
              disabled={
                buttonName == 'invalid survey link' ||
                //!name ||
                //!roomName ||
                //!username ||
                //!password ||
                isLoading
              }
            >
              {buttonName}
            </Button>
            {isLoading && <CircularProgress className={classes.loadingSpinner} />}
          </form>
        )}
      </div>

      {/* <Grid item xs={12} md={6} style={{ padding: '10px' }}></Grid> */}
    </div>
  );
}
