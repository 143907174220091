import React, { ChangeEvent, useState, FormEvent } from 'react';
import { useAppState } from '../../state';

import Button from '@material-ui/core/Button';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import GrassRootsLogo from './grassroots-logo.png';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import { getFormattedDate } from '../Petition/Survey';

import { CssBaseline } from '@material-ui/core';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import { useLocation, useHistory } from 'react-router-dom';

//@ts-ignore
import ReCAPTCHA from 'react-google-recaptcha';
import { Battery20 } from '@material-ui/icons';

const useStyles = makeStyles({
  container: {
    height: '100vh',
    background: '#0D122B',
  },
  twilioLogo: {
    width: '55%',
    display: 'block',
  },
  videoLogo: {
    width: '25%',
    padding: '2.4em 0 2.1em',
  },
  grassRootsLogo: {
    //width: '100%',//dont want image to expand when errors show up
    padding: '2.4em 0 2.1em',
    display: 'block',
  },
  paper: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    maxWidth: '460px',
    padding: '2em',
    marginTop: '4em',
    background: 'white',
    color: 'black',
  },
  button: {
    color: 'black',
    background: 'white',
    margin: '2em 0 0.5em 0',
    textTransform: 'none',
  },
  captcha: {
    margin: '2em 0 0 0',
  },
  button2: {
    color: 'white !important',
    background: 'green !important',
    margin: '0.8em 0 0.7em',
    textTransform: 'none',
  },
  errorMessage: {
    color: 'red',
    display: 'flex',
    alignItems: 'center',
    margin: '1em 0 0.2em',
    '& svg': {
      marginRight: '0.4em',
    },
  },
  loadingSpinner: {
    position: 'absolute',
    marginLeft: '1em',
    marginTop: '1.8em',
  },
  formControl: {
    marginTop: 30,
    minWidth: 184,
  },
});

const theme = createMuiTheme({
  palette: {
    type: 'light',
  },
});

export default function Login() {
  const classes = useStyles();
  const {
    signIn,
    getLogin,
    getPetition,
    getWalklist,
    isAuthReady,
    loggedImei,
    setLoggedImei,
    isFetchingWalklist,
    isFetchingPetition,
    isFetchingLogin,
  } = useAppState();

  const history = useHistory();
  const location = useLocation<{ from: Location }>();
  const [passcode, setPasscode] = useState('');
  const [username, setUsername] = useState('');
  const [server, setServer] = useState('canvass.grassrootsunwired.com');
  const [authError, setAuthError] = useState<Error | null>(null);

  //const isAuthEnabled = Boolean(process.env.REACT_APP_SET_AUTH);

  const [isNotRobot, setIsNotRobot] = useState(false);

  const loginPutHandler = () => {
    const headers = new window.Headers();
    //console.log('username:' + username);
    headers.set('Authorization', 'Bearer ' + sessionStorage.getItem('customg'));
    headers.set('imei', ('react00' + username).substr(0, 20)); //'a5c2ab7e5305232');
    headers.set('Content-Type', 'application/json');
    headers.set('Accept', 'application/json, text/plain, */*');

    let body = {
      Latitude: 40.1,
      Longitude: -74.8,
      Type: 'GPS',
      Uncertainty: 50,
      Time: getFormattedDate(new Date()),
      Batterylevel: 0,
      signalStrength: -120,
      RunningAppscount: 30,
      Brightness: 200,
      Bluetooth: true,
      Bondeddevices: 2,
      Wifi: false,
      Imei: 'react00' + username, //'a5c2ab7e5305232',
      Phonemodel: 'None',
      Networktype: 'LTE',
    };

    fetch(`https://` + server + `/api/v2/events/login`, {
      headers: headers,
      method: 'PUT',
      body: JSON.stringify(body),
    })
      .then(res => {
        if (res.status === 201 || res.status === 200) {
          console.log('User logged in successfully');
        }
      })
      .catch(err => console.log(err));
  };

  const login = () => {
    if (!isFetchingWalklist && !isFetchingPetition && !isFetchingLogin) {
      setAuthError(null);
      setLoggedImei(('react00' + username).substr(0, 20));
      getLoginOnly()
        .then(() => {
          setPasscode('');
          getWalklistAndPetition()
            .then(() => {
              loginPutHandler();
              history.replace(location?.state?.from || { pathname: '/start' });
            })
            .catch(err => setAuthError(err));
        })
        .catch(err => setAuthError(err));
    }
  };

  function getWalklistAndPetition() {
    return Promise.all([getWalklist(username, '', server), getPetition(username, '', server)]);
  }

  function getLoginOnly() {
    return Promise.all([getLogin(username, passcode, server)]);
  }

  function onChange(value: any) {
    setIsNotRobot(value);
  }

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    login();
  };

  //if (user || !isAuthEnabled) {
  //history.replace('/');
  //}

  //if (!isAuthReady) {
  //return null;
  //}
  require('dotenv').config();

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Grid container justify="center" alignItems="flex-start" className={classes.container}>
        <Paper className={classes.paper} elevation={6}>
          <img className={classes.grassRootsLogo} src={GrassRootsLogo} alt="GrassRoots Logo"></img>

          <form onSubmit={handleSubmit}>
            <Grid container alignItems="center" direction="column">
              <TextField
                variant="standard"
                id="input-username"
                label="Username"
                onChange={(e: ChangeEvent<HTMLInputElement>) => setUsername(e.target.value)}
                type="text"
              />
              <TextField
                variant="standard"
                id="input-passcode"
                label="Password"
                onChange={(e: ChangeEvent<HTMLInputElement>) => setPasscode(e.target.value)}
                type="password"
              />
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="demo-simple-select-outlined-label">Server</InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={server}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => setServer(e.target.value)}
                  label="Server"
                >
                  <MenuItem value="canvass.grassrootsunwired.com">Canvass</MenuItem>
                  <MenuItem value="fund.grassrootsunwired.com">Fund</MenuItem>
                  <MenuItem value="staging.grassrootsunwired.com">Staging</MenuItem>
                  <MenuItem value="portal.rightfield.gop">Rightfield</MenuItem>
                </Select>
              </FormControl>
              <ReCAPTCHA className={classes.captcha} sitekey={process.env.REACT_APP_SITE_KEY} onChange={onChange} />
              <div>
                {authError && (
                  <Typography variant="caption" className={classes.errorMessage}>
                    <ErrorOutlineIcon />
                    {authError.message}
                  </Typography>
                )}
              </div>

              <div>
                <Button
                  variant="contained"
                  className={classes.button}
                  type="submit"
                  disabled={!isNotRobot || !passcode.length || !username.length}
                >
                  Login
                </Button>
                {(isFetchingWalklist || isFetchingPetition || isFetchingLogin) && (
                  <CircularProgress className={classes.loadingSpinner} />
                )}
              </div>
            </Grid>
          </form>
        </Paper>
      </Grid>
    </ThemeProvider>
  );
}
