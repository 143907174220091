import React, { ChangeEvent, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useAppState } from '../../../state';
import Switch from '@material-ui/core/Switch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faComment } from '@fortawesome/free-solid-svg-icons';

//dropdown
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    serverDisplayContainer: {
      position: 'absolute',
      top: '-15px',
      left: '230px',
      height: '63px',
      width: '717px',
      borderRadius: '15px',
      backgroundColor: theme.tertiaryColors.default,
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.6)',
      display: 'grid',
      alignItems: 'end',
      gridAutoFlow: 'column',
      zIndex: 2,
      gridTemplateColumns: '171px 191px 158px 197px',
    },
    itemContainer: {
      backgroundColor: theme.palette.background.default,
      borderRadius: '10px',
      height: '35px',
      display: 'grid',
      alignItems: 'center',
      justifyItems: 'center',
      margin: '0px 0px 6px 6px',
    },
    serverText: {
      fontSize: '15px',
      margin: '0px',
      position: 'relative',
      top: '1px',
    },
    switch: {
      justifySelf: 'center',
    },
    userIcon: {
      fontSize: '22px',
      justifySelf: 'end',
      position: 'relative',
      color: '#F22F46',
      top: '-1px',
    },
    commentIcon: {
      fontSize: '22px',
      justifySelf: 'end',
      position: 'relative',
      color: '#4caf50',
      top: '-1px',
    },
    pendingText: {
      color: theme.palette.text.primary,
      margin: '0px',
      fontSize: '15px',
      marginRight: '2px',
      position: 'relative',
    },
  })
);

//<InputLabel id="demo-simple-select-outlined-label">Language</InputLabel>

export default function NotificationContainer() {
  const classes = useStyles();
  const { roomsOpen, totalRooms, newTextMessages, language, setLanguage, darkMode, setDarkMode } = useAppState();

  React.useEffect(() => {
    if (!darkMode) {
      document.documentElement.style.setProperty('--scrollbar-track-color', '#e2e2e2');
      document.documentElement.style.setProperty('--scrollbar-thumb-color', '#b4b4b4');
    } else {
      document.documentElement.style.setProperty('--scrollbar-track-color', '#303030');
      document.documentElement.style.setProperty('--scrollbar-thumb-color', '#202020');
    }
  }, [darkMode]);

  return (
    <div className={classes.serverDisplayContainer}>
      <div className={classes.itemContainer} style={{ width: '165px', gridTemplateColumns: '30px auto' }}>
        <FontAwesomeIcon icon={faUser} className={classes.userIcon} />
        <p className={classes.pendingText} style={{ top: '1px' }}>
          {roomsOpen} Voters Waiting
        </p>
      </div>
      <div className={classes.itemContainer} style={{ width: '185px', gridTemplateColumns: '16% auto' }}>
        <FontAwesomeIcon icon={faComment} className={classes.commentIcon} />
        <p className={classes.pendingText} style={{ top: '1px' }}>
          {newTextMessages} New Messages
        </p>
      </div>
      <div className={classes.itemContainer} style={{ width: '152px', gridTemplateColumns: '90px auto' }}>
        <p className={classes.pendingText} style={{ marginLeft: '5px' }}>
          Dark Mode{' '}
        </p>
        <Switch classes={{ root: classes.switch }} checked={darkMode} onChange={() => setDarkMode(!darkMode)} />
      </div>
      <div className={classes.itemContainer} style={{ width: '185px', gridTemplateColumns: '90px auto' }}>
        <p className={classes.pendingText} style={{ top: '1px' }}>
          Language
        </p>
        <FormControl>
          <Select
            id="demo-simple-select-outlined"
            value={language}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setLanguage(e.target.value)}
            label=""
          >
            <MenuItem value="default">English</MenuItem>
            <MenuItem value="es">Espa&#241;ol</MenuItem>
          </Select>
        </FormControl>
      </div>
    </div>
  );
}
