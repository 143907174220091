import React, { useEffect, useRef, useState } from 'react';
import { useAppState } from '../../state';
import { withStyles, createMuiTheme, makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import TextField from '@material-ui/core/TextField';
import Fab from '@material-ui/core/Fab';
import SendIcon from '@material-ui/icons/Send';
import SmsIcon from '@material-ui/icons/Sms';
import RefreshIcon from '@material-ui/icons/Refresh';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import VideoIcon from '@material-ui/icons/Videocam';
import AssignmentIcon from '@material-ui/icons/Assignment';
import getFilteredPhoneNumber from '../../utils/getFilteredPhoneNumber/getFilteredPhoneNumber';

//predefined popup
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';

//import axios
//import axios from 'axios';

//loading
//import BounceLoader from 'react-spinners/BounceLoader';
//import LoadingOverlay from 'react-loading-overlay';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

import { green, purple, blue, lightGreen } from '@material-ui/core/colors';
import { any } from 'prop-types';
import useRoomState from '../../hooks/useRoomState/useRoomState';

import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import './List.css';
const sendTextMessageSound = require('../../utils/AudioFiles/sendTextMessageSound.wav');

export default function InteractiveList(props: any) {
  const useStyles = makeStyles(theme => ({
    chatSection: {
      width: '100%',
      margin: '0px',
    },
    chatheader: {
      textAlign: 'center',
    },
    textR: {
      textAlign: 'right',
    },
    chatMine: {
      textAlign: 'left',
      display: 'inline-block',
      borderRadius: '10px 0px 10px 10px',
      background: 'lightgreen',
      padding: '5px 15px',
      maxWidth: '300px',
      wordWrap: 'break-word',
      boxShadow: 'rgba(0, 0, 0, 0.3) 0px 7px 7px',
    },
    chatOther: {
      textAlign: 'left',
      display: 'inline-block',
      borderRadius: '0px 10px 10px 10px',
      background: 'gray',
      padding: '5px 15px',
      maxWidth: '300px',
      wordWrap: 'break-word',
      boxShadow: 'rgba(0, 0, 0, 0.3) 0px 7px 7px',
    },
    textL: {
      textAlign: 'left',
    },
    messageArea: {
      overflowY: 'auto',
    },
    buttonSection: {
      width: 'calc(100% - 20px)',
      margin: '3px 10px',
      display: !mobileMode ? '' : 'grid',
      gridTemplateColumns: !mobileMode ? '' : 'auto 100px',
    },
    chatButton: {
      backgroundColor: '#F22F46',
      color: 'white',
      '&:hover': {
        backgroundColor: '#d11b31',
      },
    },
    textMenuIcon: {
      transform: 'scale(1.3)',
      padding: '6px',
    },
  }));

  const { petition, petitionjson, loggedUsername, server, textToPush, setTextToPush, mobileMode } = useAppState();
  const classes = useStyles();
  const [inputValue, setInputValue] = useState('');

  //dynamnic predefined texts
  const getPredefinedProperty = () => {
    for (let i = 0; i < petitionjson.ScriptProperties.length; ++i) {
      if (petitionjson.ScriptProperties[i].Name === 'TEXT_PREDEFINED') {
        return petitionjson.ScriptProperties[i].Value;
      }
    }
    return null;
  };

  const getSurveyPredefinedProperty = () => {
    for (let i = 0; i < petitionjson.ScriptProperties.length; ++i) {
      if (petitionjson.ScriptProperties[i].Name === 'SURVEY_PREDEFINED') {
        return petitionjson.ScriptProperties[i].Value;
      }
    }
    return null;
  };

  const predefinedSurvey = getSurveyPredefinedProperty();
  //@ts-ignore
  var predefinedSurveyId = null;

  if (predefinedSurvey) {
    if (parseInt(predefinedSurvey) && parseInt(predefinedSurvey) > 0) {
      predefinedSurveyId = parseInt(predefinedSurvey);
      //console.log(predefinedTextsSplit);
    }
  }

  const predefinedTexts = getPredefinedProperty();
  //@ts-ignore
  var predefinedTextsSplit: Array<string> = null;

  if (predefinedTexts) {
    predefinedTextsSplit = predefinedTexts.split('||');
    //console.log(predefinedTextsSplit);
  }

  //predefined texts
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  //handler to click refresh button
  const handleRefreshClick = () => {
    // props.setLoadingActive(true);
    getText(props.phone);
  };

  //call Twilio getText()
  const getText = (phoneNum: string) => {
    const headers = new window.Headers();
    headers.set('Authorization', 'Bearer ' + sessionStorage.getItem('customg'));
    headers.set('Content-Type', 'application/json');
    headers.set('Accept', 'application/json, text/plain, */*');

    let petientJson, petitionId;

    if (petition == null || petition == '') {
      petitionId = '';
    } else {
      petientJson = JSON.parse(petition);
      petitionId = petientJson['Id'];
    }
    const body = JSON.stringify({
      petitionid: petitionId,
      tonumber: phoneNum,
      message: 'test message 2',
    });

    fetch(`https://` + server + `/api/v2/twilio/getText`, {
      headers: headers,
      method: 'POST',
      body: body,
    })
      .then(res => {
        props.setIsShow(true);
        return res.json();
      })
      .then(data => {
        if (data.TextMessages != undefined) {
          props.setMsgText(data.TextMessages);
          props.removeNewMessagesHandler(getFilteredPhoneNumber(phoneNum));
          scrollToRef(messagesEndRef);
        } else {
          props.setMsgText([]);
        }
        //console.log(data);
        //console.log(data.TextMessages);
        props.setLoadingActive(false);
      })
      .catch(function(error) {
        props.setMsgText([]);
        console.log('Request failed', error);
        // props.setLoadingActive(false);
      });
  };

  const updateUnreadTexts = async (fromNumber: string, toNumber: string, message: string) => {
    const headers = new window.Headers();
    headers.set('Authorization', 'Bearer ' + sessionStorage.getItem('customg'));
    headers.set('Content-Type', 'application/json');
    headers.set('Accept', 'application/json, text/plain, */*');
    const body = JSON.stringify({
      petitionId: petitionjson.Id,
      fromnumber: fromNumber,
      tonumber: toNumber,
      message: message,
    });
    await fetch(`https://` + server + `/api/v2/twilio/updateText`, {
      headers: headers,
      method: 'POST',
      body: body,
    }).catch(error => {
      console.log('Request failed', error);
    });
  };

  //scrolldown
  const messagesEndRef = useRef(null);

  const scrollToRef = (ref: any) => {
    ref.current.scrollIntoView({
      behavior: 'smooth',
      block: 'end',
      inline: 'end',
    });
  };

  //get real phone number
  const getRealNum = (data: string) => {
    if (data == null || data == '') return;
    var matches = data.match(/\d/g);
    if (matches) {
      return '+1' + matches.join('');
    }
  };

  const getDateSent = () => {
    let date = new Date();
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = JSON.stringify(date.getDate()).length === 1 ? `0${date.getDate()}` : `${date.getDate()}`;
    let hours = date.getHours();
    let minutes = JSON.stringify(date.getMinutes()).length === 1 ? `0${date.getMinutes()}` : date.getMinutes();
    let seconds = JSON.stringify(date.getSeconds()).length === 1 ? `0${date.getSeconds()}` : date.getSeconds();
    let pm = false;
    if (hours >= 12) {
      pm = true;
      hours -= 12;
    }
    let fullDate = `${month}/${day}/${year} ${hours}:${minutes}:${seconds} ${pm ? 'PM' : 'AM'}`;
    return fullDate;
  };

  useEffect(() => {
    if (textToPush !== null) {
      let { msgText } = props;
      let newText = {
        Body: textToPush.message,
        DateSent: getDateSent(),
      };
      msgText.push(newText);
      props.setMsgText(msgText);
      updateUnreadTexts(textToPush.fromNumber, textToPush.toNumber, textToPush.message);
      setTextToPush(null);
      setTimeout(() => scrollToRef(messagesEndRef), 100);
    }
  }, [textToPush]);

  //handle new walk click
  useEffect(() => {
    if (props.phone != '' && props.phone != null) {
      getText(props.phone); //children function of interest
    }
  }, [props.phone]);

  //handle refresh button
  useEffect(() => {
    if (props.isRefresh != 0) {
      handleRefreshClick();
    }
  }, [props.isRefresh]);
  //call Twilio sendText()
  const sendText = () => {
    const headers = new window.Headers();
    headers.set('Authorization', 'Bearer ' + sessionStorage.getItem('customg'));
    headers.set('Content-Type', 'application/json');
    headers.set('Accept', 'application/json, text/plain, */*');

    let petientJson, petitionId;

    if (petition == null || petition == '') {
      petitionId = '';
    } else {
      petientJson = JSON.parse(petition);
      petitionId = petientJson['Id'];
    }
    const body = JSON.stringify({
      petitionid: petitionId,
      tonumber: props.phone,
      message: inputValue,
    });

    fetch(`https://` + server + `/api/v2/twilio/sendText`, {
      headers: headers,
      method: 'POST',
      body: body,
    })
      .then(res => {
        if (res.status === 200) {
          return 'OK';
        } else {
          return res.text();
        }
      })
      .then(data => {
        if (data !== 'OK') {
          if (data) {
            if (data === 'Already sent') {
              throw new Error('Failed to send text because it was already sent.');
            } else {
              const localJson = JSON.parse(data);
              if (localJson && localJson.ExceptionMessage) {
                throw new Error(localJson.ExceptionMessage);
              } else {
                throw new Error('Failed to send text');
              }
            }
          } else {
            throw new Error('Failed to send text');
          }
        }
        props.setMsgText([
          ...props.msgText,
          { Body: inputValue, DateSent: new Date().toLocaleString(), To: getRealNum(props.phone) },
        ]);
        setInputValue('');
        props.playSound(sendTextMessageSound, 2);
        scrollToRef(messagesEndRef);
      })
      .catch(err => {
        setInputValue('');
        if (err && err.message) {
          if (
            err.message
              .replaceAll(' ', '')
              .trim()
              .toLowerCase()
              .includes('fromphonenumber')
          ) {
            alert('Unable to send text.  Please verify from number.');
          } else if (
            err.message
              .replaceAll(' ', '')
              .trim()
              .toLowerCase()
              .includes('alreadysent')
          ) {
            alert('Unable to send text because it was already sent.');
          } else {
            alert('Unable to send text');
          }
        } else {
          alert('Unable to send text');
        }
      });
  };

  //handler to click send button or press enter
  const sendMsg = (event: any) => {
    event.preventDefault();
    if (!inputValue || inputValue.trim() === '') return;
    console.log('trying to send text');
    sendText();
    scrollToRef(messagesEndRef);
  };

  const addSurveyLinkText = () => {
    const headers = new window.Headers();
    headers.set('Authorization', 'Bearer ' + sessionStorage.getItem('customg'));
    headers.set('Content-Type', 'application/json');
    headers.set('Accept', 'application/json, text/plain, */*');

    let petitionJson2, petitionId;

    if (petition == null || petition == '') {
      petitionId = '';
      alert('Unable to initiate survey link');
    } else if (props == null || props.subject == null) {
      petitionId = '';
      alert('Unable to initiate survey link. Missing subject details.');
    } else if (props.subject.Status && props.subject.Status === '14') {
      console.log(props.subject);
      alert('Unable to initiate survey link. Subject already surveyed.');
    } else {
      petitionJson2 = JSON.parse(petition);
      petitionId = petitionJson2['Id'];

      //console.log(props.subject.toString());

      const body = JSON.stringify({
        petitionid: petitionId,
        //userId: '123',
        userName: loggedUsername,
        subjectInfo: JSON.stringify(props.subject),
        subjectId: props.subject.Hid,
        //tonumber: props.phone,
        //message: inputValue,
      });

      fetch(`https://` + server + `/api/v2/petition/get-survey-link`, {
        //fetch(`http://localhost:52902/api/v2/petition/get-survey-link`, {
        headers: headers,
        method: 'POST',
        body: body,
      })
        .then(res => {
          if (res.status === 200) {
            //return 'OK';
            return res.text();
          } else {
            //return res.text();
            return 'bad';
          }
        })
        .then(data => {
          if (data === 'bad') {
            if (data) {
              //@ts-ignore
              if (data === 'Already sent') {
                throw new Error('Failed to send text because it was already sent.');
              } else {
                const localJson = JSON.parse(data);
                if (localJson && localJson.ExceptionMessage) {
                  throw new Error(localJson.ExceptionMessage);
                } else {
                  throw new Error('Failed to retrieve survey link');
                }
              }
            } else {
              throw new Error('Failed to retrieve survey link');
            }
          }
          let link = `${window.location.origin}/survey/${server.charAt(0)}-${data}`;

          if (inputValue !== '') {
            //if (!inputValue.endsWith(videoUrl)) {
            setInputValue(inputValue + ' ' + link);
            //setInputValue(inputValue + ' ' + 'survey link ok');
            //}
          } else {
            setInputValue(link);
          }
          //props.setMsgText([
          //  ...props.msgText,
          //  { Body: inputValue, DateSent: new Date().toLocaleString(), To: getRealNum(props.phone) },
          //]);
          //setInputValue('');
          //props.playSound(sendTextMessageSound, 2);
          //scrollToRef(messagesEndRef);
        })
        .catch(err => {
          setInputValue('');
          if (err && err.message) {
            if (
              err.message
                .replaceAll(' ', '')
                .trim()
                .toLowerCase()
                .includes('fromphonenumber')
            ) {
              alert('Unable to retrieve survey link.');
            } else if (
              err.message
                .replaceAll(' ', '')
                .trim()
                .toLowerCase()
                .includes('alreadysent')
            ) {
              alert('Unable to retrieve survey link.');
            } else {
              alert('Unable to retrieve survey link');
            }
          } else {
            alert('Unable to retrieve survey link');
          }
        });
    }
  };

  //handler to click survey button
  const handleSurveyLinkMsg = (event: any) => {
    event.preventDefault();
    //if (!inputValue || inputValue.trim() === '') return;
    //console.log('trying to send text');
    console.log('trying to add survey link message');
    addSurveyLinkText();
    //sendText();
    //scrollToRef(messagesEndRef);
  };

  //handler for opening predefined messages
  const handlePredefinedOpen = (event: any) => {
    //event.preventDefault();
    //@ts-ignore
    console.log(event.currentTarget);
    if (predefinedTextsSplit && predefinedTextsSplit.length > 0) {
      setAnchorEl(event.currentTarget);
    } else {
      alert('No pre-defined messages');
    }
  };

  const handleMobilePredefinedOpen = () => {
    if (predefinedTextsSplit && predefinedTextsSplit.length > 0) {
      setAnchorEl(chatMenuAnchorEl);
    } else {
      alert('No pre-defined messages');
    }
  };

  //handler for closing predefined messages
  const handlePredefinedClose = () => {
    setAnchorEl(null);
  };

  //handler for selecting predefined messages
  const handlePredefinedSelect = (message: any) => {
    if (message) {
      //if (inputValue !== "") {
      //  setInputValue(inputValue + " " + message);
      //} else {
      setInputValue(message);
      //}
    }
    setAnchorEl(null);
  };

  const inviteToVideoHandler = () => {
    let parsedPetition = JSON.parse(petition);
    let videoUrl = `${window.location.origin}/join/${server}_${parsedPetition.Id}_${props.subject.Hid}_${loggedUsername}_${props.subject.FirstName}`;

    if (inputValue !== '') {
      if (!inputValue.endsWith(videoUrl)) {
        setInputValue(inputValue + ' ' + videoUrl);
      }
    } else {
      setInputValue(videoUrl);
    }
  };

  const roomState = useRoomState();

  const [chatMenuAnchorEl, setChatMenuAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(chatMenuAnchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    //setAnchorEl establishes the UI element that the menu will anchor to and expand from
    event.stopPropagation();
    setChatMenuAnchorEl(event.currentTarget);
  };

  const ITEM_HEIGHT = 48;

  const handleClose = () => {
    setChatMenuAnchorEl(null);
  };

  //{
  //predefinedTextsSplit && predefinedTextsSplit.length > 0 && predefinedTextsSplit.map((value: any, i: any) => (

  //  <MenuItem onClick={handlePredefinedClose}>{value}</MenuItem>
  //  <ListItem
  //    button
  //    selected={selectedIndex === i}
  //    onClick={event => handleLocationClick(event, value.location, i)}
  //    key={value.location}
  //    className={'walklistCandidateContainer'}
  //  >
  //    {/*// @ts-ignore */}
  //    <WalklistCandidate
  //      address={value.location}
  //      subject={getStatusArray(value.location)}
  //      index={i}
  //      phoneNumber={value.Cell}
  //    />
  //  </ListItem>
  //                ))
  //}

  return (
    <div>
      {props.isShowChat ? (
        <Grid //Whole component container
          container
          component={Paper}
          className={classes.chatSection}
          style={
            !mobileMode
              ? roomState === 'disconnected'
                ? { height: 'calc(100vh - 265px)' }
                : { height: 'calc(100vh - 433px)' }
              : { height: 'calc(100vh - 174px' }
          }
        >
          <Grid item xs={12}>
            <List //Container for text messages
              className={classes.messageArea}
              style={
                !mobileMode
                  ? roomState === 'disconnected'
                    ? { height: 'calc(100vh - 330px)' }
                    : { height: 'calc(100vh - 498px)' }
                  : { height: 'calc(100vh - 239px' }
              }
            >
              {props.msgText.map((msg: any) => (
                <ListItem>
                  {getFilteredPhoneNumber(props.phone) == getFilteredPhoneNumber(msg.To) ? ( //send msg to right
                    <Grid container className={classes.textR}>
                      <Grid item xs={12}>
                        <ListItemText className={classes.chatMine}>{msg.Body}</ListItemText>
                      </Grid>
                      <Grid item xs={12}>
                        <ListItemText secondary={msg.DateSent}></ListItemText>
                      </Grid>
                    </Grid>
                  ) : (
                    //recieve msg to right
                    <Grid container className={classes.textL}>
                      <Grid item xs={12} className={classes.chatOther}>
                        <ListItemText>{msg.Body}</ListItemText>
                      </Grid>
                      <Grid item xs={12}>
                        <ListItemText secondary={msg.DateSent}></ListItemText>
                      </Grid>
                    </Grid>
                  )}
                </ListItem>
              ))}
              <div ref={messagesEndRef} />
            </List>

            <Divider />
            <Grid container className={classes.buttonSection}>
              <Grid item xs={8} style={{ width: '100%', maxWidth: '100%' }}>
                <TextField
                  id="outlined-basic-email"
                  onChange={event => setInputValue(event.target.value)}
                  value={inputValue}
                  label="Type Something"
                  fullWidth
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  onKeyPress={ev => {
                    if (ev.key === 'Enter') {
                      // Do code here
                      sendMsg(ev);
                    }
                  }}
                />
              </Grid>
              {!mobileMode ? (
                <Grid
                  item
                  xs={4}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Fab
                    color="primary"
                    size="small"
                    className={classes.chatButton}
                    aria-label="add"
                    onClick={event => sendMsg(event)}
                  >
                    <SendIcon />
                  </Fab>
                  <Fab
                    color="primary"
                    size="small"
                    className={classes.chatButton}
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={event => handlePredefinedOpen(event)}
                    style={{ margin: '0px 0px 0px 5px' }}
                  >
                    <SmsIcon />
                  </Fab>
                  <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handlePredefinedClose}
                  >
                    {predefinedTextsSplit &&
                      predefinedTextsSplit.length > 0 &&
                      predefinedTextsSplit.map((value: any, i: any) => (
                        <MenuItem onClick={event => handlePredefinedSelect(value)}>{value}</MenuItem>
                      ))}
                  </Menu>
                  <Fab
                    color="primary"
                    size="small"
                    className={classes.chatButton}
                    aria-label="add"
                    onClick={inviteToVideoHandler}
                    style={{ margin: '0px 5px' }}
                  >
                    <VideoIcon />
                  </Fab>
                  {predefinedSurveyId && (
                    <Fab
                      color="primary"
                      size="small"
                      className={classes.chatButton}
                      aria-label="add"
                      onClick={event => handleSurveyLinkMsg(event)}
                    >
                      <AssignmentIcon />
                    </Fab>
                  )}
                </Grid>
              ) : (
                <div
                  style={{
                    display: 'grid',
                    gridTemplateColumns: '50% 50%',
                    alignItems: 'center',
                    justifyItems: 'center',
                    position: 'relative',
                    top: '4px',
                    left: '3px',
                  }}
                >
                  <Fab
                    color="primary"
                    size="small"
                    className={classes.chatButton}
                    aria-label="add"
                    onClick={event => sendMsg(event)}
                  >
                    <SendIcon />
                  </Fab>
                  <IconButton
                    className={classes.textMenuIcon}
                    aria-label="more"
                    aria-controls="long-menu"
                    aria-haspopup="true"
                    onClick={handleClick}
                  >
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    id="long-menu"
                    anchorEl={chatMenuAnchorEl}
                    keepMounted
                    open={open}
                    onClose={handleClose}
                    PaperProps={{
                      style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                      },
                    }}
                  >
                    {predefinedTextsSplit &&
                      predefinedTextsSplit.length > 0 &&
                      predefinedTextsSplit.map((value: any, i: any) => (
                        <MenuItem
                          onClick={() => {
                            handlePredefinedSelect(value);
                            handleClose();
                          }}
                          style={{ overflow: 'auto' }}
                        >
                          "{value}"
                        </MenuItem>
                      ))}
                    <MenuItem
                      onClick={() => {
                        inviteToVideoHandler();
                        handleClose();
                      }}
                    >
                      Invite to Video
                    </MenuItem>
                    {predefinedSurveyId && (
                      <MenuItem
                        onClick={event => {
                          handleSurveyLinkMsg(event);
                          handleClose();
                        }}
                      >
                        Send Survey Link
                      </MenuItem>
                    )}
                  </Menu>
                </div>
              )}
            </Grid>
          </Grid>
        </Grid>
      ) : null}
    </div>
  );
}
