import React, { useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Button from '@material-ui/core/Button';
import { green } from '@material-ui/core/colors';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      height: '50px',
      width: '50%',
      marginLeft: '50%',
      color: 'white',
      backgroundColor: green[500],
      '&:hover': {
        backgroundColor: green[700],
      },
    },
    checkbox: {
      position: 'absolute',
      right: '5px',
      zIndex: 2,
    },
    title: {
      fontSize: '24px',
      fontWeight: 'bold',
      margin: '0px',
      padding: '18px 0px 10px 15px',
    },
    optionText: {
      fontSize: '16px',
      margin: '5px 0px',
    },
  })
);

export default function FilterDialogue(props: any) {
  const [filters, setFilters] = useState(props.knockStatuses.map(() => false));

  const [filterNewMessages, setFilterNewMessages] = useState(false);
  const [latestFilters, setLatestFilters] = useState(props.knockStatuses.map(() => false));

  const classes = useStyles();
  const { onClose, open } = props;

  const handleClose = () => {
    let statusArray = []; //Create an array to hold the status values (0, 1, 2, 3 or 14) selected by filter
    for (let i = 0; i < props.knockStatuses.length; ++i) {
      if (filters[i]) {
        //If filter array value at i is true, add the status to the array
        statusArray.push(props.knockStatuses[i].LocalId);
      }
    }
    setLatestFilters(filters);
    onClose(statusArray, filterNewMessages, true); //return the status array containing values to be filtered for with newMessages filter seperate. True value indicates filters were changed
  };

  const updateFilters = (index: number) => {
    let newFilters = [...filters];
    newFilters[index] = !newFilters[index];
    setFilters(newFilters);
  };

  return (
    <Dialog
      onClose={() => {
        setFilters(latestFilters);
        onClose([], false);
      }}
      aria-labelledby="simple-dialog-title"
      open={open}
      fullWidth={true}
      maxWidth={'xs'}
    >
      <p className={classes.title}>Select Filters</p>
      <List>
        {props.knockStatuses.map((value: any, index: number) => (
          <ListItem button onClick={() => updateFilters(index)} key={index}>
            <p className={classes.optionText}>
              {value.LocalId} - {value.Name}
            </p>
            <Checkbox checked={filters[index]} className={classes.checkbox} />
          </ListItem>
        ))}
        <ListItem button onClick={() => setFilterNewMessages(!filterNewMessages)}>
          <p className={classes.optionText}>New Text Messages</p>
          <Checkbox checked={filterNewMessages} className={classes.checkbox} />
        </ListItem>
        <ListItem autoFocus>
          <Button className={classes.button} type="submit" color="primary" variant="contained" onClick={handleClose}>
            Filter
          </Button>
        </ListItem>
      </List>
    </Dialog>
  );
}
