import React, { ChangeEvent, FormEvent, useState, useEffect } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import { useAppState } from '../../state';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import { styled } from '@material-ui/core/styles';

import { useParams } from 'react-router-dom';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import logo from './logo.png';

const Container = styled('div')({
  display: 'grid',
  gridTemplateRows: 'auto 1fr',
  flex: 1,
  justifyContent: 'center',
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      //background: 'theme.palette.background.paper',
      background: '#212121',
      width: 'auto',
      textAlign: 'center',
      height: 'auto',
      justifyContent: 'center',
      display: 'flex',
      border: 'solid 2px #77AF4F',
      flexDirection: 'column',
    },
    joinButton: {
      margin: '1em',
      background: '#77AF4F',
    },
    loadingSpinner: {
      marginLeft: '1em',
    },
    header: {
      width: '100%',
      textAlign: 'center',
      fontSize: '24px',
      color: 'white',
    },
    RoomInfo: {
      marginLeft: 'auto',
      marginRight: 'auto',
      borderRadius: '10px',
      padding: '20px',
      flexDirection: 'column',
      fontSize: '20px',
      display: 'flex',
      // height: '360px',
      justifyContent: 'bottom',
    },
  })
);

export default function JoinRoom() {
  const classes = useStyles();
  const { URLRoomName } = useParams();
  const { getToken, isFetching } = useAppState();
  const { isConnecting, connect, isAcquiringLocalTracks } = useVideoContext();

  //const [name, setName] = useState<string>(user ?.displayName || 'asdf');
  const [roomName, setRoomName] = useState<string>('');
  // const [username, setUsername] = useState<string>('x1');
  //const [password, setPassword] = useState<string>('a');
  const [identity, setIdentity] = useState<string>('');
  const [server, setServer] = useState<string>('');
  const [petitionId, setPetitionId] = useState<string>('');
  const [firstName, setFirstName] = useState<string>('');
  const [buttonName, setButtonName] = useState<string>('Join Video');

  // useEffect(() => {
  //   if (URLRoomName) {
  //     setRoomName(URLRoomName);
  //   }
  // }, [URLRoomName]);

  //get Info from url temporary
  useEffect(() => {
    let info = window.location.pathname.split('/join/')[1];
    if (info != undefined && info != '') {
      let info_detail = info.split('_');

      if (info_detail.length == 5 && !isNaN(Number(info_detail[1])) && !isNaN(Number(info_detail[2]))) {
        setRoomName(info);
        setServer(info_detail[0]);
        setFirstName(info_detail[4]);
        setIdentity(info_detail[2]);
        setPetitionId(info_detail[1]);
      } else {
        setButtonName('invalid video room link');
      }
    }
  }, []);

  //handle click join button
  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    if (buttonName == 'invalid video room link') return;

    event.preventDefault();

    const headers = new window.Headers();

    //const endpoint = process.env.REACT_APP_TOKEN_ENDPOINT || '/accessTokenVideo';
    const params = new window.URLSearchParams({ identity, roomName, petitionId });

    //return fetch(`${endpoint}?${params}`, { headers }).then(res => res.text());
    fetch(`https://${server}/api/v2/twilio/accessTokenVideoAnon?${params}`, {
      headers: headers,
      method: 'GET',
    })
      .then(res => {
        if (res.status === 200) {
          console.log('res 200 ok');
          return res.text();
        } else {
          console.log('not 200 ok');
          throw new Error('Failed to get token');
        }
      })
      .then(token => connect(token))
      .catch(err => {
        alert('Unable to join video');
      });
  };

  return (
    <div className={classes.root}>
      <Grid container direction="row">
        <Grid item xs={12} md={12} style={{ padding: '20px' }}>
          <img src={logo} alt="Logo" style={{ justifySelf: 'center', height: '65px' }} />

          <p className={classes.header}>Welcome {firstName}</p>
          <div className={classes.RoomInfo}>
            {/* <p>
              Room Number:
              <br />
              <b> {roomName} </b>
            </p>
            <TextField value={petitionId} label="Petition ID" disabled variant="outlined" />
            <br />
            <TextField value={identity} disabled label="Identifier" variant="outlined" /> */}
            <form onSubmit={handleSubmit}>
              <Button
                className={classes.joinButton}
                type="submit"
                color="primary"
                variant="contained"
                disabled={
                  buttonName == 'invalid video room link' ||
                  isAcquiringLocalTracks ||
                  isConnecting ||
                  //!name ||
                  !roomName ||
                  //!username ||
                  //!password ||
                  isFetching
                }
              >
                {buttonName}
              </Button>
              {(isConnecting || isFetching) && <CircularProgress className={classes.loadingSpinner} />}
            </form>
          </div>
        </Grid>
        {/* <Grid item xs={12} md={6} style={{ padding: '10px' }}></Grid> */}
      </Grid>
    </div>
  );
}
