import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import useRoomState from '../../../hooks/useRoomState/useRoomState';
import LocalVideoPreview from '../../../components/LocalVideoPreview/LocalVideoPreview';
import Room from '../../../components/Room/Room';
import { useAppState } from '../../../state';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      // height: '300px',
      width: '100%',
      display: 'grid',
    },
  })
);

export default function VideoViewer() {
  const { mobileMode } = useAppState();
  const classes = useStyles();
  const roomState = useRoomState();

  return <div className={classes.container}>{roomState === 'disconnected' ? <LocalVideoPreview /> : <Room />}</div>;
}
