import React, { useState } from 'react';
import Participant from '../Participant/Participant';
import { styled, createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import useParticipants from '../../hooks/useParticipants/useParticipants';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import useSelectedParticipant from '../VideoProvider/useSelectedParticipant/useSelectedParticipant';

const Container = styled('aside')(({ theme }) => ({
  overflowY: 'auto',
  height: '100%',
}));

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    videoContainer: {
      border: '3px solid',
      borderColor: theme.tertiaryColors.default,
    },
  })
);

export default function ParticipantStrip() {
  const ScrollContainer = styled('div')(({ theme }) => {
    return {
      display: 'grid',
      gridTemplateColumns: '50% 50%',
      height: '100%',
    };
  });

  const {
    room: { localParticipant },
  } = useVideoContext();
  const participants = useParticipants();
  const [selectedParticipant, setSelectedParticipant] = useSelectedParticipant();
  const classes = useStyles();

  return (
    <Container>
      <ScrollContainer>
        <div className={classes.videoContainer}>
          <Participant
            participant={localParticipant}
            isSelected={selectedParticipant === localParticipant}
            onClick={() => null}
            customStyles={{ maxHeight: '160px' }}
          />
        </div>
        <div className={classes.videoContainer}>
          {participants.map(participant => (
            <Participant
              key={participant.sid}
              participant={participant}
              isSelected={selectedParticipant === participant}
              onClick={() => null}
              customStyles={{ maxHeight: '160px' }}
            />
          ))}
        </div>
      </ScrollContainer>
    </Container>
  );
}
