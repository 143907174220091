const getFilteredPhoneNumber = (phoneNumber: string) => {
  if (phoneNumber === null || phoneNumber === undefined) return 'Invalid Phone Number';
  //@ts-ignore
  phoneNumber = phoneNumber.replaceAll(' ', '');
  let numbersFound = 0;
  let reversedPhoneNumber = '';
  for (let i = phoneNumber.length - 1; i >= 0; --i) {
    if (!isNaN(Number(phoneNumber[i]))) {
      numbersFound++;
      reversedPhoneNumber += phoneNumber[i];
    }
    if (numbersFound === 10) {
      return reversedPhoneNumber
        .split('')
        .reverse()
        .join('');
    }
  }
  return 'Invalid Phone Number';
};

export default getFilteredPhoneNumber;
