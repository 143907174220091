import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import NewChatIndicator from '../Petition/NewChatIndicator/NewChatIndicator';
import { useAppState } from '../../state';
import getFilteredPhoneNumber from '../../utils/getFilteredPhoneNumber/getFilteredPhoneNumber';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'grid',
      gridTemplateRows: '38px auto',
      gridTemplateColumns: '10px auto',
      minHeight: '70px',
      width: '100%',
      position: 'relative',
      borderBottom: '1px solid #606060',
    },
    colorBar: {
      height: '100%',
      width: '100%',
      gridRowStart: 1,
      gridRowEnd: 3,
      gridColumn: 1,
    },
    addressText: {
      margin: '0px',
      alignSelf: 'end',
      fontSize: '16px',
      marginLeft: '5px',
    },
    subjectText: {
      margin: '0px',
      alignSelf: 'start',
      fontSize: '14px',
      marginLeft: '5px',
      marginRight: '30px',
    },
    arrow: {
      position: 'absolute',
      top: '50%',
      right: '20px',
      transform: 'translate(0px, -50%)',
      '&::before': {
        content: `''`,
        display: 'inline-block',
        backgroundColor: '#808080',
        height: '20px',
        width: '2px',
        transform: 'rotate(45deg)',
        position: 'absolute',
        top: '-3px',
      },
      '&::after': {
        content: `''`,
        display: 'inline-block',
        backgroundColor: '#808080',
        height: '20px',
        width: '2px',
        transform: 'rotate(-45deg)',
        position: 'absolute',
        bottom: '-3px',
      },
    },
  })
);

export default function WalklistCandidate(props: any) {
  const classes = useStyles();
  const { newTextMessageList, walklistjson } = useAppState();

  const formatSubjectDisplay = (subjectArray: Array<string>) => {
    let subjectText = `${subjectArray.length} ${subjectArray.length === 1 ? 'Subject -' : 'Subjects -'} Status: `;
    for (let i = 0; i < subjectArray.length; ++i) {
      if (i > 0) {
        subjectText += ', '; //Add a comma for each status after the first
      }
      subjectText += subjectArray[i];
    }

    return subjectText;
  };

  //@ts-ignore
  const newTextDisplay = subjectAddress => {
    var found = false;

    if (subjectAddress && walklistjson && walklistjson.Walklist && newTextMessageList) {
      let tmpNumbers: any[] = [];

      //looking at both options for cell
      walklistjson.Walklist.map((w: any, i: any) => {
        if (w.Address === subjectAddress) {
          if (w.Cell) {
            tmpNumbers.push(getFilteredPhoneNumber(w.Cell));
          }
          if (w.Other && w.Other.CellPhone) {
            tmpNumbers.push(getFilteredPhoneNumber(w.Other.CellPhone));
          }
        }
      });

      if (tmpNumbers && tmpNumbers.length > 0) {
        found = newTextMessageList?.some((newTextMessageListValue: any, index: number) =>
          tmpNumbers.some((tmpNumbersValue: any, index: number) => newTextMessageListValue[0] === tmpNumbersValue)
        );

        if (found) {
          return true;
        }
      }
    }

    return false;
  };

  const doneStatuses = props.knockStatuses.filter((value: any) => value.CategoryName === 'Done');

  const getStatusColor = (subjectArray: Array<string>) => {
    let knockStatuses = []; //0 = not knocked, 1 = knocked but not canvassed, 2 = canvassed

    for (let i = 0; i < subjectArray.length; ++i) {
      if (subjectArray[i] === '0') {
        knockStatuses.push(0);
      } else if (doneStatuses.some((value: any) => Number(subjectArray[i]) === value.LocalId)) {
        knockStatuses.push(2);
      } else {
        knockStatuses.push(1);
      }
    }

    if (knockStatuses.every((value: any) => value === 2)) {
      //If every subject at a given address is canvassed
      return '#4caf50'; //green
    } else if (knockStatuses.every((value: any) => value === 0)) {
      //if every subject as a given address hasn't been knocked yet
      return ''; //clear
    } else if (
      knockStatuses.some((value: any) => value === 2) &&
      !knockStatuses.every((value: any, index: number, arr: any) => value === arr[0])
    ) {
      //if subjects at a given address don't all have the same knock status
      return '#ffeb06'; //yellow
    } else {
      return '#F22F46'; //red
    }

    // if (notKnocked > 0 && nonCanvassed <= 0 && canvassed <= 0) {
    //   return ''; //return white if status' are 0 //should be '#fffff' but looks cleaner without the white
    // } else if (canvassed > 0 && nonCanvassed <= 0 && notKnocked <= 0) {
    //   return '#4caf50'; //if status of all subjects is 14, return green
    // } else if (nonCanvassed > 0 && canvassed <= 0) {
    //   return '#F22F46'; //if subject status is neither 0 nor 14, return red
    // } else {
    //   return '#ffeb06'; //return yellow if mixed
    // }
  };

  const color = getStatusColor(props.subject);

  return (
    <div className={classes.container}>
      <div className={classes.colorBar} style={{ backgroundColor: color === '' ? 'rgba(0, 0, 0, 0)' : color }} />
      <p className={classes.addressText}>{props.address}</p>
      <p className={classes.subjectText} style={{ color }}>
        {formatSubjectDisplay(props.subject)}
      </p>
      {newTextDisplay(props.address) && <NewChatIndicator />}
      <div className={classes.arrow} />
    </div>
  );
}
