import React, { ChangeEvent } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faComment } from '@fortawesome/free-solid-svg-icons';
import Switch from '@material-ui/core/Switch';
import { useAppState } from '../../state';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import Menu from '../MenuBarStart/Menu/Menu';
import { motion } from 'framer-motion';

export default function MobileNavigationDrawer(props: any) {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      navigationDrawerPageContainer: {
        height: '100vh',
        width: '100%',
        position: 'absolute',
        top: '0',
        left: '0',
        zIndex: 1000,
      },
      navigationDrawerBackdrop: {
        position: 'absolute',
        top: '0',
        left: '0',
        height: '100vh',
        width: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.65)',
        zIndex: 1001,
      },
      navigationDrawerContainer: {
        position: 'absolute',
        left: '0',
        top: '0',
        height: '100vh',
        width: '80%',
        maxWidth: '300px',
        backgroundColor: theme.palette.background.default,
        zIndex: 1002,
      },
      headerContainer: {
        height: '50px',
        width: '100%',
        borderBottom: '1px solid #707070',
        display: 'grid',
      },
      closeButton: {
        display: 'inline-block',
        marginLeft: '5px',
        alignSelf: 'center',
      },
      notificationContainer: {
        padding: '0px 0px 10px 0px',
        borderBottom: '1px solid #707070',
      },
      notificationIconContainer: {
        height: '35px',
        display: 'grid',
        gridTemplateColumns: '50px auto',
        alignItems: 'center',
        justifyItems: 'center',
      },
      notificationObjectContainer: {
        height: '35px',
        display: 'grid',
        gridAutoFlow: 'column',
        alignItems: 'center',
        justifyItems: 'center',
      },
      userIcon: {
        fontSize: '22px',
        position: 'relative',
        color: '#F22F46',
        top: '-1px',
      },
      commentIcon: {
        fontSize: '22px',
        position: 'relative',
        color: '#4caf50',
        top: '-1px',
      },
      notificationText: {
        margin: '0px',
        fontSize: '16px',
        justifySelf: 'start',
      },
      switch: {
        justifySelf: 'center',
      },
      linkContainer: {
        height: '50px',
        width: '100%',
        borderBottom: '1px solid #707070',
        display: 'grid',
        alignItems: 'center',
        cursor: 'pointer',
      },
      linkContainerSelected: {
        height: '50px',
        width: '100%',
        borderBottom: '1px solid #707070',
        display: 'grid',
        alignItems: 'center',
        cursor: 'pointer',
        backgroundColor: theme.tertiaryColors.default,
      },
      linkText: {
        margin: '0px',
        marginLeft: '5px',
        fontSize: '18px',
      },
      logo: {
        position: 'absolute',
        bottom: '10px',
        left: '50%',
        transform: 'translate(-50%, 0)',
        width: '80%',
      },
      avatarContainer: {
        position: 'absolute',
        bottom: '55px',
      },
    })
  );

  const classes = useStyles();
  const { roomsOpen, totalRooms, newTextMessages, language, setLanguage, darkMode, setDarkMode } = useAppState();

  const changePageHandler = (newPage: number) => {
    if (newPage !== props.currentPage) {
      props.changePage(0, newPage, false);
      props.closeNavigationDrawerHandler();
    }
  };

  React.useEffect(() => {
    if (!darkMode) {
      document.documentElement.style.setProperty('--scrollbar-track-color', '#e2e2e2');
      document.documentElement.style.setProperty('--scrollbar-thumb-color', '#b4b4b4');
    } else {
      document.documentElement.style.setProperty('--scrollbar-track-color', '#303030');
      document.documentElement.style.setProperty('--scrollbar-thumb-color', '#202020');
    }
  }, [darkMode]);

  const backdropTransition = {
    in: {
      opacity: 1,
    },
    out: {
      opacity: 0,
    },
    initial: {
      opacity: 0,
    },
  };

  const drawerTransition = {
    in: {
      x: '0%',
    },
    out: {
      x: '-100%',
    },
    initial: {
      x: '-100%',
      transition: 'ease-out',
    },
  };

  return (
    <div className={classes.navigationDrawerPageContainer}>
      <motion.div
        className={classes.navigationDrawerBackdrop}
        onClick={props.closeNavigationDrawerHandler}
        initial={backdropTransition.initial}
        animate={backdropTransition.in}
        exit={backdropTransition.out}
        transition={{ duration: 0.2 }}
      />
      <motion.div
        className={classes.navigationDrawerContainer}
        initial={drawerTransition.initial}
        animate={drawerTransition.in}
        exit={drawerTransition.out}
        transition={{ duration: 0.2 }}
      >
        <div className={classes.headerContainer}>
          <CloseIcon onClick={props.closeNavigationDrawerHandler} className={classes.closeButton} />
        </div>
        <Menu />
        <div className={classes.notificationContainer}>
          <div className={classes.notificationIconContainer}>
            <FontAwesomeIcon icon={faUser} className={classes.userIcon} />
            <p className={classes.notificationText}>{roomsOpen} Waiting</p>
          </div>
          <div className={classes.notificationIconContainer}>
            <FontAwesomeIcon icon={faComment} className={classes.commentIcon} />
            <p className={classes.notificationText}>
              {newTextMessages} Message{newTextMessages !== 1 && 's'}
            </p>
          </div>
          <div className={classes.notificationObjectContainer}>
            <p className={classes.notificationText} style={{ marginLeft: '5px' }}>
              Dark Mode{' '}
            </p>
            <Switch classes={{ root: classes.switch }} checked={darkMode} onChange={() => setDarkMode(!darkMode)} />
          </div>
          <div className={classes.notificationObjectContainer}>
            <p className={classes.notificationText} style={{ top: '1px', marginLeft: '5px' }}>
              Language
            </p>
            <FormControl>
              <Select
                id="demo-simple-select-outlined"
                value={language}
                onChange={(e: ChangeEvent<HTMLInputElement>) => setLanguage(e.target.value)}
                label=""
              >
                <MenuItem value="default">English</MenuItem>
                <MenuItem value="es">Espa&#241;ol</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
        <div
          className={props.currentPage === 0 ? classes.linkContainerSelected : classes.linkContainer}
          onClick={() => changePageHandler(0)}
        >
          <p className={classes.linkText}>Walklist</p>
        </div>
        <div
          className={props.currentPage === 1 ? classes.linkContainerSelected : classes.linkContainer}
          onClick={() => changePageHandler(1)}
        >
          <p className={classes.linkText}>Survey</p>
        </div>
        <div
          className={props.currentPage === 2 ? classes.linkContainerSelected : classes.linkContainer}
          onClick={() => changePageHandler(2)}
        >
          <p className={classes.linkText}>Video</p>
        </div>
        <img className={classes.logo} src={props.Logo} />
      </motion.div>
    </div>
  );
}
