import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import AppBar from '@material-ui/core/AppBar';
import ToggleFullscreenButton from './ToggleFullScreenButton/ToggleFullScreenButton';
import Toolbar from '@material-ui/core/Toolbar';
import Menu from './Menu/Menu';

import { useAppState } from '../../state';

import { useParams } from 'react-router-dom';
import FlipCameraButton from './FlipCameraButton/FlipCameraButton';
import LocalAudioLevelIndicator from './DeviceSelector/LocalAudioLevelIndicator/LocalAudioLevelIndicator';
import VideoParticipants from './VideoParticipants/VideoParticipants';
import NotificationContainer from './NotificationContainer/NotificationContainer';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: theme.palette.background.default,
    },
    toolbar: {
      [theme.breakpoints.down('xs')]: {
        padding: 0,
      },
    },
    rightButtonContainer: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: 'auto',
      color: theme.palette.text.primary,
      //color: 'black',//for light theme
    },
    form: {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      [theme.breakpoints.up('md')]: {
        marginLeft: '2.2em',
      },
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      maxWidth: 200,
    },
    loadingSpinner: {
      marginLeft: '1em',
    },
    displayName: {
      margin: '1.1em 0.6em',
      minWidth: '200px',
      fontWeight: 600,
    },
    joinButton: {
      margin: '1em',
    },
    logo: {
      position: 'absolute',
      top: '10px',
      left: '10px',
      height: '40px',
    },
  })
);

export default function MenuBarStart(props: any) {
  const classes = useStyles();
  const { URLRoomName } = useParams();
  const { loggedUsername, server } = useAppState();
  //const { userlogin, getLogin, isFetchingLogin } = useAppLoginState();

  return (
    <AppBar className={classes.container} position="static" elevation={2}>
      <NotificationContainer />
      <img className={classes.logo} src={props.Logo} />

      <Toolbar className={classes.toolbar}>
        <div className={classes.rightButtonContainer}>
          {/*loggedUsername*/}

          <FlipCameraButton />
          <LocalAudioLevelIndicator />
          <ToggleFullscreenButton />
          <Menu />
        </div>
      </Toolbar>
    </AppBar>
  );
}
