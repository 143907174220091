import React, { useState } from 'react';
import Controls from '../Controls/Controls';
import LocalVideoPreview from '../LocalVideoPreview/LocalVideoPreview';
import { styled } from '@material-ui/core/styles';
import Room from '../Room/Room';
import useRoomState from '../../hooks/useRoomState/useRoomState';
import JoinRoom from './JoinRoom';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useAppState } from '../../state';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import JoinPageRoom from '../JoinPageRoom/JoinPageRoom';
import { detect } from 'detect-browser';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import { getFormattedDate } from '../Petition/Survey';
import './JoinApp.css';

const Container = styled('div')({
  display: 'grid',
  gridTemplateRows: 'auto',
});

const browser = detect();
//Detect the users browser & OS and prompt them if they are on iOS and not using Safari
if (browser) {
  if (browser.name !== 'ios' && browser.os === 'iOS') {
    window.alert('Application not supported by this browser. Please use Safari');
  }
}

export default function JoinApp() {
  //style
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      second: {
        background: '#0D122B',
        height: '13vh',
        position: 'absolute',
        bottom: '20px',
        left: '50%',
      },
      headerText: {
        marginTop: '0px',
      },
    })
  );

  const { room, localTracks } = useVideoContext();

  window.onunload = function() {
    try {
      room?.disconnect?.();
      localTracks?.forEach(track => track.stop());
    } catch (err) {}
  };

  const classes = useStyles();
  const roomState = useRoomState();

  return (
    <Container className="joinAppContainer">
      <div className="joinAppContentContainer">
        {roomState === 'disconnected' && <JoinRoom></JoinRoom>}
        {roomState === 'disconnected' ? <LocalVideoPreview /> : <JoinPageRoom />}
      </div>
      <div className={classes.second}>
        <Controls />
      </div>
    </Container>
  );
}
