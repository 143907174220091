import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import { useAppState } from '../../state';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faComment } from '@fortawesome/free-solid-svg-icons';

export default function MobileMenuBar(props: any) {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      menuBarContainer: {
        height: '50px',
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        display: 'grid',
        gridTemplateColumns: '50px 1fr auto',
        alignItems: 'center',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.45)',
        zIndex: 3,
      },
      burgerContainer: {
        height: '60%',
        width: '35px',
        justifySelf: 'center',
        border: '1px solid white',
        cursor: 'pointer',
      },
      burgerDash: {},
      pageText: {
        margin: '0px',
        fontSize: '22px',
      },
      iconsContainer: {
        borderRadius: '5px',
        backgroundColor: theme.palette.background.default,
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.45)',
        display: 'grid',
        position: 'relative',
        top: '-1px',
        gridAutoFlow: 'column',
        paddingRight: '10px',
        marginRight: '10px',
      },
      notificationIconContainer: {
        height: '35px',
        display: 'grid',
        gridTemplateColumns: '45px auto',
        alignItems: 'center',
        justifyItems: 'center',
        position: 'relative',
        top: '2px',
      },
      userIcon: {
        fontSize: '22px',
        position: 'relative',
        color: '#F22F46',
        top: '-1px',
      },
      commentIcon: {
        fontSize: '22px',
        position: 'relative',
        color: '#4caf50',
        top: '-1px',
      },
      notificationText: {
        margin: '0px',
        fontSize: '16px',
        justifySelf: 'start',
      },
    })
  );

  const classes = useStyles();
  const { roomsOpen, newTextMessages } = useAppState();

  return (
    <div className={classes.menuBarContainer}>
      <MenuIcon style={{ justifySelf: 'center' }} onClick={props.openNavigationDrawerHandler} />
      <p className={classes.pageText}>
        {props.currentPage === 0 ? 'Walklist' : props.currentPage === 1 ? 'Survey' : 'Video'}
      </p>
      <div className={classes.iconsContainer}>
        <div className={classes.notificationIconContainer}>
          <FontAwesomeIcon icon={faUser} className={classes.userIcon} />
          <p className={classes.notificationText}>{roomsOpen}</p>
        </div>
        <div className={classes.notificationIconContainer}>
          <FontAwesomeIcon icon={faComment} className={classes.commentIcon} />
          <p className={classes.notificationText}>{newTextMessages}</p>
        </div>
      </div>
    </div>
  );
}
